if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value: function (index) {
      // If the index is negative, count from the end of the array.
      index = Math.sign(index) < 0 ? this.length + index : index
      // Return the item if index is within the bounds of the array; undefined otherwise.
      return index >= 0 && index < this.length ? this[index] : undefined
    },
    writable: true,
    enumerable: false,
    configurable: true,
  })
}

if (!Array.prototype.findLastIndex) {
  Object.defineProperty(Array.prototype, 'findLastIndex', {
    value: function (callback, thisArg) {
      if (this == null) {
        throw new TypeError(
          'Array.prototype.findLastIndex called on null or undefined',
        )
      }
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function')
      }

      for (let i = this.length - 1; i >= 0; i--) {
        if (callback.call(thisArg, this[i], i, this)) {
          return i
        }
      }
      return -1
    },
    configurable: true,
    writable: true,
  })
}
