import { create, apply } from 'mutative'
import { flushSync } from 'react-dom'
import { FL } from './common_init'

import dayjs from 'dayjs'
import {
  keys,
  values,
  entries,
  ol,
  plural,
  latest,
  recent,
  toCapitalCase,
  later,
  shallowEqual,
  is,
  nid,
  omit_null,
  minute,
  now,
  extract,
  array_to_obj,
  Omap,
  ofilter,
  memo,
  CDN_URL,
  day,
  hour,
  week,
  getSubdomain,
  preprocessForRemarkMath,
} from './lib/utils'
import { inspect } from 'util'
import { lg } from './log.ts'
import _ from 'lodash'
// import {bind} from "./immer-yjs";
import * as jsonpatch from 'fast-json-patch'
import { _global } from './common_init'
;(_global as any)._ = _

// enablePatches()

//legacy, will phase out in favor of MODELS / user.ai
export const AI_ID = 'ai'
export const DEFAULT_BASE_MODEL = 'openai/gpt-4'

// Vella:
export const DEFAULT_CHAT_MODEL = 'rm6lz1h8'
const DEFAULT_TITLE = 'New Chat'

export class Store {
  state: any = {}
  full_state: any
  unsub: any
  ydoc: any
  // back = []
  history = []
  undo_spot = 0
  // forward = []
  checkpoints = []
  checkpointIndex = 0
  obs = {}
  watchers = {}
  profile: any = {}
  binder
  prev
  connection_id: any
  _prev_doc = {}
  _current_user
  _checkpoint
  _confirm_callback
  is_mobile
  _in_action
  _set_editor
  _in_action_render
  _in_external_render
  _external_update
  _import
  _user_edit
  _edit_model
  _password_recovery
  _confirm_email
  _bootstraped: boolean
  _FL = {}
  _off
  static types = [
    'invite',
    'file',
    'room',
    'user',
    'team',
    'message',
    'notif',
    'note',
  ]

  constructor() {}

  destroy() {
    this.state = null
    this.checkpoints = []
    this.obs = {}
    this.watchers = {}
    this.binder = null
    this._bootstraped = false
    this._prev_doc = {}
    //TODO: other mem cleanup?
  }

  get observers() {
    return Object.values(this.obs)
  }

  @action
  clearState() {
    this.state._in_command = false
  }

  @action
  initFromSession(session, first = false, data = {}) {
    // this.init(false);
    this.clear()
    this.setTeam(session)
    this.setUser(session)
    this.user.team_id = session.team_id

    this.initWorkspace(first)
    if (!_.isEmpty(data)) this.merge(data)
    this.init(true)
  }

  @action
  confirm(fn, title = null, description = null) {
    this._confirm_callback = fn
    this.toggle('confirm', { title, description })
  }

  @action
  addFile(file) {
    this.checkpoint()
    var id = this.save({
      type: 'file',
      ..._.omit(file, ['source']),
    })
    if (file.on.type_ === 'room') {
      this.collapseRight(false)
      this.setCurrentFile(file.on.id_, id)
    }
    return id
  }

  @action
  addNote(note = {}, goto = true) {
    this.checkpoint()
    note.content =
      note.content ||
      `# New Note \n\n*${dayjs().format('MMM D, YYYY h:mm A')}*\n\n -`
    var id = this.save({
      type: 'note',
      ...note,
    })
    if (goto) {
      this.nav('/docs/' + id)
    }
  }

  @action
  duplicateFile(file) {
    // is this omit necessary
    this.checkpoint()
    const id = nid()
    this.save({ ..._.cloneDeep(file), id: id })
    if (!this.room) {
      this.nav('/docs/' + id)
    }
    // navigate to room with file?
  }

  @action
  archiveFile(file) {
    this.checkpoint()
    if (file.id === this.current_file?.id) {
      this.nav('/docs/')
    }
    this.save({
      ...file,
      archived: true,
    })
  }

  @action
  detachFile(id) {
    this.checkpoint()
    this.state.files[id].on = null
    return id
  }

  file_url(file) {
    return `${CDN_URL}/files/` + file.sid
  }

  @action
  updateFile(id, data) {
    console.log(`data here`, data)
    this.save({ id, type: 'file', ...data })
  }

  @action
  updateMessage(id, data) {
    this.save({ id, type: 'message', ...data })
  }

  @action
  initWorkspace(first = false) {
    this.addRoom(true)
    this.toggle('download_app', first)
    this.toggleProp('dense_mode', true)
    // this.createAIUsers()
  }

  @action
  setProfile(profile) {
    this.profile = profile
    this.state._dummy = Math.random()
  }

  get team() {
    return (
      this.teams?.[this.user?.current_team] ??
      _.first(values(this.teams || {})) ??
      {}
    )
  }

  @action //({ overwrites: true })
  setToast(toast, uid = this.user?.id) {
    let user = this.users[uid]
    if (!user) return
    if (toast.delivered == undefined) toast.delivered = false
    user.toast = toast

    if (toast.delivered) return
    user.toast.create_time = now()
    user.toast.id = nid()
  }

  @action
  clearCruft(doc) {
    doc = this.current(doc)
    delete doc.toast
    delete doc.team_info
  }

  @action
  nav(url, external = false, host = null) {
    console.log('>> nav to url: ', url)
    lg('nav', { url }, this)
    this.user.location = url
    if (host) this.user.host = host
    var type = url.split('/')[1]
    if (type == 'hello') {
      type = 'user'
    }

    const id = url.split('/')[2]

    this.see(id)

    //gross..
    //TODO: why is this logic so cumbersome
    //auto-redirect to team if room is not in team
    if (
      !this.in_share &&
      !this.in_team(this.room) &&
      ['room', 'app'].includes(type)
    ) {
      var team = this.in_teams(this.room).at(0)
      if (team) {
        this.setCurrentTeam(team)
      } else {
        // if (url == _global.original_url && id) {
        //   this.setToast({
        //     title: `You can't see this room.`,
        //     content: `Are you logged in to the right account?`,
        //     level: 'warning',
        //     ephemeral: true,
        //     options: {
        //       id: 'sign_in',
        //       action: {
        //         label: '➥ Sign In',
        //         onClick: () => this.toggle('space_login'),
        //       },
        //     },
        //   })
        // }
        this.navFallback()
      }
    }

    if (type == 'room') {
      this.latest_user_notifs()
        .filter((n) => this.messages[n.target]?.room_id == id)
        .map((n) => (n.seen = true))
      this.toggle('team_viewer', false)
    } else if (type == 'user' && id) {
      this.setCurrentPersona(id)
      this.createCanonicalRoom(id)
    } else if (type == 'publish') {
      this.createCanonicalRoom(id)
    } else if (type == 'docs') {
      if (id) {
        this.collapseRight(false)
      } else {
        this.collapseRight(true)
      }
    } else {
      this.setCurrentPersona(null)
    }
  }

  @action
  setCurrentPersona(id) {
    this.user.current_persona_id = id
  }

  createCanonicalRoom(id) {
    var cid = this.canonical_id(id)
    if (!this.rooms[cid]) {
      this.giveAccessTo(
        this.ref(this.persona),
        this.ref(this.user),
        'read',
        false,
      )
      return this.addRoom(
        false,
        { id: cid, canonical: true, archived: true },
        id,
      )
    }
    return cid
  }

  @action
  bootstrap(data) {
    this.clear()
    Object.assign(this.state, data)
    this.init(true)

    this._bootstraped = true
  }

  @action
  postBootstrap() {
    if (!this.user.private) this.user.private = {}
    if (!this.user.secure) this.user.secure = {}
  }

  @action
  merge(data) {
    _.merge(this.state, data)
    this.unshowAll()
  }

  @action
  init(val = true) {
    this.state._init = val
  }

  @action
  loading(val = true) {
    this.state._loading = val
  }

  @action
  clear() {
    Object.assign(this.state, {
      rooms: {},
      notifs: {},
      users: {},
      teams: {},
      messages: {},
    })
    this.state._model = DEFAULT_CHAT_MODEL
  }

  @action
  setTeam(session) {
    if (!this.teams[session.team_id])
      this.save({
        type: 'team',
        id: session.team_id ?? nid(),
        name: session.team_info?.name,
        open: session.team_info?.open,
        anon: session.anonymous,
      })
  }

  @action
  addTeam(team, switch_to = true) {
    var id = this.save({
      type: 'team',
      ...team,
    })
    this.addToTeam(this.user.id, id)
    if (switch_to) this.setCurrentTeam(id)
  }

  @action
  addInvite(invite) {
    var id = this.save({
      type: 'invite',
      ...invite,
    })
    this.giveAccessTo(this.ref(this.deref(id)), invite.to)
    this.giveAccessTo(this.ref(this.team), invite.to)
  }

  get invites() {
    return values(this.state.invites || {}).filter(
      (i) => !i.archived && i.to == this.user.email,
    )
  }

  get team_invites() {
    return values(this.state.invites || {}).filter(
      (i) => !i.archived && i.team_id == this.team.id,
    )
  }

  @action
  acceptInvite(invite) {
    this.addToTeam(this.user.id, invite.team_id)
    this.setCurrentTeam(invite.team_id)
    this.archive(invite)
  }

  @action
  archiveAllChats() {
    values(this.state.rooms).map(this.archive.bind(this))
  }

  @action
  requestReload() {
    this.user.reload_requested = new Date()
  }

  @action
  declineInvite(invite) {
    this.archive(invite)
  }

  @action
  addToTeam(user_id, team_id = this.team.id) {
    var user = this.users[user_id]
    var team = this.teams[team_id]
    if (!user || !team) return
    if (team_id.includes('share-')) {
      this.giveAccessTo(this.ref(user), this.ref(team), 'read')
    } else {
      this.giveAccessTo(this.ref(user), this.ref(team), 'write')
    }
    this.giveAccessTo(this.ref(team), this.ref(user), 'read')
    this.giveAccessTo(this.ref(team), user.email, null)
  }

  @action
  removeFromTeam(user_id = this.user.id, team_id = this.team.id) {
    if (values(this.teams).length == 1) return

    var user = this.users[user_id]
    var team = this.teams[team_id]

    this.giveAccessTo(this.ref(user), this.ref(team), null)
    this.giveAccessTo(this.ref(team), this.ref(user), null)
    this.giveAccessTo(this.ref(team), user.email, null)
    this.setCurrentTeam(this.active_teams.filter((t) => t.id != team_id)[0].id)
  }

  @action
  setCurrentTeam(team_id) {
    console.log(`team_id`, team_id)
    this.user.current_team = team_id

    //remove later, temp for repair:
    if (
      this.team.id &&
      !this.human_users.map((u) => u.id).includes(this.user.id)
    )
      this.addToTeam(this.user.id, team_id)
    if (!this.in_team(this.room)) {
      this.navFallback()
    }
    this.setToast({ content: `Switched to ${this.team.name || 'Your Space'}` })
    this.unshowAll()
  }

  @action
  navFallback() {
    this.go(this.rooms_created.at(-1))
    if (!this.in_share && (!this.room?.id || !this.rooms_created.at(-1)))
      this.addRoom(true)
  }

  @action
  upsertTeam(doc) {
    this.save({
      type: 'team',
      ...doc,
    })
    this.setCurrentTeam(doc.id)
  }

  @action
  setUser(session) {
    this.upsertUser(session)
    this.setCurrentUser(session.id)
    this.setFocused()
    // this.createAIUsers()
  }

  model_name(_model = this.model_id) {
    return _model ? this.users[this.model_id]?.name : ''
  }

  @action
  import(data) {
    data.map((r) => {
      let msgs = r.mapping
      delete r.mapping
      this.addRoom(false, r)
      values(msgs)
        .filter((m) => m.message)
        .map((m) => {
          m.role = m.message.author?.role
          m.content = m.message.content?.parts?.at(0) || ''
          m = JSON.parse(JSON.stringify(m))
          omit_null(m)
          this.addMessage(_.cloneDeep(m), r.id, m.role == 'user' ? null : 'ai')
        })
    })
  }

  @action
  duplicateRoom(room_id = this.room.id) {
    let room = this.rooms[room_id]
    let new_room = {
      ...room,
      orig: room.id,
      title: 'Copy of ' + room.title,
      id: nid(),
    }
    var id = this.addRoom(true, new_room)
    this.room_messages(room_id).map((m) => {
      this.addMessage(_.cloneDeep(_.omit(m, 'id')), id)
    })
    return new_room.id
  }

  @action
  clearRoom(room_id = this.room.id) {
    this.room_messages(room_id).map((m) => {
      m.deleted = true
    })
  }

  @action
  addRoomWithFile(fid) {
    let params: any = { current_file: fid }
    var file = this.all_files()[fid]
    if (!file) return
    var is_note = file.type === 'note'
    let id = this.addRoom(
      true,
      { current_file: fid, note: is_note ? fid : undefined },
      is_note ? 'doc' : DEFAULT_CHAT_MODEL,
    )
    if (!is_note) {
      file.on = this.ref(this.rooms[id])
      file.next = true
    }
    this.collapseRight(false)
  }

  @action
  addRoom(
    switch_to = false,
    rm: any = {},
    model = this.user?._model || this.state._model,
    models = [],
  ) {
    let id = rm.id ?? nid()
    //this.room = proxy
    let members = [this.user.id]
    // if (!this.users[model]) this.createAIUsers()

    if (model && models.length == 0) models = [model]

    if (models.length > 0) model = models[0]
    this.save({
      id,
      type: 'room',
      model: model,
      models: models,
      title: '',
      current_file: '',
      note: '',
      members,
      ...rm,
    })
    if (switch_to) this.go(this.rooms[id])
    if (!model) {
      this.toggle('user_edit', true)
    }

    this.checkpoint()
    return id
  }

  @action
  upsertRoom(id, data) {
    return this.save({ id, type: 'room', ...data })
  }

  @action
  setRoomModels(models) {
    this.room.models = models
  }

  @action
  addRoomMembers(members, id = this.room.id) {
    var room = this.rooms[id]
    room.members = _.uniq(this.room_members(id).concat(members))
    this.checkpoint()
  }

  @action
  removeRoomMembers(members, id = this.room.id) {
    var room = this.rooms[id]
    room.members = _.difference(this.room_members(id), members)
    if (room.members.length == 0) delete room.model
    this.checkpoint()
  }
  @action
  addRoomModels(models, id = this.room.id) {
    var room = this.rooms[id]
    room.models = _.uniq(this.room_models(id).concat(models))
    this.checkpoint()
  }

  @action
  removeRoomModels(models, id = this.room.id) {
    this.checkpoint()
    var room = this.rooms[id]
    room.models = _.difference(this.room_models(id), models)
    if (room.models.length == 0) {
      delete room.model
    }
    this.removeRoomMembers(models, id)
    this.checkpoint()
  }

  @action
  renameRoom(title, id = this.room.id) {
    this.save({ id, type: 'room', title })
  }

  get initialized() {
    return !!this.users
  }

  get has_user() {
    return !!this.user
  }

  get has_authed_user() {
    return !this.user?.anonymous
  }

  @action
  upsertUser(data) {
    this._upsertUser(data)
  }

  @action
  updateUser(data, id = this.user.id) {
    this._upsertUser({ id, ...data })
  }

  //purely semantic vs updateUser rn
  @action
  updateSubscription(data, id = this.user.id) {
    this._upsertUser({ id, ...data })
  }

  @action
  recordUserURLParams(params) {
    this.user.secure ||= {}
    this.user.secure.params ||= {}
    _.assign(this.user.secure.params, params)
  }

  @action
  upsertAIUser(data) {
    this.checkpoint()
    data.ai = true
    data.model = data.model ?? this.state.users[data?.id]?.model
    data.provisional = data.provisional == 'on' ? true : null
    var pub = data.public
    delete data.public
    this.toggle('creating_assistant', false)
    data.domain = getSubdomain(this.user.host)
    var user_id = this._upsertUser(data)
    if (pub) this.publishAIUser(this.users[user_id])
    if (!data.provisional) this.setModel(user_id)
    return user_id
  }

  @action
  archiveAIUser(user) {
    this.checkpoint()
    this.archive(user)
  }

  @action
  publishAIUser(user) {
    this.giveAccessTo(this.ref(user), this.ref(user), 'all')
    this.current(user).access['all_pull'] = { p: 'read', t: 'pull' }
  }

  @action
  openNewModel() {
    var id = this.upsertAIUser({ provisional: 'on', model: DEFAULT_BASE_MODEL })
    this._edit_model = id
    this.toggle('add_model')
  }

  get edit_model() {
    return this.users[this._edit_model]
  }

  @action
  set(key_path, val) {
    _.set(this.state, key_path, val)
  }

  _upsertUser(data) {
    data.id = data.id ?? nid()
    data.type = 'user'
    delete data.access_token
    this.save({ ...(this.state.users[data.id] || {}), ...data })
    var user = this.users[data.id]
    if (!user.private) user.private = {}
    if (!user.secure) user.secure = {}
    if (!user.server) user.server = {}
    if (!user.focused) user.focused = {}
    if (!user.seen) user.seen = {}
    return data.id
  }

  setCurrentUser(id) {
    this._current_user = id
  }

  focusUser(user_views, id = null, email = null) {
    if (this.full_state) this.state = this.full_state

    if (email)
      this._current_user = this.active_users.filter(
        (u) => u.email == email,
      )[0]?.id
    if (id) this._current_user = id
    if (!email && !id) {
      this._current_user = null
      return
    }
    let filtered_state = {}
    if (user_views[this._current_user]) {
      Object.keys(this.state)
        .filter((c) => !c.startsWith('_'))
        .map((collection) => {
          filtered_state[collection] = {}
          values(this.state[collection]).map((doc) => {
            if (
              user_views[this._current_user].has(doc.id) ||
              user_views['all'].has(doc.id)
            ) {
              filtered_state[collection][doc.id] = doc
            }
          })
        })
    }
    this.full_state = this.state
    this.state = filtered_state
  }

  @action
  toggle(key, x = null, name = null) {
    if (key == 'space_signup')
      lg('funnel', { step: 'space_signup_toggle' }, this)
    if (!this.user.show) this.user.show = {}
    if (
      ['pricing_table', 'pricing_drawer'].includes(key) &&
      this.user.anonymous
    )
      key = 'space_login'
    this.user.show[key] = x ?? !this.user.show[key]

    if (
      ['pricing_table', 'pricing_drawer'].includes(key) &&
      !this.user.show[key]
    ) {
      this.user.warning = null
    }
    if (name) {
      this.setToast({
        content: `${name} ${this.user.show[key] ? 'on.' : 'off.'}`,
      })
    }
  }

  @action
  toggleProp(key, x = null, name = null) {
    this.user[key] = x ?? !this.user[key]
    if (name)
      this.setToast({
        content: `${name} ${this.user[key] ? 'on.' : 'off.'}`,
      })
  }

  @action
  unshowAll() {
    this.state._sel_cmd = null
    this.user.warning = null
    this.user.show = {}
  }

  get notifs() {
    return this.state.notifs || {}
  }

  @action
  setEmailed(ids) {
    ids.map((id) => (this.notifs[id].emailed = true))
  }

  @action
  setDelivered(id) {
    this.notifs[id].delivered = true
  }

  @action
  setCurrentRoomMessage(msg_id) {
    this.go(this.messages[msg_id])
  }

  @action
  see(id) {
    if (!this.user.seen) this.user.seen = {}
    this.user.seen[id] = Date.now()
  }

  @action
  switchRoom(down = false) {
    let i = this.rooms_flat.findIndex((r) => r.id == this.user_current_room)
    if (i == -1)
      if (this.rooms_flat.length) this.go(this.rooms_flat[0])
      else return
    let next = this.rooms_flat[i + (down ? 1 : -1)]
    if (!next) return
    delete this.user.warning
    this.go(next)
  }

  @action
  setFocused(focused = true) {
    if (!this.user || !this.connection_id) return
    if (!this.user.focused) this.user.focused = {}
    if (focused) {
      this.user.focused[this.connection_id] = Date.now()
      this.user.is_focused = Date.now()
      this.user.last_focused = Date.now()
      this.see(this.user_current_room)
    } else {
      delete this.user.focused[this.connection_id]
      delete this.user.is_focused
    }
  }

  get pinned_models() {
    return this.ai_users.filter((m) => this.user_pinned(m.id))
  }

  @action
  switchModel(prev = false) {
    this.checkpoint()
    let i = this.pinned_models.findIndex((m) => m.id == this.model_id)
    let next = this.pinned_models[i + (prev ? -1 : 1)].id
    if (!next) return
    this.setModel(next)
    this.setToast({
      options: { duration: 500 },
      content: `Switched to ${this.model_name()}`,
    })
  }

  @action
  deleteRoom(id = this.user_current_room) {
    this.checkpoint()
    if (id == this.user_current_room) this.switchRoom(false)
    this.state.rooms[id].archived = true
    if (!this.room) this.switchRoom(false)
  }

  @action
  unDeleteRoom(id = this.user_current_room) {
    this.checkpoint()
    this.state.rooms[id].archived = false
  }

  @action
  clearArchivedRooms() {
    values(this.state.rooms)
      .filter((r) => r.archived)
      .map((r) => {
        this.room_messages(r.id).map((m) => delete this.state.messages[m.id])
        delete this.state.rooms[r.id]
      })
  }

  @action
  clearArchivedFiles() {
    values(this.state.files)
      .filter((f) => f.archived)
      .map((f) => {
        delete this.state.files[f.id]
      })
  }

  message_user(m) {
    var slug = m.message?.metadata?.model_slug
    var openai_model = slug
      ? slug.includes('gpt-4')
        ? 'openai/gpt-4'
        : 'openai/gpt-3.5-turbo'
      : null
    var owner = m.owner_id == 'ai' ? null : m.owner_id
    return this.state.users[owner || openai_model || m.model]
  }

  message_content(m, display = true) {
    var content = display ? (m.content_display ?? m.content) : m.content
    return Array.isArray(content)
      ? content
      : [{ type: 'text', text: { value: content } }]
  }

  message_content_vision(m) {
    var content = m.content
    return Array.isArray(content)
      ? content.map((c) =>
          c.type != 'text' ? c : { ...c, text: c.text?.value ?? c.text },
        )
      : [{ type: 'text', text: content }]
  }

  message_clean(m) {
    return this.message_content_text(m).replace(/<[^>]*>/g, '')
  }

  message_content_text(m) {
    if (!m) return ''
    return this.message_content(m)
      .map((msg) =>
        msg?.text instanceof Object ? msg.text.value : msg?.text || '',
      )
      .join('\n\n')
  }

  room_model(id = this.room?.id) {
    return this.room_models(id).at(0) ?? this.rooms[id]?.model
  }

  room_models(id = this.room?.id) {
    let room = this.rooms[id]
    if (!room) return []
    return (
      room.models ??
      (room.model
        ? [room.model]
        : (this.room_participants(room.id)
            .filter((u) => u?.ai)
            .map((u) => u.id) ?? []))
    )
  }

  active_models(id = this.room?.id) {
    return this.room_models(id)
      .map((id) => this.users[id])
      .filter((u) => u?.ai)
  }

  room_members(id = this.room?.id) {
    let room = this.rooms[id]
    return (
      room.members ??
      this.room_participants(room.id)
        .filter((u) => u)
        .filter((u) => !u.ai)
        .map((u) => u.id) ??
      []
    )
  }

  room_title(id = this.room?.id) {
    var r = this.rooms[id]
    if (r?.title) {
      return r?.title
    }
    const firstMessage = this.message_content_text(this.room_messages(r?.id)[0])
    return (firstMessage || DEFAULT_TITLE).slice(0, 70)
  }

  in_room(id = this.room?.id) {
    return _.orderBy(
      _.uniq(
        this.room_members(id)
          .concat(this.room_models(id))
          .map((m) => this.users[m]),
      ),
      (u) => u?.id == this.rooms[id].model,
      ['asc'],
    )
  }

  room_headers(id = this.room.id) {
    return this.room_members(this.room.id)
      .map((id) => this.users[id])
      .filter((u) => u)
      .filter((u) => !u.ai)
      .concat(this.active_models(this.room.id))
      .filter((u) => u.id != this.user.id)
  }

  room_participants(id = this.room?.id) {
    return _.uniq(this.room_messages(id).map((m) => this.message_user(m)))
  }

  get active_users() {
    return this._active_users()
  }

  @memo((s) => [s.users])
  _active_users() {
    return values(this.users || {})
      .filter((u) => u.archived != true && u.provisional != true)
      .filter((u) => this.in_team(u))
  }

  get user_domain() {
    return getSubdomain(this.user?.host)
  }

  get public_users() {
    return values(this.users || {}).filter(
      (u) =>
        u.archived != true &&
        u.provisional != true &&
        !u.shadow &&
        this.user_domain == u.domain,
    )
  }

  get users_by_room() {
    const users_by_room = {}
    this.active_users.map(
      (u) =>
        (users_by_room[this.user_current_room_(u)] = (
          users_by_room[this.user_current_room_(u)] || []
        ).concat(u)),
    )
    return users_by_room
  }

  @memo((s) => [s.state.users])
  usersInRoom(room_id) {
    return this.active_users.filter(
      (u) => this.user_current_room_(u) == room_id,
    )
  }

  @action
  clearRooms() {
    this.state.rooms = {}
  }

  get bookmarks() {
    return [] //values(this.state.messages).filter((m) => m.bookmarked)
  }

  get messages() {
    return this.state.messages
  }

  get rooms() {
    return this.state.rooms
  }

  get files() {
    return this.state.files
  }

  @memo((s) => [s.state.files, s.state.notes])
  all_files() {
    return _.assign({}, this.state.files, this.state.notes)
  }

  archived_files() {
    return values(this.files).filter((f) => f.archived)
  }

  get note() {
    if (!this.room) {
      const file_id = this.user_location().split('/').at(-1)
      if (file_id == 'docs') {
        return ''
      }
      return this.all_files()[file_id]?.['content']
    }
    if (this.notes && this.room.note) {
      const note = this.notes[this.room.note]
      if (note.archived) {
        console.log('creating new note')
        this.setNoteContents('', this.room.id)
        return ''
      }
      return note['content']
    }
    return this.room.doc ?? ''
  }

  get notes() {
    return this.state.notes
  }

  get users() {
    return this.state.users
  }

  get team_files() {
    return values(this.files)
      .sort(recent)
      .filter((f) => !f.archived && this.in_team(f))
  }

  get others() {
    return this.active_users
      .filter((u) => u.id != this.user.id)
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .sort((a, b) => ((a.ai ? 1 : 0) < (b.ai ? 1 : 0) ? 1 : -1))
  }

  get human_users() {
    return this.active_users.filter(
      (u) => !u.anonymous && !(u.ai || u.id == AI_ID),
    )
  }

  get characters() {
    return this.active_users.filter((u) => u.ai && !u.builtin && !u.provisional)
  }

  get public_characters() {
    return this.public_users.filter((u) => u.ai && !u.builtin && !u.base)
  }

  get ai_users() {
    return this.active_users
      .filter(
        (u) => !u.anonymous && u.ai && !u.provisional && u.id !== 'desktop',
      )
      .sort((a, b) => (a.builtin ? 1 : -1))
  }

  get teams() {
    return this.state.teams
  }

  get active_teams() {
    return values(this.teams).filter((t) => !t.id.includes('share-'))
  }

  get user() {
    return this._current_user
      ? (this.state.users || {})[this._current_user]
      : null
  }

  get user_private() {
    return _.omit(this.user, [
      'access_token',
      'seen',
      'about',
      'secure',
      'server',
    ])
  }

  get in_share() {
    return this.user.location?.indexOf('/share/') > -1
  }

  user_name(id = this.user?.id) {
    let user = (this.users || {})[id]
    if (!user) return 'Anonymous'
    return (
      user?.name ||
      toCapitalCase(_.first(user?.email?.split('@'))) ||
      'Anonymous'
    )
  }

  last_seen(o) {
    return this.user?.seen[o.id] || 0
  }

  team_users(team = this.team) {
    if (!team?.access) return []
    return keys(_.pickBy(team.access, (a, id) => a.t == 'user')).map(
      (u) => this.users[u],
    )
  }

  in_teams(doc, share = false) {
    if (!doc?.access) return []
    return keys(
      _.pickBy(
        doc.access,
        (a, id) => a.t == 'team' && (share || !id.includes('share-')),
      ),
    )
  }

  in_team(doc, team_id = this.team?.id) {
    return doc && (doc.access?.['all'] || !!doc.access?.[team_id])
  }

  is_admin(doc, user_id = this.user?.id) {
    return doc && doc.access?.[user_id]?.p?.includes('admin')
  }

  admin(doc) {
    return (
      doc &&
      entries(doc.access)
        ?.filter(([k, v]) => v.p == 'admin')
        .at(0)
        ?.at(0)
    )
  }

  get is_vello_admin() {
    return this.in_team(this.user, '81ag9d61')
  }

  can_write(doc, user_id = this.user?.id) {
    return doc && ['write', 'admin'].includes(doc.access?.[user_id]?.p)
  }

  get rooms_created() {
    var rms = values(this.state.rooms)
      .filter((r) => this.in_team(r))
      .sort(recent)
      .reverse()
    return rms
    // return _.groupby(rms, r => r.pinned)
  }

  get pinned_rooms() {
    var rms = values(this.state.rooms).filter((r) => r.pinned)
    return rms
    // return _.groupby(rms, r => r.pinned)
  }

  roomsQuery(q): any[] {
    var res = this.rooms_grouped(q)
    if (q.length > 1) {
      const msgs: any = values(this.messages)
        .filter(
          (m) =>
            this.message_content_text(m)
              ?.toLowerCase()
              .indexOf(q.toLowerCase()) != -1,
        )
        .sort(recent)
        .slice(0, 30)
      if (msgs.length) res.push(['Message Results', msgs])
    }
    return res
  }

  rooms_grouped(q = '') {
    var things = _.groupBy(
      this.rooms_created
        .filter((r) => r.title?.toLowerCase().indexOf(q.toLowerCase()) !== -1)
        .filter((r) => this.user.show?.archived || !r.archived)
        .reverse(),
      (r) =>
        r.archived ? 'Archived' : r.pinned ? 'Pinned Chats' : 'All Chats',
    )

    let bookmarked = values(this.messages)
      .filter((m) => m.bookmarked && m.content.indexOf(q) !== -1)
      .filter((m) => this.in_team(m))
    if (bookmarked.length) things['Bookmarked Messages'] = bookmarked
    return entries(things).sort().reverse()
  }

  get rooms_flat() {
    return _.flatten(this.rooms_grouped().map((x) => x[1])).reverse()
  }

  get rooms_mru() {
    return this.rooms_created.sort(
      (a: any, b: any) => this.last_seen(b) - this.last_seen(a),
    )
  }

  persona_rooms(pid = this.current_persona) {
    return this.rooms_mru.filter((r) => r.model == pid && !r.canonical)
  }

  @action
  switch() {
    this.go(this.rooms_mru[1])
  }

  get user_current_message() {
    if (this.user_location()?.indexOf('/room') == -1) return null
    if (this.user_location().indexOf('/message') == -1) return null
    return this.user_location().split('/').at(-1)
  }

  user_location(u = this.user) {
    return u?.location ?? '/'
  }

  get user_current_room() {
    return this.user_current_room_(this.user)
  }

  get current_persona() {
    var loc = this.user_location()
    if (!_.some(['/hello/', '/publish/'], (s) => loc.includes(s)))
      return this.user?.current_persona_id
    return loc.split('/').at(-1)
  }

  get persona() {
    return this.users[this.current_persona]
  }

  get message_placeholder() {
    return this.room_models().length > 1
      ? `Ask ${this.room_models().length} AI Personas anything`
      : this.users[this.room_model()]?.name
        ? `Ask ${this.users[this.room_model()].name} anything`
        : 'Message room'
  }

  @action
  sendMessage() {
    if (this.getCurrentMessage() == '') return
    if (this.room.streaming) this.stopStreaming()
    var mid = this.addMessage({ content: this.getCurrentMessage() })
    this.setCurrentMessage('')
    return mid
  }

  @memo((s) => [s.user?.location])
  user_current_room_(u) {
    var loc = this.user_location(u)
      .replace('/share/', '/room/')
      .replace('/rooms/', '/room/')
    if (_.some(['/hello/', '/publish/'], (s) => loc.includes(s)))
      return this.canonical_id(this.current_persona)
    return loc.split('room/').slice(-1)[0].split('/')[0]
  }

  get in_publish() {
    return this.user_location().includes('/publish/')
  }

  canonical_id(pid) {
    return `${pid}-${this.user.id}`
  }

  get room() {
    return this.user_current_room
      ? this.state.rooms[this.user_current_room]
      : null
  }

  @action
  setNoteContents(markdown, room_id, note_id = null) {
    let id
    if (note_id && note_id.length > 0) {
      id = note_id
    } else if (this.room.note && this.room.note.length > 0) {
      id = this.room.note
    } else {
      id = nid()
    }
    this.save({
      id: id,
      type: 'note',
      content: markdown,
      archived: false,
    })
    this.setCurrentFile(room_id, id)
  }

  get current_file() {
    if (!this.room) {
      return this.all_files()[this.user_location().split('/').at(-1)] ?? null
    } else if (this.room.current_file) {
      return this.all_files()[this.room.current_file]
    } else if (this.room.doc) {
      // doc migration here
      const note_id = nid()
      this.setNoteContents(this.room.doc, this.room.id, note_id)
      return this.all_files()[note_id]
    }
    return null
  }

  @action
  setFileName(file_id: string, title: string) {
    this.save({
      ...this.all_files()[file_id],
      name: title,
    })
  }

  @action
  setCurrentFile(room_id, file_id) {
    if (!room_id) {
      return
    }
    var room = this.rooms[room_id]
    room.current_file = file_id
    if (this.all_files()[file_id].type == 'note') room.note = file_id
    this.collapseRight(false)
  }

  get flex_messages() {
    return values(this.messages)
      .filter((m) => m.used_flex && !m.error && !m.warning)
      .sort(recent)
  }

  @memo((s) => [s.user?.location, s.state.messages])
  room_messages(room_id = this.user_current_room, { tools = false } = {}) {
    if (!room_id) return []
    return values(this.state.messages)
      .filter(
        (m) =>
          m.room_id == room_id &&
          m.role != 'system' &&
          (m.role != 'tool' || tools) &&
          !m.deleted &&
          !m.on,
      )
      .sort((a, b) =>
        a.order == b.order
          ? a.update_time < b.update_time
            ? 1
            : -1
          : a.order < b.order
            ? 1
            : -1,
      )
      .reverse()
  }

  @action
  setModel(model, save = true) {
    var old_model = this.room.model
    if (this.room?.models?.length) {
      this.room.models[0] = model
      this.room.model = model
    } else if (this.room) this.room.model = model
    if (model == 'doc') this.collapseRight(false)
    if (old_model == 'doc' && this.note == '') this.collapseRight(true)
    if (save) this.user._model = model
  }

  get ai() {
    return this.users[this.model_id]
  }

  // base model
  get model() {
    return this.ai?.model
  }

  // owner_id
  get model_id() {
    return this.room?.model ?? this.user?._model ?? this.state._model
  }

  get models() {
    return this.ai_users.filter((m) => !this.characters.includes(m))
  }

  @action
  addAIMessage(
    message,
    model = this.model_id,
    owner_id = null,
    room_id = null,
  ) {
    this.clearState()
    var id = this._addMessage(
      {
        model: model,
        ai: true,
        content: '',
        ...message,
      },
      room_id,
      owner_id,
    )
    return id
  }

  user_level(user = this.user, flex = true) {
    if (this.user.anonymous) return 0
    var plan = this.plan
    if (!plan || (plan.status == 'trialing' && plan.cancelled)) return 1
    if (plan.name == 'basic') return 2
    if (plan.name == 'plus') return 3
    if (plan.name == 'pro') return 4
    if (plan.name == 'team') return 5
  }

  limits(model = this.model, user = this.user, flex = true) {
    model = this.users[model]?.model ?? model
    var level = this.user_level(user, flex)
    var lim: any
    if (
      [
        'openai/gpt-4-vision-preview',
        'openai/gpt-4-classic',
        'openai/gpt-4',
        'web',
        'doc',
        'anthropic/claude-2',
        'anthropic/claude-3-opus',
        'anthropic/claude-3-sonnet',
        'mistral/mistral-large-latest',
      ].includes(model)
    ) {
      if (level == 0) {
        //anon
        lim = {
          all: 17,
          weekly: 12,
          daily: 6,
          hourly: 4,
        }
      } else if (level == 1) {
        //signed in
        lim = {
          all: 35,
          weekly: 30,
          daily: 20,
          hourly: 10,
        }
      } else if (this.plan.status == 'trialing') {
        //trialing
        lim = {
          all: 60,
          weekly: 55,
          daily: ['web', 'doc'].includes(model) ? 20 : 40,
          hourly: 30,
        }
      } else if (level == 2) {
        // basic
        lim = {
          all: Infinity,
          weekly: 200,
          daily: ['web', 'doc'].includes(model) ? 20 : 30,
          hourly: 15,
        }
      } else if (level == 3) {
        // plus
        lim = {
          all: Infinity,
          weekly: 500,
          daily: ['web', 'doc'].includes(model) ? 30 : 90,
          hourly: 30,
        }
      } else if (level == 4) {
        // pro
        lim = {
          all: Infinity,
          weekly: 1000,
          daily: 300,
          hourly: 80,
        }
      } else if (level == 5) {
        // team
        lim = {
          all: Infinity,
          weekly: Infinity,
          daily: 300 * (this.plan_always.seats ?? 1),
          hourly: 50 * (this.plan_always.seats ?? 1),
        }
      }
    } else {
      if (level == 0) {
        lim = {
          all: 17,
          weekly: 12,
          daily: 6,
          hourly: 4,
        }
      } else if (level < 2 || this.plan.status == 'trialing') {
        lim = {
          all: 70,
          weekly: 60,
          daily: 30,
          hourly: 15,
        }
      } else {
        lim = {
          all: Infinity,
          weekly: Infinity,
          daily: Infinity,
          hourly: 100,
        }
      }
    }

    //flex override above
    if (flex && this.plan?.has_flex) {
      lim.all = Infinity
      lim.weekly = Infinity
      lim.daily = Infinity
      lim.hourly = Infinity
      lim.all_global = Infinity
      lim.period_cost = Infinity
    } else if (level == 0) {
      //anon
      lim.all_global = FL('signed-out-messages', 2)
      lim.period_cost = FL('signed-out-cost', 2)
    } else if (level == 1) {
      //signed in
      lim.all_global = FL('signed-in-messages', 10)
      lim.period_cost = FL('signed-in-cost', 2)
    } else if (this.plan.status == 'trialing') {
      lim.all_global = FL('paid-trial-messages', 26)
      lim.period_cost = FL('trial-cost', 4)
    } else {
      lim.all_global = Infinity
      if (this.plan?.name == 'plus') lim.period_cost = FL('plus-cost', 15)
      else if (this.plan?.name == 'pro') lim.period_cost = FL('pro-cost', 30)
      else if (this.plan?.name == 'team') lim.period_cost = FL('team-cost', 35)
    }
    return { ...lim, ..._.pick(this.user, ['all_global', 'all']) }
  }

  usage(model = this.model) {
    var all_global = values(this.messages).filter((m) => !m.imported)
    if (this.plan_always) {
      var period_start = new Date(this.plan_always.ending * 1000)
    } else {
      var period_start = new Date()
    }
    period_start.setMonth(period_start.getMonth() - 1)
    var period_cost =
      all_global
        .filter((m) => m.ai && m.create_time > period_start.getTime())
        .map((m) => m.input_cost + m.output_cost)
        .filter((c) => c > 0)
        .reduce((a, b) => a + b, 0) ?? 0
    var all = all_global.filter((m) => m.model == model)
    var weekly = all.filter((m) => m.create_time > Date.now() - week)
    var daily = weekly.filter((m) => m.create_time > Date.now() - day)
    var hourly = daily.filter((m) => m.create_time > Date.now() - hour)
    return {
      period_cost,
      all_global: all_global.length,
      all: all.length,
      weekly: weekly.length,
      daily: FL('u_dt', daily.length),
      hourly: hourly.length,
    }
  }

  @memo((s) => [s.model, s.messages, s.plan, s.user.email])
  check(model = this.user?.last_warning_model ?? this.model, flex = true) {
    var usage = this.usage(model)
    var limits = this.limits(model, this.user, flex)
    var r: any = {
      period_cost: Math.max(0, limits.period_cost - usage.period_cost),
      all_global: Math.max(0, limits.all_global - usage.all_global),
      all: Math.max(0, limits.all - usage.all),
      weekly: Math.max(0, limits.weekly - usage.weekly),
      daily: Math.max(0, limits.daily - usage.daily),
      hourly: Math.max(0, limits.hourly - usage.hourly),
    }
    r.ok =
      r.all > 0 &&
      r.all_global > 0 &&
      r.weekly > 0 &&
      r.daily > 0 &&
      r.hourly > 0 &&
      r.period_cost > 0
    if (r.all_global <= 0) r.message = `😔 You're all out of messages.`
    else if (r.period_cost <= 0)
      r.message = `😔 You've reached your plan limit for this month.`
    else if (r.all <= 0) r.message = `${r.all} messages left for this model.`
    else if (r.weekly <= 0)
      r.message = `${r.weekly} messages left for this model this week.`
    else if (r.daily <= 0)
      r.message = `${r.daily} messages left for this model today.`
    else if (r.hourly <= 0)
      r.message = `${r.hourly} messages left for this model the next hour.`
    // r.message = `${r.weekly} weekly, \n${r.daily} daily, and ${r.hourly} hourly messages left.`
    return r
  }

  transform(message, base_url = 'https://localhost/file') {
    var annotations = message.annotations || []
    if (message.text !== undefined) {
      message = message.text
    }
    if (message.value !== undefined) {
      message = message.value
    }
    if (message.content?.parts !== undefined) {
      message = message.content.parts[0]
    }
    //for math
    annotations.map((a) => {
      if (a.type == 'file_path') {
        message = message.replace(a.text, base_url + '/' + a.file_path.file_id)
      }
    })
    if (!message.replaceAll) return ''
    return message
      .replaceAll('\\[', '\n$$$\n')
      .replaceAll('\\]', '\n$$$\n')
      .replaceAll('\\(', '$$$')
      .replaceAll('\\)', '$$$')
      .replace(/【.*?】/g, '')
  }

  get plan() {
    var plan = this.plan_always
    if (!plan) return null
    return plan.is_active && new Date() < new Date(plan.ending * 1000)
      ? plan
      : null
  }

  get plan_expired() {
    return (
      !this.plan_always.ending ||
      new Date() >= new Date(this.plan_always.ending * 1000)
    )
  }

  get plan_always() {
    return (
      this.user?.secure?.plan ??
      this.team_users()
        .map((x) => x?.secure?.plan)
        .filter((p) => p?.name == 'team')
        .at(0)
    )
  }

  get unlogged_plan_changes() {
    return jsonpatch.compare(
      this.user?.secure?.logged_plan ?? {},
      this.user?.secure?.plan ?? {},
    )
  }

  @action
  setPlanLogged() {
    this.user.secure.logged_plan = this.user.secure.plan
  }

  user_message(
    model = this.user?.last_warning_model ?? this.model,
    owner_id = this.model_id,
  ) {
    if (this.user.warning)
      return {
        title: 'Message Too Long',
        type: 'warning',
        content: this.user.warning,
      }
    var check = this.check(model)
    if (check.ok) return
    var content, type
    //anon
    var label = '➥ Sign Up'
    var msg = 'Sign up for a free Vello account to keep chatting.'
    var toggle = 'space_signup'
    var title = `Continue this conversation with ${this.users[owner_id ?? model]?.name}?`
    if (!this.user.anonymous) {
      title = `No more messages left with ${this.users[owner_id ?? model]?.name}.`
      if (
        this.plan_always &&
        !['canceled', 'active', 'trialing'].includes(this.plan_always?.status)
      ) {
        label = '➥ Fix Payment'
        toggle = 'pricing_drawer'
        msg = 'Payment Issue: update your payment method to continue.'
      } else if (this.plan_always?.cancelled) {
        toggle = 'pricing_drawer'
        // toggle = 'pricing_drawer'
        msg =
          'Plan canceled. Renew your plan to continue this conversation now.'
      } else if (!this.plan) {
        label = '➥ Join'
        toggle = FL('pricing-table') ? 'pricing_table' : 'pricing_drawer'
        // toggle = 'pricing_drawer'
        msg = 'Start a free trial to continue this conversation now.'
      } else {
        label = '➥ Upgrade'
        toggle = 'pricing_drawer'
        if (this.plan.name == 'pro')
          msg =
            'You are reaching our standard capacity limits. Choose **Enable Flex** to enable additional messages at cost. Enabling Flex costs nothing and you can see each message cost live. See [details](https://docs.vello.ai/plans#vello-flex).'
        else
          msg =
            'Upgrade to the **Pro Plan** to increase your rate limits and continue this conversation now.'
        if (this.plan.cancelled)
          msg =
            'Subscription cancelled. Please re-activate your plan to continue, or [email us](mailto:hello@vello.ai) with any feedback.'
      }
    }

    if (this.user.anonymous) {
      content = `${msg}`
      // type = 'toast'
      return { label, content, toggle, type, title }
    } else {
      if (this.plan?.status == 'trialing' && !this.plan?.cancelled) {
        return {
          type: 'warning',
          title: `😔 You've reached the Free Trial Limit.`,
          toggle: 'pricing_drawer',
          content: `You're all out of messages on your free trial. Choose \`Start Paid Plan\` to end the trial, remove limits and continue your conversation now.`,
        }
      } else {
        return {
          type: 'warning',
          toggle,
          title: `No more messages left with ${this.users[owner_id ?? model]?.name}.`,
          content: `${check.message} ✨ ${msg}`,
        }
      }
    }
  }

  do_limit_check(model, owner_id) {
    var user_message = this.user_message(model, owner_id)
    if (!user_message) return true
    lg('warning', user_message, this)
    this.user.last_warning_model = model
    // if (this.user.anonymous) lg('funnel', { step: 'anon_sign_up' }, this)
    if (user_message.type == 'toast') {
      this.setToast({
        ...user_message,
        level: 'warning',
        type: 'check',
        options: {
          id: 'sign_up',
          action: {
            label: user_message.label,
          },
          duration: Infinity,
        },
      })
    } else {
      lg('funnel', { step: user_message.toggle, message_send: true }, this)
      this.toggle(user_message.toggle, true)
    }
    return false
  }

  @action
  startAIResponse(
    model = this.model_id,
    owner_id = null,
    system = '',
    room_id = null,
    on = undefined,
    silent = false,
  ) {
    var ok = this.do_limit_check(model, owner_id)
    if (!ok) return
    this.clearState()

    var id = this._addMessage(
      {
        model: model,
        ai: true,
        content: '',
        streaming: Date.now(),
        system,
        on,
        silent,
      },
      room_id,
      owner_id,
    )

    var ok = this.do_limit_check(model, owner_id)
    if (!ok) {
      this.messages[id].hidden = true
    } else {
      this.messages[id].used_flex =
        this.messages[id].used_flex ??
        (this.plan?.has_flex && !this.check(model, false).ok)
    }
    this.room.streaming = Date.now()
    return id
  }

  file_pond(f) {
    return {
      source: f.oid,
      id: f.id,
      options: {
        type: 'local',
        file: {
          id: f.id,
          name: f.filename,
          size: f.fileSize,
          type: f.fileType,
        },
      },
    }
  }

  user_code_name(user) {
    return this.user_name(user.id).replace(/[^a-zA-Z0-9_-]/g, '_') // + '-' + user.id;
  }

  user_by_name(name) {
    return (
      name && this.ai_users.filter((u) => this.user_code_name(u) == name).at(0)
    )
  }

  @action
  setCurrentMessage(content) {
    if (!this.room.typers) this.room.typers = {}
    this.room.typers[this.user.id] = {
      create_time: Date.now(),
      content: content,
    }
  }

  room_typers(room_id = this.user_current_room) {
    return keys(this.room.typers || {})
      .filter(
        (id) =>
          id != this.user.id &&
          this.room.typers[id].content?.length &&
          this.room.typers[id].create_time > Date.now() - minute,
      )
      .map((id) => this.users[id])
  }

  getCurrentMessage() {
    return this.room?.typers ? this.room.typers[this.user.id]?.content : ''
  }

  @action
  togglePinned(id = this.user_current_room) {
    if (!id) return
    if (this.rooms[id]) this.rooms[id].pinned = !this.rooms[id].pinned
    if (this.messages[id])
      this.messages[id].bookmarked = !this.messages[id].bookmarked
  }

  get persona_card_user() {
    return typeof this.user.show?.persona_card == 'string'
      ? this.users[this.user.show?.persona_card]
      : this.persona
  }

  @action
  toggleBookmarked(id) {
    this.messages[id].bookmarked = !this.messages[id].bookmarked
  }

  getIsStreaming(id) {
    var s =
      this.state.messages[id]?.streaming || this.state.rooms[id]?.streaming
    return s && Date.now() - s < 60 * 1000
  }

  @action
  setLayout(layout) {
    layout = layout.map((size) => Math.round(size))
    var s = _.sum(layout)
    if (s !== 100) {
      layout[0] = Math.round(layout[0])
      layout[1] = Math.round(layout[1])
      // deal with rounding error
      if (layout[0] + layout[1] > 100) layout[1] -= 1
      layout[2] = 100 - layout[0] - layout[1]
    }
    if (!shallowEqual(this.user.layout, layout)) {
      this.user.layout = layout
    }
    this.state._dummy = Math.random()
  }

  @action
  collapseLeft(collapse) {
    let layout = this.user.layout ?? [20, 40, 40]
    if (collapse) {
      layout[1] = layout[1] + layout[0]
      layout[0] = 0
    } else if (layout[0] == 0) {
      layout[0] = 20
      layout[1] -= layout[0]
    }
    this.setLayout(layout)
  }

  @action
  collapseRight(collapse) {
    if (!this.is_mobile) {
      let layout = this.user.layout ?? [20, 40, 40]
      if (collapse) {
        layout[1] = layout[1] + layout[2]
        layout[2] = 0
      } else if (layout[2] == 0) {
        layout[1] = ((100 - layout[0]) * 4) / 5
        layout[2] = (layout[1] * 1) / 5
      }
      this.setLayout(layout)
    }
    this.toggle('notes_open', !collapse)
  }

  @action
  endAIResponse(m_id) {
    this.state._ai_edit = false
    this._prev_doc[m_id] = null
    var message = this.state.messages[m_id]
    message.streaming = false
    this.rooms[message.room_id].streaming = false
    return message.id
  }

  exportRoom(share_type = 'chat', room_id = this.room.id) {
    this.unshowAll()
    var users: any = new Array()
    var messages: any = new Array()
    var room = _.cloneDeep(this.rooms[room_id])
    if (share_type == 'chat') {
      room.note = null
    }

    if (share_type != 'doc') {
      messages = this.room_messages(room_id)
      users = _.uniqBy(messages, 'owner_id').map((m) =>
        _.omit(this.users[m.owner_id], ['seen', 'access', 'focused']),
      )
    }

    // Convert room, messages, and users to objects
    var rooms = { [room_id]: room }
    users = _.keyBy(users, 'id')
    messages = _.keyBy(messages, 'id')
    return {
      rooms,
      messages,
      users,
      notes: this.notes,
      files: this.files,
    }
  }

  @action
  stopStreaming() {
    this.room.streaming = false
    this.room_messages().map((m) => {
      delete m.streaming
    })
    return this.room.id
  }

  @action
  regenResponse() {
    this.stopStreaming()
    if (this.last_message.model)
      this.state.messages[this.last_message.id].deleted = true

    return this.last_human_message.id
  }

  @action
  rewriteLastMessage() {
    this.stopStreaming()
    if (this.last_message.model)
      this.state.messages[this.last_message.id].deleted = true
    if (this.last_human_message) {
      var content = this.last_human_message.content
      this.last_human_message.deleted = true
      //put the last message back into the input
      this.setCurrentMessage(content)
    }
    this.checkpoint()
  }

  @action
  reloadLastMessage() {
    if (this.last_human_message) {
      var content = this.last_human_message.content
      //put the last message back into the input
      this.setCurrentMessage(content)
    }
    this.checkpoint()
  }

  @action
  appendToMessage(m_id, chunk, error: any = false) {
    if (!this.messages[m_id]?.streaming) return
    if (!this._prev_doc[m_id]) {
      this._prev_doc[m_id] = new Omap(this.note.split('\n'))
    }
    var message = this.state.messages[m_id]
    var prev = message
    message.content += chunk
    if (error) {
      if (error == 'warning') {
        message.warning = true
        this.user.warning = chunk
        this.toggle('pricing_drawer')
      } else {
        message.error = true
      }
    }
    this.touch(message)
    // if (message.model == 'doc') {
    let switched_to = this._process(prev, message.content, chunk, m_id)
    // }
  }

  // ::remove(#a0)::
  // ::remove(#a0, #d2)::
  // ::move(#bc, above=#c3)::
  // ::add(below=#xy)::
  // # This is a header
  // - and this is a list item
  // another line
  // ::end::

  _getid(arg) {
    return arg ? parseInt(arg.match(/\d+/g)?.join('')) : 0
  }

  _processCommand(command, m_id) {
    this.state._command = command.split('(')[0].trim()
    if (this.state._command == 'photo')
      this.state._args = _.first(command.match(/\((.*?)\)/))
    else {
      this.state._args =
        _.first(command.match(/\((.*?)\)/))
          ?.split(',')
          .map((arg) => arg.trim()) || []
      this.state._args = this.state._args
        .map((arg) => {
          if (arg.includes('-')) {
            let [start, end] = arg.split('-').map(this._getid)
            return Array(end - start + 1)
              .fill(0)
              .map((_, idx) => start + idx)
          } else if (arg.includes('above') || arg.includes('below')) {
            return arg
          } else {
            return [this._getid(arg)]
          }
        })
        .flat()
    }
    if (this.state._command !== 'add') {
      this.state._ai_edit = true
      this._applyCommand(m_id)
    } else {
      this.state._ai_edit = false
      this.state._addCommand = command // Keep track of add command
    }
  }

  _applyCommand(m_id) {
    var cmd = this.state._command
    var args = this.state._args
    var new_doc = this.note.split('\n')
    if (cmd == 'remove') {
      args.forEach((id) => {
        if (typeof id !== 'string') this._prev_doc[m_id].delete(id)
      })
    } else if (cmd == 'move') {
      let targetIndex = args.find((arg) => typeof arg === 'string')
      let movedElements = args
        .filter((arg) => typeof arg !== 'string')
        .map((id) => this._prev_doc[m_id].get(id))
      // Remove elements to move from the document
      args
        .filter((arg) => typeof arg !== 'string')
        .forEach((id) => this._prev_doc[m_id].delete(id))
      // Insert the moved elements at the target location
      this._prev_doc[m_id].insert(
        movedElements,
        this._getid(targetIndex),
        !targetIndex.includes('above'),
      )
    } else if (cmd === 'endadd') {
      let addArgs = _.get(this.state._addCommand.match(/\((.*?)\)/), '[1]', '')
        .split(',')
        .map((arg) => arg.trim())
      let targetIndex = addArgs.find((arg) => typeof arg === 'string')
      let addedText = this.messages[m_id].content
        .split(/::add.*?::/)
        .at(-1)
        .split('::endadd::')[0]
      // Insert the new text at the target location
      if (!targetIndex) targetIndex = '#a' + this._prev_doc[m_id].getLastId()
      this._prev_doc[m_id].insert(
        [addedText],
        this._getid(targetIndex),
        !targetIndex.includes('above'),
      )
    } else if (cmd === 'photo') {
      var id = nid()

      this.updateMessage(this.last_message.id, {
        content_display: (
          this.last_message.content_display ?? this.last_message.content
        ).replace(/\::photo\((.*)\)::/, (m, g) => {
          this.triggerTool(
            'make_image',
            { prompt: g },
            this.last_message.id,
            id,
          )
          return `::tool_call(${id})::`
        }),
      })
    }
    this._prev_doc[m_id].log()
    const room = this.rooms[this.messages[m_id].room_id]
    this.setNoteContents(
      this._prev_doc[m_id].getAll().join('\n'),
      room.id,
      room.note,
    )
  }

  @action
  triggerTool(tool, args, mid, id) {
    this.state._tool_call = { tool, args, mid, id }
    console.log(`this.state._tool_call`, this.state._tool_call)
  }

  @action
  writeToolResult(mid, id, result) {
    // this.state._tool_call = null;
    var msg = this.messages[mid]
    this.updateMessage(mid, {
      content_display: msg.content_display.replace(
        new RegExp(`::tool_call\\(${id}\\)::`),
        (m, g) => {
          return `![image](${result})`
        },
      ),
    })
  }

  _process(prev, next, chunk, m_id) {
    if (chunk.includes('::')) {
      this.state._in_command = !this.state._in_command
      if (!this.state._in_command) {
        this._processCommand(next.split('::').at(-2), m_id)
      }
      return this.state._in_command
    }
  }

  get last_message() {
    return this.room_messages().at(-1)
  }

  get last_human_message() {
    return this.room_messages()
      .filter((m) => !this.is_ai(m))
      .at(-1)
  }

  mentioned_users(str) {
    var mentioned_users = []
    this.active_users.map((u) => {
      const look_for = _.escapeRegExp(
        '@' + this.user_name(u.id)?.replaceAll(' ', '-'),
      )
      //match word boundary, exclude -
      if (str.search(new RegExp(`${look_for}(?![\w-])`)) != -1) {
        mentioned_users.push(u)
      }
    })
    return mentioned_users
  }

  last_message_mentions(room = this.room) {
    const message = this.message_content_text(this.last_human_message)
    return this.mentioned_users(message)
  }

  refreshTitle(room_id) {
    let r = this.rooms[room_id]
    if (!r.title || r.title == DEFAULT_TITLE) {
      r.title = this.room_title(r.id)
    }
  }

  @action
  setAllTitles() {
    values(this.rooms).forEach((r) => this.refreshTitle(r.id))
  }

  @action
  editModel(model_id = this.model_id) {
    if (this.persona) {
      this.toggle('persona_card')
      return
    }
    var model = this.users[model_id]
    if (
      !this.is_vello_admin &&
      (!this.can_write(model) || this.persona || model.builtin || !model.ai)
    ) {
      this.toggle('persona_card', model_id)
      return
    }

    this._edit_model = model_id
    this.toggle('update_model')
  }

  room_next_files(room = this.room) {
    return this.files_on(room).filter((f) => f.next)
  }

  @action
  addMessage(msg, room_id = null, owner_id = null) {
    var ok = this.do_limit_check(this.model, owner_id)
    if (!ok) {
      this.setCurrentMessage(msg.content)
      return
    }
    room_id = room_id ?? this.user_current_room
    var files = this.room_next_files(this.rooms[room_id])
    files.map((f) => delete f.next)
    msg.files = files.map((f) => f.id)
    msg.content = this.msg_file_content(msg.content, files)
    this.setFocused()
    this.checkpoint()
    return this._addMessage(msg, room_id, owner_id)
  }

  msg_file_content(content, files) {
    var images = files.filter((f) => f.fileType.includes('image'))
    if (images.length) {
      content = [{ type: 'text', text: content }]
      images.map((f) =>
        content.push({
          type: 'image_url',
          image_url: { url: this.file_url(f) },
        }),
      )
    }
    return content
  }

  @action
  addMessageSilent(msg, room_id = null, owner_id = null) {
    room_id = room_id ?? this.user_current_room
    return this._addMessage(msg, room_id, owner_id, true)
  }

  _addMessage(msg, room_id = null, owner_id = null, silent = false) {
    if (
      this.user.current_persona_id &&
      this.rooms[room_id].canonical &&
      !this.users[this.user.current_persona_id].stable
    ) {
      room_id = this.duplicateRoom(room_id)
      var r = this.rooms[room_id]
      r.canonical = false
      r.archived = false
      r.title = this.message_content_text(msg).trunc(15)
      this.go(r)
    }

    room_id = room_id ?? this.user_current_room
    owner_id = owner_id ?? msg.owner_id ?? this._current_user
    let id = msg.id ?? nid()
    msg = { ...msg, id, room_id, owner_id, type: 'message' }
    const room = this.rooms[room_id]
    if (!room.members?.includes(owner_id)) {
      if (!room.members) this.rooms[room_id].members = []
      room.members.push(owner_id)
    }

    if (!this.user.message_count)
      this.user.message_count = values(this.state.messages).length
    if (!this.user.ai_message_count)
      this.user.ai_message_count = values(this.state.messages).filter(
        (m) => m.ai,
      ).length
    if (!room.message_count) room.message_count = 0
    room.message_count++
    this.user.message_count++
    if (msg.ai) this.user.ai_message_count++
    msg.order = room.message_count
    if (this.user.message_count == 1) {
      lg('funnel', { step: 'first_message' }, this)
    }

    this.user.warning = false
    this.save(msg)
    if (!silent) {
      this.touch(this.deref(room_id))
      this.refreshTitle(room_id)
      //i think this is for cmdk, but disabling for ai background chats
      if (!room.canonical && !room.archived) this.go(room)
    }
    return msg.id
  }

  go(thing) {
    if (!thing) {
      console.error('tried to nav to undefined thing')
      return
    }
    //don't put logic here just call nav and put all logic there
    // if (thing.canonical) {
    // if (false) {
    //   this.nav(`/hello/${thing.model}`)
    //} else
    if (thing.type == 'user') {
      this.nav(`/hello/${thing.id}`)
    } else if (thing.type == 'message') {
      this.nav(`/room/${thing.room_id}/message/${thing.id}`)
    } else {
      this.nav(`/${thing.type}/${thing.id}`)
    }
  }

  touch(doc) {
    doc.update_time = Date.now()
  }

  get(path) {
    return _.get(this.state, path)
  }

  ref(thing) {
    if (thing.id_) return thing
    return { type_: thing.type, id_: thing.id }
  }

  files_on(on) {
    if (!on) return []
    if (!on.id_) on = this.ref(on)
    if (on.type_ == 'message')
      return this.deref(on).files?.map((f) => this.deref(f)) ?? []
    return values(this.state.files ?? {}).filter((f) => is(f.on, on)) ?? []
  }

  deref(ref) {
    if (ref.id) return ref
    var type = ref.type ?? ref.type_

    if (ref.indexOf && ref.indexOf('/') > -1)
      return _.get(this.state, ref.split('/'))
    else if (!type) {
      for (var i = 0; i < Store.types.length; i++) {
        var doc = _.get(this.state, [plural(Store.types[i]), ref])

        if (doc) return doc
      }
    } else if (ref.id_) return _.get(this.state, [plural(type), ref.id_])
  }

  get collections() {
    return Store.types.map((t) => this.state[plural(t)] || {})
  }

  current(doc) {
    return this.deref(this.ref(doc)) ?? {}
  }

  delete(doc) {
    delete this.state[plural(doc.type)][doc.id]
    //NOTE: put in archive col instead, but will be mixed col exception..
  }

  @action
  toggleUserPinned(user_id) {
    if (!this.user.pinned) this.user.pinned = {}
    this.user.pinned[user_id] = !this.user_pinned(user_id)
  }

  user_pinned(user_id) {
    const stored = this.user?.pinned?.[user_id]
    if (stored == undefined) return !this.users[user_id].unpinned
    return stored
  }

  archive(doc) {
    this.state[plural(doc.type)][doc.id].archived = true
  }

  save(doc, user_id = this.user?.id) {
    doc.id = doc.id ?? nid()
    var cdoc = this.current(doc)

    if (!cdoc?.create_time && !doc.create_time) {
      lg('create', doc, this)
      if (doc.type == 'user' && !doc.ai)
        lg('funnel', { step: 'new_user', ...doc }, this)
      doc.create_time = Date.now()
    }
    if (!cdoc.owner_id && !doc.owner_id) doc.owner_id = this.user?.id
    if (!doc.update_time) doc.update_time = Date.now()
    var ndoc = { ...cdoc, ...doc }
    if (!this.state[plural(ndoc.type)]) this.state[plural(ndoc.type)] = {}
    this.state[plural(ndoc.type)][ndoc.id] = ndoc
    this.initAccess(ndoc, user_id)

    if (ndoc.type == 'user' /*&& _.isEmpty(cdoc.access)*/) {
      if (ndoc.builtin) {
        ndoc.access = {}
        this.giveAccessTo(this.ref(ndoc), { type_: 'push', id_: 'all' }, 'read')
      } else {
        //THIS NEEDS TO BE ON CREATE NOT SAVE for users
        if (!ndoc.ai) {
          this.addToTeam(ndoc.id)
          this.giveAccessTo(this.ref(ndoc), this.ref(ndoc), 'admin')
        }
      }
    }

    return ndoc.id
  }

  @action
  setType(id, type) {
    this.state[plural(type)][id].type = type
  }

  @action
  setDummyAccess(doc) {
    doc.access = {}
  }

  // set admin as current user, and write to current team
  @action
  initAccess(
    doc,
    user_id = this.user?.id,
    team_id = this.team?.id,
    privacy = 'write',
    reset = false,
  ) {
    // if (doc.access && !reset) return
    doc = this.state[plural(doc.type)][doc.id]
    if (!doc.access) doc.access = {}

    team_id = doc.type == 'team' ? doc.id : team_id

    if (team_id)
      if (team_id.includes('share-'))
        doc.access[team_id] = { p: 'read', t: 'team' }
      else doc.access[team_id] = { p: 'write', t: 'team' }

    if (this.user) doc.access[user_id] = { p: 'admin', t: 'user' }
  }

  @action
  giveAccessTo(
    doc_ref,
    to_ref = this.ref(this.user),
    privacy = 'write',
    overwrite = true,
  ) {
    if (!doc_ref) return
    var doc = this.deref(doc_ref)
    if (typeof to_ref == 'string')
      to_ref = { id_: to_ref.replace('.', '__'), type_: 'email' }
    // this.initAccess(doc)
    if (privacy == null) {
      delete doc.access[to_ref.id_]
      return
    }
    //type_ and id from ref
    if (doc.access[to_ref.id_] && !overwrite) return
    doc.access[to_ref.id_] = { p: privacy, t: to_ref.type_ }
  }

  @action
  setPublic(doc_ref) {
    var doc = this.deref(doc_ref)
    doc.access = {}
    doc.access['all'] = { p: 'read', t: 'push' }
  }

  //works for message or user
  is_ai(thing) {
    return (
      thing && (thing.model || thing.id == AI_ID || thing.owner_id == AI_ID)
    )
  }

  trigger(obs, id = nid()) {
    this.obs[id] = obs
    return id
  }

  untrigger(id) {
    delete this.obs[id]
  }

  watch(fn, callback, id = null, init = true) {
    id = id ?? nid()
    this.watchers[id] = {
      id,
      fn,
      callback,
      last: this.watchers[id] ? this.watchers[id].last : '@@',
    }
    if (init) this.notify_watch(this.watchers[id], { name: '@initialize' }, {})
    return id
  }

  unwatch(id) {
    delete this.watchers[id]
  }

  async notify_watch(w, action, meta) {
    let cur = w.fn(this)
    let prev = w.last
    w.last = cur
    if (!shallowEqual(cur, prev)) {
      await w.callback(action, meta, cur, prev, this)
    }
  }

  async notify(action, meta) {
    this.observers.map((o: any) => o(action, meta, this))
    // flushSync(() => {
    await Promise.all(
      values(this.watchers).map((w) => this.notify_watch(w, action, meta)),
    )
    // })
  }

  pointTo(ystate, binder, origin = 'external_init', user_id = null) {
    if (this.unsub) this.unsub()
    this.binder = binder
    this.ydoc = ystate
    if (_global.S)
      this.applyState(this.binder.get(), [
        { transaction: { origin, context: { token: { id: user_id } } } },
      ])
    this.unsub = this.binder.subscribe(this.applyState.bind(this))
  }

  @action
  setUserName(name, id = this.user.id) {
    this.users[id].name = name
  }

  @action
  setTeamName(name) {
    this.team.name = name
  }

  @action
  setUserAbout(about, id = this.user.id) {
    this.users[id].about = about
  }

  @action
  setTeamRespond(respond, id = this.user.id) {
    this.team.respond = respond
  }

  @action
  setPasswordRecovery(x) {
    //FIXME: this is a hack to force a re-render
    this.state._dummy = Math.random()
    this._password_recovery = x
  }

  @action
  setConfirmEmail(x) {
    //FIXME: this is a hack to force a re-render
    this.state._dummy = Math.random()
    this._confirm_email = x
  }

  async run(afn, silent = false) {
    var was_off = this._off
    this._off = true
    afn()
    this._off = was_off

    if (!silent) {
      await this.notify(action, {
        origin: this,
        state: this.state,
        prev: this.prev,
        user_id: this._current_user,
      })
    }
    return this._last_action
  }

  //apply external action
  async applyAction(act, origin = null) {
    console.info(':: apply external', act.name)
    this._applyAction(act)
    await this.notify(act, {
      origin,
      external: true,
      state: this.state,
      prev: this.prev,
      user_id: this._current_user,
    })
  }

  applyActionSilent(act) {
    this._applyAction(act)
  }

  _applyAction(act) {
    this.prev = this.state
    this.state = apply(this.state, act.patches)
    if (act.name.includes('undo:') || act.name.includes('redo:')) return
    this.history.push(act)
  }

  checkpoint() {
    this._checkpoint = true
    // this.checkpoints.push(this.back.length)
    // this.checkpointIndex = this.checkpoints.length - 1
  }

  off() {
    this._off = true
  }
  on() {
    this._off = false
  }

  get back() {
    return this.history.toReversed().slice(this.undo_spot)
  }

  get forward() {
    return this.history.slice(this.history.length - this.undo_spot)
  }

  undo(back = true) {
    // const actionStack = back ? this.back : this.forward
    // const oppositeStack = back ? this.forward : this.back

    const prefix = back ? 'undo:' : 'redo:'
    const patch_key = back ? 'inverse_patches' : 'patches'

    let stack = back ? this.back : this.forward

    if (stack.length == 0) return
    while (stack.length > 0) {
      const act = stack.shift()

      this.applyAction({
        ...act,
        name: prefix + act.name,
        patches: act[patch_key],
      })

      this.undo_spot += back ? 1 : -1
      if (act.checkpoint) {
        break
      }
      stack = back ? this.back : this.forward
    }
    this.setToast({
      options: { duration: 500 },
      content: back ? 'Undo' : 'Redo',
    })
  }

  @memo((s) => [s.state.notifs])
  latest_user_notifs(id = this.user?.id) {
    return (
      values(this.state?.notifs || {})
        .filter((n) => n.to == id)
        .sort(latest)
        .slice(0, 100) || []
    )
  }

  user_notifs(unread = false, id = this.user?.id) {
    //most recent first
    var ns = this.latest_user_notifs()
    if (unread)
      ns = ns.filter(
        (n) =>
          !this.user.seen['notifs'] ||
          (n.create_time > this.user.seen['notifs'] && !n.seen),
      )
    return ns
  }

  //these are happening inside action context (post)
  addNotif(notif) {
    var nid = this.save(notif, notif.to)
    this.setToast({ type: 'notif', id_: nid }, notif.to)
    return nid
  }

  postProcess(name, result) {
    // this.state._last_action = { name, result: result ?? null, id: nid() }
    if (name == 'addMessage' || name == 'endAIResponse') {
      var msg = this.messages[result]
      if (!msg || msg.silent) return
      this.in_room(msg.room_id)
        .filter((u) => {
          return (
            u &&
            msg.owner_id != u.id &&
            !u.ai &&
            (this.user_current_room_(u) != msg.room_id ||
              !u.is_focused ||
              u.is_focused < Date.now() - 60 * minute)
          )
        })
        .concat(this.last_message_mentions(msg.room_id).filter((u) => !u.ai))
        .map((u) => {
          this.addNotif({
            id: nid(),
            to: u.id,
            target: msg.id,
            ephemeral:
              this.user_current_room_(u) == msg.room_id &&
              u.last_focused &&
              u.last_focused > Date.now() - 2 * minute
                ? true
                : false,
            key: msg.room_id,
            type: 'notif',
          })
        })
    }
  }
}

//action_decorator
//mutations initiated here
function action(target, name, descriptor): any {
  const originalMethod = descriptor.value

  descriptor.value = function (...args) {
    let result
    let ouid = _global.uid
    _global.uid = this.user?.id

    if (this._in_action) {
      return originalMethod.apply(this, args)
    }
    this.prev = this.state

    const [next, patches, inverse_patches] = create(
      this.state,
      (draft) => {
        this.state = draft
        this._in_action = name
        // console.log(`>>>action `, name)
        try {
          result = originalMethod.apply(this, args)
          //post process after action
          this.postProcess(name, result)
        } catch (e) {
          console.log(
            `error during action ${name}, for user ${this.user?.id}: `,
            e,
          )
          this.state = this.prev
          // if (!_global.S) {
          throw e
          // } else {
          // console.error(e);
          // }
        } finally {
          this._in_action = null
        }
      },
      { enablePatches: { arrayLengthAssignment: false } },
    )

    this.state = next
    this._in_action_render = name
    const checkpoint = this._checkpoint
    this._checkpoint = false
    const action = {
      id: nid(),
      name,
      result,
      patches,
      inverse_patches,
      checkpoint,
      args,
    }
    this._last_action = action

    if (
      ![
        'toggleProp',
        'setToast',
        'set',
        'toggle',
        'unshowAll',
        'postBootstrap',
        'recordUserURLParams',
        'setFocused',
        'init',
        'bootstrap',
      ].includes(name)
    ) {
      this.undo_spot = 0
      this.history.push(action)
    }

    if (!this._off)
      this.notify(action, {
        origin: this,
        state: this.state,
        prev: this.prev,
        user_id: this._current_user,
      })

    //don't love this nonsense, I think its needed because react forceupdate is async
    later(() => (this._in_action_render = false))
    later(() => (this._user_edit = false))
    _global.uid = ouid
    return result
  }

  return descriptor
}

const store = new Store()

// @ts-ignore
_global.store = store

export default store
