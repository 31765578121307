import React, { StrictMode, PureComponent, ReactNode, RefObject } from 'react'
import * as Sentry from '@sentry/react'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import DOMPurify from 'dompurify'

import {
  Pencil1Icon,
  EyeClosedIcon,
  Share2Icon,
  ChevronDownIcon,
  DoubleArrowLeftIcon,
  DoubleArrowUpIcon,
  LapTimerIcon,
  FileIcon,
  ChatBubbleIcon,
  BoxIcon,
  PlusIcon,
  StopIcon,
  HomeIcon,
  LayersIcon,
  UpdateIcon,
  HeartIcon,
  StackIcon,
  DownloadIcon,
  EnterIcon,
  LightningBoltIcon,
  LockClosedIcon,
  HeartFilledIcon,
  EnterFullScreenIcon,
  ExitFullScreenIcon,
  QuestionMarkCircledIcon,
  BellIcon,
  ClipboardIcon,
  CheckIcon,
  DrawingPinFilledIcon,
  DrawingPinIcon,
  HamburgerMenuIcon,
  BookmarkIcon,
  BookmarkFilledIcon,
  GearIcon,
  MixerHorizontalIcon,
  CaretDownIcon,
  CalendarIcon,
  EnvelopeClosedIcon,
  FaceIcon,
  PersonIcon,
  PlusCircledIcon,
  UploadIcon,
  ExitIcon,
  RocketIcon,
  PaperPlaneIcon,
  PinRightIcon,
  PinLeftIcon,
  FileTextIcon,
  Cross1Icon,
  FilePlusIcon,
  FileMinusIcon,
  Cross2Icon,
  ResetIcon,
  CaretSortIcon,
  CircleIcon,
  ExternalLinkIcon,
  EraserIcon,
  ArrowLeftIcon,
  TrashIcon,
  CopyIcon,
} from '@radix-ui/react-icons'

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// Create a client
const queryClient = new QueryClient()

import { usersSchema } from './types'

import { lg } from './log.ts'
import { _global, FL } from './common_init'
import Nango from '@nangohq/frontend'

const nango = new Nango({ publicKey: '8ff1d931-3fb1-41b3-ad48-405191601dae' })
import { AgGridReact } from 'ag-grid-react' // React Grid Logic
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme

import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import RenderIfVisible from 'react-render-if-visible'
import {
  isToday,
  isYesterday,
  subWeeks,
  subMonths,
  subYears,
  isAfter,
  format,
  parseISO,
} from 'date-fns'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'

import ReactJson from '@microlink/react-json-view'
import { Switch } from '@/components/ui/switch'
import { motion, AnimatePresence } from 'framer-motion'
import { AiOutlineApple, AiFillApple, AiFillWindows } from 'react-icons/ai'
import { CSSTransition } from 'react-transition-group'
import { FileUploader } from 'react-drag-drop-files'
import { ReactTinyLink } from 'react-tiny-link'
import { capture, OutputType } from 'html-screen-capture-js'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Toaster, toast } from 'sonner'
import copy from 'copy-to-clipboard'
import { Drawer } from 'vaul'
import { HoldToConfirmButton } from './Components'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { X, Pencil, Bot, MoreHorizontal } from 'lucide-react'
import * as z from 'zod'
import { Fancybox as NativeFancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {
  nameToMention,
  getCaretCoordinates,
  replaceWord,
  remarkMentions,
} from './lib/mention_utils'

import { getCurrentWord } from './lib/mention_utils'

import { RiCoupon3Line } from 'react-icons/ri'

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

import {
  memo,
  Component,
  createRef,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react'
import BarLoader from 'react-spinners/BarLoader'
import SyncLoader from 'react-spinners/SyncLoader'
import BeatLoader from 'react-spinners/BeatLoader'
import MoonLoader from 'react-spinners/MoonLoader'
import PuffLoader from 'react-spinners/PuffLoader'

import basicLogo from '/basic.png'
import plusLogo from '/plus.png'
import proLogo from '/pro.png'

import logoUrl from '/logo-transparent.svg'
import logoCircledFlat from '/logo-circled-flat.png'
import logoCircled from '/logo-circled.png'
import notifSound from '/notif.ogg'

import { Auth } from '@/auth'
// import Home from './client'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Badge } from '@/components/ui/badge'
import {
  toCapitalCase,
  KEY_MOD,
  OPTION_MOD,
  key_to_symbol,
  downloadURI,
  post,
  nid,
  shallowEqual,
  ofilter,
  later,
  values,
  keys,
  entries,
  Base64,
  getParam,
  ago,
  rel,
  download,
  get_elem_value,
  latest,
  recent,
  env,
  get,
  jget,
  query,
  ob,
  CDN_URL,
  getSubdomain,
  getFileName,
  downloadMarkdown,
  is_vision_model,
  parse,
} from '@/lib/utils'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useTheme } from 'next-themes'
import * as RPopover from '@radix-ui/react-popover'
import { Command, useCommandState } from 'cmdk'

import {
  Logo,
  LinearIcon,
  FigmaIcon,
  SlackIcon,
  YouTubeIcon,
  RaycastIcon,
} from './icons'

import { BiUserPlus } from 'react-icons/bi'
import { RiSideBarFill } from 'react-icons/ri'

import { CiStickyNote } from 'react-icons/ci'

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'

import { Checkbox } from '@/components/ui/checkbox'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

import {
  Command as CommandC,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from '@/components/ui/command'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from '@/components/ui/dialog'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { BsArrowDownCircleFill } from 'react-icons/bs'
import { PiChatsLight } from 'react-icons/pi'
import { FiMoreVertical } from 'react-icons/fi'
import * as Y from 'yjs'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import ScrollToBottom, { AutoHideFollowButton } from 'react-scroll-to-bottom'
import Keys, { Commands } from './keys'
import { clearSession } from '@/session'
import { supabase, api, api_client } from '@/db'

import rehypeRaw from 'rehype-raw'

import _ from 'lodash'

import { Markdown } from 'tiptap-markdown'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import cn from 'classnames'

import CodeBlockPrism from 'tiptap-extension-code-block-prism'
import ReactMarkdown from 'react-markdown'

import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkGfm from 'remark-gfm'
// import remarkMentions from 'remark-mentions'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import { io } from 'socket.io-client'

// import { BlockNoteEditor } from '@blocknote/core'
// import { BlockNoteView, useBlockNote } from '@blocknote/react'
// import "@blocknote/core/style.css";

import './styles.css'
import './prism.css'
import useForceUpdate from 'use-force-update'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'

import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/components/prism-markup-templating.js'
import Prism from 'prismjs'

import './App.css'
import './icons.css'
import './linear.scss'

import {
  Route,
  // Link,
  Redirect,
  Router,
  Switch as RouterSwitch,
} from 'react-router-dom'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
// Import the plugin code
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { loadStripe } from '@stripe/stripe-js'
import '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
// Register the plugins
// registerPlugin(
//   FilePondPluginImageExifOrientation,
//   FilePondPluginImagePreview,
//   FilePondPluginPdfPreview,
// )

// Register the plugin
registerPlugin(FilePondPluginFileValidateType)

import ReactGA from 'react-ga4'
// ReactGA.initialize('AW-11465219128')

const UPLOAD_ENDPOINT = `${import.meta.env.VITE_API_URI}/upload`

//this client init code path should move somewhere explicit instead of the view
import store, { DEFAULT_BASE_MODEL } from '@/Store'
import Sync, { history } from '@/sync'
;(window as any).electron?.ipcRenderer.on('message', (data) => {
  store[data.action](...data.args)
})

const win = window as any

win.toast = toast
win.win = win
win.Y = Y

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const MOBILE_WIDTH = 600
const DESKTOP_VERSION = '5.0.6'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {})
function StripeCheckout() {
  const [options, setOptions] = useState(null)
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    async function getSecret() {
      var clientSecret = (
        await jget(
          `/stripe/create-checkout-session?r=${window.Rewardful?.referral}`,
        )
      ).client_secret
      setOptions({
        // clientReferenceId: store.user.id,
        clientSecret,
        onComplete: () => setIsComplete(true),
      })
    }
    getSecret()
  }, [])

  if (!options) {
    return (
      <Centered full={false}>
        <BarLoader className="opacity-50" />
      </Centered>
    )
  }
  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  )
}

let langs_loaded = {}
const Code: any = React.memo(function Code({ code, language, inline }) {
  useEffect(() => {
    async function highlight() {
      if (language && !langs_loaded[language]) {
        try {
          await import(
            `../node_modules/prismjs/components/prism-${language}.js`
          )
          langs_loaded[language] = true
        } catch (error) {}
      }
      Prism.highlightAll()
    }
    highlight()
  }, [code, language])
  if (inline) {
    return <code className={`language-${language}`}>{code}</code>
  }
  return (
    <pre key="hey" className="lang">
      <code className={`language-${language}`}>{code}</code>
    </pre>
  )
})

function Fancybox(props) {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    const delegate = props.delegate || '[data-fancybox]'
    const options = props.options || {}

    NativeFancybox.bind(container, delegate, options)

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  })

  return <div ref={containerRef}>{props.children}</div>
}

const get_file_url = (id) => import.meta.env.VITE_API_URI + '/file/' + id

class VComponent extends Component<any, any> {}
class PComponent extends PureComponent<any, any> {}

export default class App extends VComponent {
  constructor(props: any) {
    super(props)
    this.state = {
      isMobileView: window.innerWidth <= MOBILE_WIDTH,
    }
  }

  componentDidMount() {
    store.is_mobile = window.innerWidth <= MOBILE_WIDTH
    store.watch(
      (store) => [store.state, Sync.session?.anonymous],
      () => {
        this.forceUpdate()
      },
      'app',
    )
    // window.addEventListener('resize', () => {
    //   this.setState({ isMobileView: window.innerWidth <= MOBILE_WIDTH })
    //   store.is_mobile = window.innerWidth <= MOBILE_WIDTH
    // })
  }

  componentWillUnmount() {
    store.unwatch('app')
  }

  render() {
    if (store._confirm_email)
      return (
        <Centered>
          <div className="text-slate-600">
            ✋ Email Confirmed. Close this window and continue in the previous
            tab.
          </div>
        </Centered>
      )
    if (!store.state?._init) return <Loading />
    return (
      // <StrictMode>
      <api.Provider client={api_client} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <Sentry.ErrorBoundary fallback={null}>
            <Router history={history}>
              <RouterSwitch>
                <Route
                  path="/space/:id"
                  render={(props: any) => {
                    return (
                      <Blog
                        key="blog"
                        team_id={props.match.params.id}
                        {...props}
                      />
                    )
                  }}
                />
                <Route
                  path="/docs"
                  render={(props: any) => {
                    return <Docs />
                  }}
                />
                <Route
                  path="/user/:id"
                  render={(props: any) => {
                    return (
                      <Blog
                        key="blog"
                        user_id={props.match.params.id}
                        {...props}
                      />
                    )
                  }}
                />
                <Route
                  path="/share/:id"
                  render={(props: any) => {
                    return <Share share_id={props.match.params.id} {...props} />
                  }}
                />
                <Route
                  path="/publish/:id"
                  render={(props: any) => (
                    <PublishPage
                      defaults={store.users[props.match.params.id]}
                      pid={props.match.params.id}
                    />
                  )}
                />
                <Route
                  path="/explore/"
                  render={(props: any) => <Explore key="explore" />}
                />
                <Route
                  path="/hello/:id"
                  render={(props: any) => <PersonaView />}
                />
                <Route
                  exact
                  path="/hello"
                  render={() => <Landing force={true} />}
                />
                <Route exact path="/" component={Landing} />
                <Route exact path="/admin" component={Admin} />

                <Route path="/*" component={Main} />
              </RouterSwitch>
            </Router>
            <GlobalHotKeys
              keyMap={Commands.keyed}
              handlers={Commands.handlers}
            />
          </Sentry.ErrorBoundary>
        </QueryClientProvider>
      </api.Provider>
      // </StrictMode>
    )
  }
}

class ObserverComponent extends PComponent {
  fn
  id
  filter
  constructor(props, fn = null, filter = null, id = null) {
    super(props)
    this.fn = fn
    this.filter = filter
    this.id = id
  }

  componentDidMount() {
    if (this.fn)
      this.id = store.watch(
        this.fn,
        (action) => {
          if (this.filter && !this.filter(action)) return
          this.forceUpdate()
        },
        this.id,
      )
  }

  componentWillUnmount() {
    store.unwatch(this.id)
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const form = useForm({ values: props.defaults })
    return <Component {...props} form={form} />
  }
}

class VDialog extends VComponent {
  render() {
    const { sheet, drawer, name, title, children, description } = this.props
    //TODO: add the vaul mobile option in here too

    if (drawer) {
      return (
        <Drawer.Root
          onOpenChange={() => store.toggle(name)}
          open={store.user.show?.[name] ? true : false}
        >
          <Drawer.Portal className="z-50">
            <Drawer.Overlay className="z-50 fixed inset-0 bg-black/40" />
            <Drawer.Content className="z-50 pt-2 bg-white rounded-t-[18px] flex-1 flex flex-col max-h-[90%] fixed bottom-0 left-0 right-0">
              <div className="overflow-y-scroll">
                <div className="relative z-50 mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300 -mb-3 mt-[.4rem]" />
                {title && (
                  <div className="flex justify-center items-center space-x-2 pt-4">
                    <span className="font-semibold text">{title}</span>
                  </div>
                )}
                {description && (
                  <>
                    <div className="text-md pt-4 px-5 text-slate-500">
                      {description}
                    </div>
                    <div className="bg-slate-50 mt-3" />
                  </>
                )}
                <div className="place-items-center">{children}</div>
              </div>
              <Separator />
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      )
    } else if (sheet) {
      return (
        <Sentry.ErrorBoundary fallback={<></>}>
          <Sheet open={store.user.show?.[name]}>
            <SheetContent className="w-full SheetContent" name={name}>
              <SheetHeader>
                <SheetTitle>{title}</SheetTitle>
                <SheetDescription>{description}</SheetDescription>
              </SheetHeader>
              {children}
            </SheetContent>
          </Sheet>
        </Sentry.ErrorBoundary>
      )
    }
    return (
      <Sentry.ErrorBoundary fallback={<></>}>
        <Dialog key={name} open={store.user.show?.[name]}>
          {/* 
                // @ts-ignore */}
          <DialogContent name={name} {...this.props}>
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              <DialogDescription>{description}</DialogDescription>
            </DialogHeader>
            {children}
          </DialogContent>
        </Dialog>
      </Sentry.ErrorBoundary>
    )
  }
}

class FDialog_ extends VComponent {
  constructor(props) {
    super(props)
    props.onOpen && props.onOpen()
    this.state = {}
  }
  // onSubmit(data) {
  //   toast('You submitted the following values:', {
  //     description: (
  //       <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
  //         <code className="text-white">{JSON.stringify(data, null, 2)}</code>
  //       </pre>
  //     ),
  //   })
  // }

  render() {
    var form = this.props.form
    return (
      <VDialog {...this.props}>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              // FIXME: not sure why but this was failing intermmitently
              // re-enable I guess for form validation..
              // form.handleSubmit(this.props.onSubmit)
              e.preventDefault()
              e.stopPropagation()
              this.props.onSubmit({
                ...form.getValues(),
                helpers: form.helpers,
              })
              store.unshowAll()
              store.toggle(this.props.name, false)
            }}
            className="w-full space-y-6 py-4"
          >
            {this.props.children(this.props)}
            <DialogFooter>
              {this.props.onDelete ? (
                <HoldToConfirmButton
                  key="del"
                  variant="destructive"
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.onDelete()
                    store.toggle(this.props.name)
                  }}
                  onCancelledClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  Archive
                </HoldToConfirmButton>
              ) : null}
              {this.props.onPublish ? (
                <Button
                  key="pub"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    store.unshowAll()
                    this.props.onPublish()
                  }}
                >
                  Publish
                </Button>
              ) : null}
              <div className="grow" />
              <Button
                key="save_butn"
                variant="outline"
                onClick={(e) => {
                  e.preventDefault()
                  store.toggle(this.props.name)
                }}
              >
                Cancel
              </Button>
              <Button key="submit" type="submit">
                {this.props.submitLabel || 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </VDialog>
    )
  }
}

const FDialog = withUseFormHook(FDialog_)

//::start components
class Files extends VComponent {
  constructor(props) {
    super(props)
    this.state = {
      files: store
        .files_on(this.props.on)
        .filter((f) => _.isMatch(f, this.props.meta))
        .map((f) => store.file_pond(f)),
    }
  }

  render() {
    return (
      <div className="w-full max-w-2xl">
        {/* Pass FilePond properties as attributes */}
        <FilePond
          files={this.state.files}
          acceptedFileTypes={this.props.acceptedFileTypes}
          allowMultiple={this.props.allowMultiple ?? true}
          labelIdle={
            this.props.labelIdle ??
            'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'
          }
          maxFiles={10}
          server={UPLOAD_ENDPOINT}
          name="file"
          credits={false}
          onremovefile={(error, file) => {
            //@ts-ignore
            store.detachFile(file.file.id ?? file.id, this.props.on)
          }}
          onupdatefiles={(fileItems) => {
            this.setState({
              files: fileItems.map((fileItem) => fileItem.file),
            })
          }}
          onprocessfile={(error, file) => {
            //serverId has oid and sid (openai and server)
            var id = store.addFile({
              on: store.ref(this.props.on),
              ..._.pick(file, [
                'size',
                'source',
                'id',
                'filename',
                'fileType',
                'fileSize',
              ]),
              ...JSON.parse(file.serverId),
              ...(this.props.meta ?? {}),
            })
            var url = `${CDN_URL}/files/` + JSON.parse(file.serverId).sid
            this.props.callback?.({ id, url })
          }}
        />
      </div>
    )
  }
}

export class ModelSettings extends VComponent {
  render() {
    return (
      <div className="flex select-none text-gray-300 justify-center center place-items-center grid text-center mx-4">
        {store.room_models().length < 2 && store.room_model() && (
          <>
            <Tabs
              onValueChange={(value) => {
                store.setModel(value)
              }}
              value={store.room_model()}
              className={cn('pl-0 place-items-center m-0', {
                'w-full': !store.is_mobile,
                'w-[90%]': store.is_mobile,
              })}
            >
              <ScrollArea className="w-full max-w-[1500px] whitespace-nowrap rounded-md border-0">
                <TabsList className="py-8 w-full max-w-[1500px]">
                  {values(store.pinned_models).map((model) => (
                    <TabsTrigger
                      key={model.id}
                      className="tracking-tighter md:tracking-normal overflow:text-hidden md:max-w-none md:p-2 md:px-4 md:w-full"
                      value={model.id}
                    >
                      <div>
                        <div className="truncate">
                          {(model.id == 'doc' ? '🖋️ ' : '') +
                            (model.id == 'web' ? '🔍 ' : '') +
                            model.name}
                        </div>
                        <div className="truncate hidden md:block text-xs text-slate-300 font-normal mt-1">
                          {model.subtitle == 'smarter'
                            ? 'turbo'
                            : (
                                model.subtitle ??
                                model.intro ??
                                model.instructions
                              )?.trunc(90)}
                        </div>
                      </div>
                    </TabsTrigger>
                  ))}
                </TabsList>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </Tabs>
          </>
        )}

        {store.room_models().length > 1 && (
          <div className="flex flex-col">
            <div className="flex space-x-2 items-center justify-center">
              {store.room_models().map((model) => (
                <Ava key={model.id} user={store.users[model]} />
              ))}
            </div>
            <Separator className="my-4" />
            <div>
              These <b>{store.room_models().length} models</b> will respond to
              every message, in order. If prompted properly, they can coordinate
              amongst each other to solve problems.
              <br />
              <br />
              You can adjust this list at any time w/ <kbd>Ctrl</kbd>{' '}
              <kbd>E</kbd>, or use <b>@mentions</b> in the chat to direct the
              next message towards one or more other models.
            </div>
          </div>
        )}
      </div>
    )
  }
}

async function sendInvite(email) {
  store.addInvite({
    team_name: store.team.name,
    team_id: store.team.id,
    from: store.user.email,
    to: email,
  })

  await post(
    '/invite',
    {
      invite: {
        team_name: store.team.name,
        team_id: store.team.id,
        from: store.user.email,
      },
      email,
    },
    'Sending invite',
  )
}

function Centered({ children, full = true }) {
  return (
    <div
      className={cn('w-full flex items-center justify-center', {
        'h-screen': full,
      })}
    >
      <div className="text-center">{children}</div>
    </div>
  )
}

function Loading({ error = false, noreset = false }) {
  return (
    <div className="bg-white">
      <Centered>
        <motion.div
          animate={{
            opacity: [0.5, 1],
            scale: [0.6, 1],
          }}
          transition={{
            ease: 'easeIn',
            duration: 8, // duration in seconds. Adjust as needed
          }}
        >
          <div className="flex flex-col items-center justify-center">
            <img
              src={error ? logoCircledFlat : logoCircled}
              className=" shrink-0 h-[100px]"
            />
            <BarLoader className="my-6" />
            <div className="text-slate-300">Hello {Sync.session?.email}</div>
          </div>
        </motion.div>

        {noreset ? null : (
          <motion.div
            animate={{
              opacity: [0.0, 1],
            }}
            transition={{
              ease: 'easeInOut',
              duration: 4, // duration in seconds. Adjust as needed
              delay: 4,
            }}
          >
            <Button
              onClick={async () => {
                await Sync.resetLocalDB()
              }}
              variant="ghost"
              className="text-slate-200 mt-4"
            >
              Reset
            </Button>
          </motion.div>
        )}
      </Centered>
      <Toaster
        richColors
        closeButton
        className="toaster"
        theme={store.user?.dark_mode ? 'dark' : 'light'}
      />
    </div>
  )
}

class Note extends VComponent {
  render() {
    return (
      <div className="w-full overflow-y-scroll">
        <div className="mx-auto my-12 max-w-[676px] px-6 text-gray-900 md:my-16">
          <article className={cn('message prose prose-slate break-words')}>
            <ReactMarkdown
              children={this.props.note ?? store.note}
              //@ts-ignore
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              components={MarkdownComponents}
            />
          </article>
        </div>
      </div>
    )
  }
}

class Settings extends ObserverComponent {
  file = createRef<any>()
  constructor(props) {
    super(props, (store) => [store.ai_users, store.user.show?.space_settings])
  }
  render() {
    return (
      <VDialog
        name="space_settings"
        key="space_settings"
        sheet
        title="Settings"
        description={`
          Space & personal settings.`}
      >
        <Separator className="my-4" />
        <div className="space-y-2">
          <Label htmlFor="name">Team Name</Label>
          <Input
            value={store.team.name}
            onChange={(e) => store.setTeamName(e.target.value)}
            placeholder="Team Name"
          />
        </div>
        <div className="text-slate-500">
          <div className="mt-4 text-black font-semibold">
            Members{' '}
            <Button variant="ghost" onClick={() => store.toggle('send_invite')}>
              <PlusCircledIcon />
            </Button>
          </div>
          <div className="text-sm text-slate-400">
            Invite your team to collaborate with you in this space.
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {values(store.human_users).map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <div className="flex">
                      <Ava className="shrink-0 mr-2" pill user={user} />
                      {store.user_name(user.id)}
                    </div>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {store.team_invites.length > 0 ? (
            <>
              <div className="mt-4 text-black font-semibold">
                Invites{' '}
                <Button
                  variant="ghost"
                  onClick={() => store.toggle('send_invite')}
                >
                  <PlusCircledIcon />
                </Button>
              </div>
              <div className="text-sm text-slate-400">Pending Invites</div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Sent</TableHead>
                    <TableHead>Email</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {store.team_invites.map((invite) => (
                    <TableRow key={invite.id}>
                      <TableCell>
                        <div className="flex">
                          {ago(invite.create_time)} ago
                        </div>
                      </TableCell>
                      <TableCell>{invite.to}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : null}

          {store.team_files.length > 0 ? (
            <>
              <div className="mt-4 text-black font-semibold">
                Files & Notes{' '}
                <Button
                  variant="ghost"
                  onClick={(e) => {
                    e.preventDefault()
                    store.toggle('_file_drawer', {
                      meta: {},
                      ref: store.ref(store.team),
                    })
                  }}
                >
                  <PlusCircledIcon />
                </Button>
              </div>
              <div className="text-sm text-slate-400">
                Files Uploaded in This Space
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Created</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {store.team_files.slice(0, 5).map((file) => (
                    <TableRow key={file.id}>
                      <TableCell>{getFileName(file.id)}</TableCell>
                      <TableCell>
                        <div className="flex">{ago(file.create_time)} ago</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                variant="ghost"
                onClick={(e) => {
                  store.unshowAll()
                  store.nav('/docs')
                }}
              >
                See All ({store.team_files.length})
              </Button>
            </>
          ) : null}

          <div className="mt-4 text-black font-semibold">
            Personas{' '}
            <Button variant="ghost" onClick={() => store.openNewModel()}>
              <PlusCircledIcon />
            </Button>
          </div>
          <div className="text-sm text-slate-400">
            Create custom AI personas help you and your team get stuff done.
          </div>
          {store.characters.length > 0 ? (
            <Table className="cursor-context-menu">
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Instructions</TableHead>
                  <TableHead>Pinned</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {store.characters.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell
                      onClick={() => {
                        store.editModel(user.id)
                      }}
                    >
                      <div className="flex">
                        <Ava className="shrink-0 mr-2" pill user={user} />
                        {store.user_name(user.id)}
                      </div>
                    </TableCell>
                    <TableCell
                      className="w-full"
                      onClick={() => {
                        store.editModel(user.id)
                      }}
                    >
                      {user.intro ||
                        user.description ||
                        (store.can_write(user) ? user.instructions : '')}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={store.user_pinned(user.id)}
                        onCheckedChange={(e) => {
                          store.toggleUserPinned(user.id)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Button
              className="mt-4"
              variant="outline"
              size="sm"
              onClick={() => store.openNewModel()}
            >
              <PlusCircledIcon className="mr-2" /> Add Persona
            </Button>
          )}

          <div className="text-black mt-4 font-semibold">
            Base Models{' '}
            {/*
                     <Button
                       variant="ghost"
                       onClick={() => store.toggle('add_model')}
                     >
                       <PlusCircledIcon />
                     </Button>*/}
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Pinned</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {store.models.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <div className="flex">
                      <Ava className="shrink-0 mr-2" pill user={user} />
                      {store.user_name(user.id)}
                    </div>
                  </TableCell>
                  <TableCell>{user.description || user.instructions}</TableCell>
                  <TableCell>
                    <Switch
                      checked={store.user_pinned(user.id)}
                      onCheckedChange={() => store.toggleUserPinned(user.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Separator className="my-8" />
        <div>
          <div className="space-y-4 py-2 pb-4">
            <div className="space-y-2">
              <Label htmlFor="name">Your Name</Label>
              <Input
                id="ws_name"
                value={store.user_name()}
                onChange={(e) => store.setUserName(e.target.value)}
                placeholder="Your Name"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="name">
                What do you want the AI to know about you?
              </Label>
              <Textarea
                minRows={4}
                id="about"
                value={store.user.about}
                onChange={(e) => store.setUserAbout(e.target.value)}
                placeholder="Where do you live? What is your job? What are your Goals?"
              />
              <div className="p-2" />
              <Label htmlFor="name">
                How would you like the AI to respond?
              </Label>
              <div className="text-sm text-gray-400">
                This is a space-wide setting, it will apply for everyone in all
                future rooms in this space.
              </div>
              <Textarea
                className="my-8"
                minRows={4}
                id="respond"
                value={store.team.respond}
                onChange={(e) => store.setTeamRespond(e.target.value)}
                placeholder="What tone do you prefer responses? Do you prefer consise or long answers?"
              />
            </div>
            <div className="flex space-x-3 justify-end items-center">
              <DarkModeToggle />
              <Label>{store.user?.dark_mode ? 'Dark' : 'Light'}</Label>
            </div>
            <div className="flex space-x-2 justify-end items-center">
              <Switch
                onCheckedChange={() => store.toggleProp('dense_mode')}
                checked={!store.user.dense_mode}
              />
              <Label>{store.user.dense_mode ? 'Dense' : 'Roomy'}</Label>
            </div>
          </div>
        </div>
        <Separator className="mb-4" />

        <div className="flex">
          <HoldToConfirmButton
            onClick={() => {
              store.removeFromTeam()
            }}
            className="shrink-0"
            variant="destructive"
          >
            <EnterIcon className="mr-2 h-5 w-5" />
            Leave Space
          </HoldToConfirmButton>
          <Dialog key="import_chat">
            <DialogTrigger className="w-full">
              <div className="flex w-full justify-end items-end">
                <Button variant="outline">
                  <EnterIcon className="mr-2 h-5 w-5" />
                  Import OpenAI Chat History
                </Button>
              </div>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Import ChatGPT Conversations</DialogTitle>
                <DialogDescription>
                  In ChatGPT go to Settings {'>'} Data Controls {'>'} Export
                  Data. Then upload conversations.json here.
                </DialogDescription>
              </DialogHeader>
              <label
                className="block text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="file_input"
              >
                Upload conversations.json
              </label>
              <Input
                ref={this.file}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
              />

              <DialogClose>
                <Button
                  onClick={async () => {
                    await handleFileUpload(this.file.current.files[0])
                  }}
                  id="readButton"
                  variant="outline"
                >
                  Import
                </Button>
              </DialogClose>
            </DialogContent>
          </Dialog>
        </div>
      </VDialog>
    )
  }
}

store.trigger(async (action, meta) => {
  if (meta.external) return
  if (['addFile', 'detachFile'].includes(action.name)) {
    const fid = action.result
    var file = store.files[fid]
    if (file.on?.type_ == 'avatar') {
      if (store.file_url(file) !== store.users[file.on.id_].avatar_url)
        store.upsertUser({ id: file.on.id_, avatar_url: store.file_url(file) })
    }
    if (!file.on && meta.prev.files[fid]?.on?.id_) {
      store.upsertUser({ id: meta.prev.files[fid].on.id_, avatar_url: null })
    }
  }
})

class PersonaForm extends VComponent {
  render() {
    var form = this.props.form
    var persona = store.users[this.props.pid ?? store._edit_model]
    var current = this.props.current ?? persona
    form.helpers = form.helpers ?? current.helpers
    if (!persona) {
      return
    }
    return [
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Name & Photo</FormLabel>
            <FormControl>
              <div className="flex space-x-4 items-start pt-2">
                <div className="flex flex-row justify-left items-center space-x-2 pb-2">
                  <ConfirmButton
                    icon={
                      <img
                        onClick={() =>
                          store.toggle('_file_drawer', {
                            ref: {
                              id_: persona.id,
                              type_: 'avatar',
                            },
                            allowMultiple: false,
                            acceptedFileTypes: ['image/*'],
                            labelIdle:
                              "📷 Drop or <span class='filepond--label-action'> Choose</span> an Avatar",
                          })
                        }
                        src={persona?.avatar_url ?? logoCircled}
                        className="cursor-pointer rounded-full mb-4 ring-0 ring-gray-300 dark:ring-gray-500 shadow-md select-none shrink w-[40px]"
                      />
                    }
                    confirmIcon="same"
                  />
                </div>
                <Input
                  className="w-full"
                  placeholder={`Name your AI Persona`}
                  {...field}
                />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />,
      <FormField
        control={form.control}
        name="model"
        render={({ field }) => {
          var is_assistant =
            current?.assistant || store.user.show.creating_assistant
          var models = values(store.ai_users)
            .filter((m) => m.base)
            .filter(
              (m) =>
                !is_assistant ||
                (m.id.includes('openai/') && !m.id.includes('-vision')),
            )
          var selected = field.value || DEFAULT_BASE_MODEL
          models.map((m) => m.id).includes(selected) ||
            (selected = DEFAULT_BASE_MODEL)
          return (
            <FormItem>
              <FormLabel>Base Model</FormLabel>
              {is_assistant ? (
                <FormDescription>
                  Only OpenAI models can be used as Assistant base models.
                </FormDescription>
              ) : null}
              <FormControl>
                <Select
                  name="model"
                  onValueChange={field.onChange}
                  value={selected}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose Model" />
                  </SelectTrigger>
                  <SelectContent className="max-w-sm sm:max-w-xl">
                    <SelectGroup className="overflow-y-auto max-h-[30rem]">
                      {models.map((model) => (
                        <SelectItem value={model.id}>
                          <div className="flex flex-row">
                            <div className="flex flex-col">
                              <span className="text-left font-medium">
                                {model.name}
                              </span>
                              <span className="text-xs text-gray-400">
                                {model.description}
                              </span>
                            </div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />,
      <FormField
        control={form.control}
        name="instructions"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Instructions</FormLabel>
            <FormControl>
              <Textarea
                placeholder={`I want you to act as a...`}
                minRows={4}
                {...field}
              />
            </FormControl>
            <FormDescription>
              Describe the AI identity in as much detail as you like. You can
              define and adjust character, ability, and behavior. Find some{' '}
              <a
                target="_blank"
                className="cool-link"
                href="https://prompts.chat"
              >
                inspiration and examples here
              </a>
              .
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />,
      <hr />,
      current.provisional ? (
        <FormField
          control={form.control}
          name="assistant"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  className="mr-2"
                  checked={field.value}
                  onCheckedChange={(e) => {
                    store.toggle('creating_assistant', e)
                    field.onChange(e)
                  }}
                />
              </FormControl>
              <FormLabel>Assistant</FormLabel>
              <FormDescription>
                Assistants can be trained with data and can use tools to
                accomplish tasks.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      ) : null,
    ].concat(
      current?.assistant || store.user.show.creating_assistant
        ? [
            <FormField
              control={form.control}
              name="code_interpreter"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Coding</FormLabel>
                  <FormDescription>
                    When enabled your assistant will be able to run code,
                    analyze data, work with data files you upload, and more.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />,
            <FormField
              control={form.control}
              name="tools.web_search"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Web Search</FormLabel>
                  <FormDescription>
                    When enabled your assistant can use our web model to
                    research answers on the web when appropriate.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />,
            <FormField
              control={form.control}
              name="tools.gen_image"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Image Generation</FormLabel>
                  <FormDescription>
                    When enabled your assistant can generate images for you.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />,
            <Separator />,
            <FormField
              control={form.control}
              name="other_assistants"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={(e) => {
                        store.toggle('other_assistants', e)
                        if (current) current.other_assistants = e
                        field.onChange(e)
                      }}
                    />
                  </FormControl>
                  <FormLabel>Assistant Team</FormLabel>
                  <FormDescription>
                    When enabled your assistant can ask other assistants for
                    help.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />,
            current?.other_assistants || store.user?.show?.other_assistants ? (
              <FormField
                control={form.control}
                name="team"
                render={({ field }) => (
                  <FormItem>
                    <FormControl className={'w-full'}>
                      <UserEdit
                        selected={form.helpers ?? current?.helpers}
                        setSelected={(selected) => {
                          form.helpers = selected
                          store.toggle('_dummy')
                        }}
                        ai
                        classes={'w-full max-h-[300px]'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null,
            <Separator />,
            <FormField
              control={form.control}
              name="retrieval"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Knowledge</FormLabel>
                  <FormDescription>
                    When enabled your assistant will find and use knowledge in
                    files you upload here, and with each message, to respond to
                    you.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />,
            <div>
              <FormLabel>Files</FormLabel>
              <Files on={persona} />
            </div>,
          ]
        : [],
    )
  }
}

class Dialogs extends ObserverComponent {
  constructor(props) {
    super(props, (store) => [store.user?.use_full_context])
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={<></>}>
        <>
          {!store.is_mobile ? (
            <div
              className={cn(
                'fixed top-[20px] inset-0 flex w-screen items-center justify-center p-4',
                { invisible: !store.user?.show?.bar },
              )}
            >
              <div
                onClick={() => store.unshowAll()}
                className="overflow-y-scroll max-h-screen fixed inset-0 z-50 bg-background/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="linear_inline fixed rounded-[20px] top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] w-full max-w-2xl rounded bg-white"
                >
                  <CMDK key="cmdk" />
                </div>
              </div>
            </div>
          ) : (
            <VDialog name="bar" key="cmdk_bar" drawer>
              <div className="linear_mobile">
                <CMDK nofocus key="cmdk" />
              </div>
            </VDialog>
          )}
          <Drawer.Root
            key="_file_drawer"
            onOpenChange={() => store.toggle('_file_drawer')}
            open={store.user?.show?._file_drawer ? true : false}
          >
            <Drawer.Portal>
              <Drawer.Overlay className="fixed inset-0 bg-black/40" />
              <Drawer.Content className="z-50 pt-2 bg-slate-50 rounded-t-[10px] flex-1 flex flex-col mt-10 max-h-[80%] fixed bottom-0 left-0 right-0">
                <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300 mb-2" />
                <div className="flex w-full items-center justify-center">
                  <Files
                    meta={
                      store.user?.show?._file_drawer?.meta ?? { next: true }
                    }
                    {..._.omit(store.user.show?._file_drawer, 'ref')}
                    on={
                      store.user.show?._file_drawer?.ref
                        ? store.user.show?._file_drawer.ref
                        : store.room
                    }
                    acceptedFileTypes={
                      (store.user.show?._file_drawer?.acceptedFileTypes ??
                      (!store.user.show?._file_drawer?.ref &&
                        is_vision_model(store.room_model())))
                        ? ['image/*']
                        : null
                    }
                  />
                </div>
              </Drawer.Content>
            </Drawer.Portal>
          </Drawer.Root>
          <Drawer.Root
            key="_room_drawer"
            onOpenChange={() => store.toggle('_room_drawer')}
            open={store.user?.show?._room_drawer ? true : false}
          >
            <Drawer.Portal className="z-50">
              <Drawer.Overlay className="z-50 fixed inset-0 bg-black/40" />
              <Drawer.Content className="z-50 pt-2 bg-slate-50 rounded-t-[10px] flex-1 flex flex-col mt-10 max-h-[80%] fixed bottom-0 left-0 right-0">
                <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300 mb-2" />
                <Rooms />
              </Drawer.Content>
            </Drawer.Portal>
          </Drawer.Root>
          <VDialog name="persona_card" className="max-w-sm">
            <PersonaCard
              noborder
              onClick={() => store.toggle('persona_card', false)}
              key={store.persona_card_user}
              persona={store.persona_card_user}
            />
          </VDialog>
          <VDialog name="flex_usage" drawer>
            <div className="flex flex-col items-center justify-center">
              <img
                src={'/flex.png'}
                className="justify-center h-20 mt-6 mb-2"
              />
              <div className="text-slate-400">
                Only messages beyond your standard limit that use the flex plan
                are listed here. Billed monthly with your subscription.
              </div>
            </div>
            <Separator className="my-4" />
            {store.flex_messages.length == 0 ? (
              <div className="flex flex-col items-center justify-center my-5">
                <div className="text-slate-300">No Flex Messages</div>
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Model</TableHead>
                    <TableHead>Tokens</TableHead>
                    <TableHead>Cost</TableHead>
                    <TableHead>Date</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {store.flex_messages.map((msg) => (
                    <TableRow className="text-slate-500" key={msg.id}>
                      <TableCell>
                        <div className="flex">
                          <Ava
                            className="shrink-0 mr-2"
                            pill
                            user={store.users[msg.model]}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {msg.input_token_count} → {msg.output_token_count}
                      </TableCell>
                      <TableCell>
                        {msg.input_cost + msg.output_cost < 0.01 ? '<' : ''}
                        {(msg.input_cost + msg.output_cost).toLocaleString(
                          'en-US',
                          { style: 'currency', currency: 'USD' },
                        )}
                      </TableCell>
                      <TableCell>{ago(msg.create_time)} ago</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </VDialog>
          <VDialog name="pricing_drawer" drawer>
            <PricingCheckout />
          </VDialog>
          <VDialog drawer name="pricing_table">
            <PricingTable />
          </VDialog>
          <VDialog
            title={store.user.show?.confirm?.title ?? 'Are you sure?'}
            description={
              store.user.show?.confirm?.description ??
              "This is a permanent action that can't be undone."
            }
            name="confirm"
          >
            <DialogFooter>
              {store._confirm_callback && (
                <>
                  <Button
                    onClick={() => {
                      store._confirm_callback()
                      store.toggle('confirm')
                    }}
                    variant="destructive"
                    className="px-10"
                    type="submit"
                  >
                    Yes
                  </Button>
                  <div className="grow" />
                  <Button
                    onClick={() => {
                      store.toggle('confirm')
                    }}
                    variant="outline"
                    className="px-10"
                    type="submit"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </DialogFooter>
          </VDialog>
          <Dialog open={store.user?.show?.help}>
            {/* 
                // @ts-ignore */}
            <DialogContent name="help">
              <div className="flex flex-col items-center justify-center">
                <img src={logoCircled} className="justify-center h-20" />
              </div>
              <DialogHeader>
                <DialogTitle>Hello, Vello</DialogTitle>
                <DialogDescription>An opinionated AI suite.</DialogDescription>
              </DialogHeader>
              <Separator />
              <div>Vello is:</div>
              <div className="items-center space-x-2 flex">
                <LightningBoltIcon className="shrink-0 text-slate-400" />
                <div>A fast, powerful AI chat client.</div>
              </div>
              <div className="items-center space-x-2 flex">
                <PersonIcon className="shrink-0 text-slate-400" />
                <div>
                  A collaborative workspace for teams, personas, and documents.
                </div>
              </div>
              <div className="items-center space-x-2 flex">
                <HeartFilledIcon className="shrink-0 text-slate-400" />
                <div>
                  Loved by users and teams around the word. <br />
                  <span className="font-semibold">500 million</span> tokens
                  served and counting.
                </div>
              </div>
              <Separator />
              <div className="items-center space-x-2 flex">
                <LockClosedIcon className="shrink-0 text-slate-400" />
                <div>
                  Your data is private, stored securely in a binary format used
                  for syncing.
                </div>
              </div>
              <div className="items-center space-x-2 flex">
                <AiOutlineApple className="shrink-0 text-slate-400" />
                <div>
                  Available as a{' '}
                  {_global.os == 'ios' ? (
                    <a
                      className="font-medium cursor-pointer cool-link"
                      onClick={() => store.toggle('_show_home_screen')}
                    >
                      Mobile app.
                    </a>
                  ) : (
                    <a
                      className="font-medium cursor-pointer cool-link"
                      onClick={() => store.toggle('download')}
                    >
                      Desktop app.
                    </a>
                  )}
                </div>
              </div>
              <div className="items-center space-x-2 flex">
                <QuestionMarkCircledIcon className="shrink-0 text-slate-400" />
                <div>
                  Questions or Feedback? See our{' '}
                  <a
                    className="font-medium cool-link"
                    target="_blank"
                    href="https://docs.vello.ai"
                  >
                    docs
                  </a>
                  ,{' '}
                  <a
                    className="font-medium cool-link"
                    target="_blank"
                    href="https://hello.vello.ai"
                  >
                    home page
                  </a>
                  ,{' '}
                  <a
                    className="font-medium cool-link"
                    target="_blank"
                    href="https://twitter.com/velloai"
                  >
                    tweet
                  </a>
                  , or&nbsp;
                  <a
                    className="font-medium cool-link"
                    href="mailto:hello@vello.ai"
                  >
                    email us
                  </a>
                  .
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog open={store.user.show?.download}>
            {/* 
                // @ts-ignore */}
            <DialogContent name="download">
              <DialogHeader>
                <DialogTitle>
                  <div className="flex space-x-1 place-items-center">
                    <AiFillApple /> <AiFillWindows />
                    <div>Get Vello for Desktop</div>
                  </div>
                </DialogTitle>
                <DialogDescription>
                  Get Vello at your fingertips with our Desktop client.
                </DialogDescription>
              </DialogHeader>
              <Separator />
              {_global.os == 'mac' ? (
                <span className="justify-center flex space-x-6">
                  <Button
                    variant="outline"
                    className="grow"
                    onClick={() => {
                      downloadURI(
                        CDN_URL + `/Vello-arm64.dmg?v=${DESKTOP_VERSION}`,
                        'Vello.dmg',
                      )
                      store.toggle('download')
                    }}
                  >
                    <DownloadIcon className="m-2" />
                    For M1 Mac
                  </Button>
                  <Button
                    className="grow"
                    onClick={() => {
                      downloadURI(
                        CDN_URL + `/Vello.dmg?v=${DESKTOP_VERSION}`,
                        'Vello.dmg',
                      )
                      store.toggle('download')
                    }}
                    variant="outline"
                  >
                    <DownloadIcon className="m-2" />
                    For Intel Mac
                  </Button>
                </span>
              ) : (
                <Button
                  onClick={() => {
                    downloadURI(
                      CDN_URL + `/Vello Setup-2.exe?v=${DESKTOP_VERSION}`,
                      'Vello Setup.exe',
                    )
                    store.toggle('download')
                  }}
                  variant="outline"
                >
                  <DownloadIcon className="m-2" />
                  Get Windows App
                </Button>
              )}
            </DialogContent>
          </Dialog>

          <Drawer.Root
            onOpenChange={() => store.toggle('_show_home_screen')}
            open={store.user?.show?._show_home_screen ? true : false}
          >
            <Drawer.Portal className="z-50">
              <Drawer.Overlay className="z-50 fixed inset-0 bg-black/40" />
              <Drawer.Content className="z-50 pt-2 bg-slate-50 rounded-t-[10px] flex-1 flex flex-col mt-10 max-h-[100%] fixed bottom-0 left-0 right-0">
                <div className="overflow-scroll">
                  <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300 mb-2" />
                  <div className="flex justify-center items-center space-x-2 pb-2">
                    <AiFillApple />
                    <span className="font-semibold text">
                      Add Vello iPhone App
                    </span>
                  </div>
                  <div className="text-md px-5 text-slate-500">
                    Add Vello to your home screen in <b>just two taps</b> - no
                    install required.
                  </div>
                  <div className="bg-slate-50 mt-3" />
                  <div className="place-items-center">
                    <div className="p-2 font-semibold text px-5">Step 1</div>
                    <div className="text-md px-5 text-slate-500">
                      Tap the share icon below.
                    </div>
                    <img className="shadow" src="/inst_one.png" />
                    <Separator />
                    <div className="p-2 font-semibold text px-5">Step 2</div>
                    <div className="text-md px-5 text-slate-500">
                      Tap Add to Home Screen.
                    </div>
                    <img className="shadow" src="/inst_two.png" />
                  </div>
                </div>
                <Separator />
              </Drawer.Content>
            </Drawer.Portal>
          </Drawer.Root>

          <VDialog
            name="issue"
            title="There was an issue"
            description="⚠️  Uh oh, Vello had an issue. Please try again later, reloading may help."
          />
          <VDialog
            name="space_signup"
            title="Hello, Vello"
            description="✋ Sign up for a free Vello account to continue your conversation."
          >
            <AuthView />
          </VDialog>
          <VDialog
            name="space_login"
            title="Sign In"
            description="Please login to continue."
          >
            <AuthView view="sign_in" />
          </VDialog>
          <VDialog
            name="user_settings"
            title="User Settings"
            description="Adjust your Vello user settings."
          >
            <div>Share settings</div>
          </VDialog>
          <Settings />
          <Invites />
          <Toaster
            richColors
            closeButton
            className="toaster"
            theme={store.user?.dark_mode ? 'dark' : 'light'}
          />
          {/*<CommandMenu />*/}
          <VDialog
            title={`Invite to \`${store.team.name || 'Your Space'}\``}
            description="Invite your team to collaborate with you here."
            name="send_invite"
          >
            <Input
              placeholder="Email"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendInvite(get_elem_value('invite_email'))
                  store.toggle('send_invite', false)
                }
              }}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="invite_email"
            />
            <DialogClose>
              <Button
                onClick={() => {
                  sendInvite(get_elem_value('invite_email'))
                  store.toggle('send_invite', false)
                }}
                id="inviteButton"
                variant="outline"
              >
                Send Invite
              </Button>
            </DialogClose>
          </VDialog>
          <FDialog
            defaults={store.users[store._edit_model]}
            key="add_new"
            onSubmit={(data) => {
              store.upsertAIUser(data)
            }}
            title={`Add New Persona to \`${store.team.name || 'Your Space'}\``}
            description="Create a new AI Persona in this space."
            name="add_model"
          >
            {({ form }) => <PersonaForm form={form} />}
          </FDialog>
          <FDialog
            key="update_model"
            defaults={store.users[store._edit_model]}
            onSubmit={(data) => store.upsertAIUser(data)}
            title={`Update character \`${
              store.users[store._edit_model]?.name || 'Your Space'
            }\``}
            description="Update your persona."
            name="update_model"
            onDelete={() => store.archiveAIUser(store.users[store._edit_model])}
            onPublish={() => {
              store.nav(`/publish/${store._edit_model}`)
              // window.open(`/hello/${store._edit_model}`, '_blank')
            }}
          >
            {({ form }) => (
              <PersonaForm
                current={store.users[store._edit_model]}
                form={form}
              />
            )}
          </FDialog>
          {store.is_vello_admin && (
            <>
              <VDialog title="Debug" name="debug_history">
                <div className="w-full h-full p-4">
                  <div className="text-slate-300">
                    <ReactJson
                      quotesOnKeys={false}
                      theme="solarized"
                      enableClipboard={false}
                      name="store.user"
                      collapsed={true}
                      src={store.history}
                    />
                  </div>
                </div>
              </VDialog>
              <VDialog title="Debug" name="debug_user">
                <div className="w-full h-full p-4">
                  <div className="text-slate-300">
                    <ReactJson
                      quotesOnKeys={false}
                      theme="solarized"
                      enableClipboard={false}
                      name="store.user"
                      collapsed={true}
                      src={store.user}
                    />
                  </div>
                </div>
              </VDialog>
              <VDialog title="Debug" name="debug_store">
                <div className="w-full h-full p-4">
                  <div className="text-slate-300">
                    <ReactJson
                      quotesOnKeys={false}
                      enableClipboard={false}
                      name="store.state"
                      collapsed={true}
                      src={store.state}
                    />
                  </div>
                </div>
              </VDialog>
            </>
          )}
        </>
      </Sentry.ErrorBoundary>
    )
  }
}

export class Page extends VComponent {
  file = createRef<any>()
  scroller = createRef<any>()
  editor = createRef<any>()
  form = createRef<any>()
  input = createRef<any>()
  panelGroup = createRef<any>()

  constructor(props: any) {
    super(props)
  }

  renderLoading() {
    if (!store.user) {
      return <Loading />
    }
  }

  renderDialogs() {
    return (
      <Sentry.ErrorBoundary fallback={<></>}>
        <Dialogs
          show={store.user?.show}
          dark_mode={store.user?.dark_mode}
          sel_cmd={store.state._sel_cmd}
        />
      </Sentry.ErrorBoundary>
    )
  }

  render() {
    var loading = this.renderLoading()
    if (loading) return loading

    if (store.user?.show?.bar && store.user?.show?.desktop_bar) {
      return (
        <div className="bg-opacity-0">
          <CMDK key="cmdk" />
        </div>
      )
    }

    return (
      <div
        className={cn('h-full md:mx-auto md:shadow-2xl md:overflow-hidden', {
          'overflow-hidden': !!Sync.session?.share_id,
          'h-full': !!Sync.session?.share_id,
        })}
      >
        {this.renderHeader()}

        <Separator />
        <div
          className={cn('flex flex-row bg-neutral-100', {
            'h-full': true,
            'pt-10':
              store.user.location?.includes('publish') ||
              (!Sync.session.share_id &&
                !store.current_persona &&
                !store.user.location?.includes('explore')),
            // 'h-[calc(100vh-2.1rem)]': _global.desktop,
          })}
        >
          {this.renderContent()}
          {this.renderDialogs()}
          {this.renderFooter()}
        </div>
      </div>
    )
  }

  renderHeader() {
    return null
  }

  renderContent() {
    return null
  }

  renderFooter() {
    return null
  }
}

export class QPage extends Page {
  query
  options
  wid

  constructor(props, q = null, options = {}) {
    super(props)
    this.query = q
    this.options = options
    if (this.query) {
      this.wid = store.watch(
        (s) => s.state._init,
        async () => {
          if (!store.state._init) return
          store.loading(true)
          store.merge(await query(this.query))
          store.loading(false)
        },
        'qpage',
      )
    }
  }

  async componentWillUnmount() {
    // store.unwatch(this.wid);
  }
}

export class Share extends Page {
  renderLoading() {
    if (!store.user) {
      return <Loading noreset />
    }
  }

  renderFooter() {
    return (
      <div className="flex space-x-3 items-center p-1 pr-2 fixed bottom-0 right-2">
        <Tip content="Continue on Vello">
          <ConfirmButton
            onClick={(e) => {
              window.location.href = `https://vello.ai/app?s=${Sync.session.share_id}`
              e.preventDefault()
            }}
            icon={
              <img
                src={logoCircledFlat}
                className="w-7 hand_logo opacity-80 shrink-0"
              />
            }
          />
        </Tip>
        <Tip align={'end'} content="Dark Mode">
          <DarkModeToggle />
        </Tip>
      </div>
    )
  }

  //SHARE PAGE
  renderContent() {
    if (values(store.messages).length && store.current_file) {
      return (
        <PanelGroup
          ref={this.panelGroup}
          direction="horizontal"
          onLayout={(sizes) => {
            store.setLayout(sizes)
          }}
        >
          <Panel
            ref={this.middle}
            className="md:shadow-md shadow-slate-400/50 border-x-1"
            collapsible={true}
            //@ts-ignore
            minSize={10}
            defaultSize={50}
          >
            <Chat />
          </Panel>
          <PanelResizeHandle className="ResizeHandle" />
          <Panel
            ref={this.right}
            style={{ overflow: 'auto' }}
            collapsible={true}
            //@ts-ignore
            defaultSize={50}
            className="bg-white shadow-inner"
          >
            <Note note={store.note} />
          </Panel>
        </PanelGroup>
      )
    }
    if (store.note) {
      return <Note note={store.note} />
    } else {
      return <Chat vaul-drawer-wrapper="" />
    }
  }
}

export class Main extends VComponent {
  render() {
    // return <BasePanelPage />
    if (!store.state._init)
      return (
        <Centered>
          <div className="bg-white rounded-full p-8">
            <BarLoader />
          </div>
        </Centered>
      )
    return store.user?.current_persona_id ? <PersonaView /> : <BasePanelPage />
  }
}

export class BasePanelPage extends Page {
  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    store.watch(
      (store) => [store.user?.layout, this.panelGroup],
      () => {
        if (store.user?.layout) {
          this.panelGroup.current?.setLayout(store.user.layout)
        }
      },
      'layout_change',
    )
  }

  componentWillUnmount(): void {
    store.unwatch('layout_change')
  }

  renderDialogs() {
    return (
      <>
        {super.renderDialogs()}
        <Drawer.Root
          onOpenChange={() => store.toggle('_show_settings')}
          key="model_settings"
          open={store.user?.show?._show_settings ? true : false}
        >
          <Drawer.Portal className="z-50">
            <Drawer.Overlay className="z-50 fixed inset-0 bg-black/40" />
            <Drawer.Content className="z-50 pt-2 bg-white rounded-t-[10px] flex-1 flex flex-col mt-10 min-h-[300px] max-h-[80%] fixed bottom-0 left-0 right-0">
              <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300" />
              {this.renderAuthControl(true)}
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </>
    )
  }
  renderLeft() {
    return (
      <div className="flex flex-col h-full">
        <div className="bg-slate-50 bg-opacity-90 overflow-y-auto overflow-x-hidden flex grow flex-col p-0">
          <Rooms />
          <Separator />
          {this.renderAuthControl()}
        </div>
      </div>
    )
  }

  renderAuthControl(force_open = undefined) {
    return (
      <AuthControl
        force_open={force_open}
        show={store.user?.show}
        team={store.team}
        anon={Sync.session?.anonymous}
        pass_recovery={store._password_recovery}
      />
    )
  }

  renderRight() {
    return (
      <FileViewer
        setEditor={(e) => {
          this.editor = e
          win.editor = e
        }}
      />
    )
  }

  renderMain() {
    return <Chat />
  }

  renderContent() {
    if (store.is_mobile) {
      return store.user.show?.notes_open
        ? this.renderRight()
        : this.renderMain()
    }

    return (
      <PanelGroup
        ref={this.panelGroup}
        direction="horizontal"
        onLayout={(sizes) => {
          store.setLayout(sizes)
        }}
      >
        <Panel
          collapsible={true}
          //@ts-ignore
          minSize={10}
          defaultSize={0}
          style={{ minWidth: 0 }}
        >
          {this.renderLeft()}
        </Panel>
        <PanelResizeHandle className="ResizeHandle" />
        <Panel
          className="md:shadow-md shadow-slate-400/50 border-x-1"
          collapsible={true}
          //@ts-ignore
          minSize={10}
          defaultSize={100}
        >
          {this.renderMain()}
        </Panel>
        <PanelResizeHandle className="ResizeHandle" />
        <Panel
          style={{ overflow: 'auto' }}
          collapsible={true}
          //@ts-ignore
          defaultSize={0}
          className="bg-gray-50 shadow-xl"
        >
          {this.renderRight()}
        </Panel>
      </PanelGroup>
    )
  }

  renderHeader() {
    return (
      <RoomHeader
        notifs={store.user_notifs(true)}
        right={store.user.layout?.[2] === 0}
        left={store.user.layout?.[0] === 0}
        show={store.user?.show}
        room={store.room}
      />
    )
  }

  renderFooter() {
    return null
  }
}

export class PublishPage_ extends BasePanelPage {
  renderMain() {
    var persona = store.users[this.props.pid]
    var form = this.props.form
    return (
      <div className="bg-white h-full p-4 overflow-y-auto">
        <div className="text-xl font-semibold">Preview & Publish Persona</div>
        <div className="text-sm text-slate-300 my-2">
          Adjust and preview how your persona will appear to other users. You
          can chat with your persona to test it out.
        </div>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              // FIXME: not sure why but this was failing intermmitently
              // re-enable I guess for form validation..
              // form.handleSubmit(this.props.onSubmit)
              e.preventDefault()
              e.stopPropagation()
              //copy/pasta!!
              store.upsertAIUser({ ...form.getValues(), helpers: form.helpers })
              // store.unshowAll();
              // store.toggle(this.props.name, false)
            }}
            className="w-full space-y-6 py-4"
          >
            <FormField
              control={form.control}
              name="intro"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Introduction</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={`Hi, I'm ${
                        form.getValues().name
                      }, I'm here to help you with...`}
                      minRows={1}
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Write a short introduction for your persona. This will be
                    shown to users when they first interact with your persona.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <PersonaForm {...this.props} />
            <FormField
              control={form.control}
              name="public"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Public</FormLabel>
                  <FormDescription>
                    Share your persona with other users via the public persona
                    directory.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex space-x-3 w-full">
              <HoldToConfirmButton
                key="del"
                variant="destructive"
                onClick={(e) => {
                  e.preventDefault()
                  store.archiveAIUser(persona)
                }}
                onCancelledClick={(e) => {
                  e.preventDefault()
                }}
              >
                Archive
              </HoldToConfirmButton>
              <div className="grow" />
              <Button key="submit" type="submit">
                {this.props.submitLabel || 'Save'}
              </Button>
              <Button
                key="clear"
                variant="outline"
                onClick={(e) => {
                  e.preventDefault()
                  store.clearRoom()
                }}
              >
                <EraserIcon className="mr-2" /> Clear Chat
              </Button>
              <Button
                key="preview"
                variant="outline"
                onClick={(e) => {
                  e.preventDefault()
                  store.nav(`/hello/${persona.id}`)
                }}
              >
                <ExternalLinkIcon className="mr-2" />
                Preview
              </Button>
              <Button
                key="share_persona"
                variant="outline"
                onClick={async (e) => {
                  e.preventDefault()
                  var url = 'https://vello.ai/hello/' + persona.id
                  await copy(url)
                  store.setToast({
                    title: 'Link Copied to Clipboard',
                    url,
                    content:
                      'Anyone with this link can interact with your persona.',
                  })
                }}
              >
                <Share2Icon className="mr-2" />
                Share
              </Button>
            </div>
          </form>
        </Form>
      </div>
    )
  }

  renderRight() {
    return <Persona />
  }
}

const PublishPage = withUseFormHook(PublishPage_)

export class AuthControl extends PComponent {
  render() {
    return (
      <span key="auth_view_parent" className="bg-slate-50 bg-opacity-90">
        {store._password_recovery && (
          <Dialog key="auth_view" open={store._password_recovery}>
            <DialogContent>
              <AuthView
                //@ts-ignore
                showLinks={false}
                view="update_password"
              />
            </DialogContent>
          </Dialog>
        )}
        {Sync.session?.anonymous ? (
          <span>
            <Separator />
            <Dialog open={store.user.show?.download} key="signup">
              <DialogTrigger className="w-full">
                <Button variant="outline" className="m-2 w-[90%]">
                  Sign Up
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Welcome</DialogTitle>
                  <DialogDescription>
                    Sign Up or Sign In below.
                  </DialogDescription>
                </DialogHeader>
                <Login />
              </DialogContent>
            </Dialog>
            <Dialog>
              <div className="text-center justify-center whitespace-nowrap flex text-sm text-slate-400 mb-2">
                or{' '}
                <DialogTrigger className="shrink-0">
                  <a className="shrink-0 p-1 font-medium text-slate-500">
                    {' '}
                    Sign In{' '}
                  </a>
                </DialogTrigger>{' '}
                to save your conversations privately
              </div>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Welcome</DialogTitle>
                  <DialogDescription>
                    Sign Up or Sign In below.
                  </DialogDescription>
                </DialogHeader>
                <Login view="sign_in" />
              </DialogContent>
            </Dialog>
          </span>
        ) : (
          <div>
            <TeamSwitcher force_open={this.props.force_open} />
          </div>
        )}
      </span>
    )
  }
}

export class SideBarPage extends QPage {
  state: any = {
    expanded: false,
  }

  renderMain() {
    return null
  }
  renderContent() {
    return (
      <div className="flex w-full">
        <motion.div
          className={cn('overflow-y-auto overflow-x-hidden', {
            'w-[60px]':
              store.user?.show.sidebar_shown && !store.user?.show.sidebar,
            'w-[280px]':
              store.user?.show.sidebar_shown && store.user?.show.sidebar,
            'p-0 w-[0px]': !store.user?.show.sidebar_shown,
            'p-2': store.user?.show.sidebar_shown,
          })}
        >
          <div
            onClick={() =>
              store.persona ? store.go(store.persona) : store.nav('/app')
            }
            className="cursor-pointer flex ml-2 p-0 mb-2 items-center"
          >
            <a>
              <img
                src={store.persona?.avatar_url ?? logoCircled}
                className="rounded-full select-none hand_logo shrink-0 w-[35px]"
              />
            </a>

            {store.user?.show.sidebar && (
              <div className="truncate text-xl text-gray-600 px-3 font-medium">
                {store.persona ? store.persona.name : 'Persona Hub'}
              </div>
            )}
          </div>
          <Separator className="mb-2" />
          <div className="flex flex-col my-0 p-0 m-0 space-y-4 items-start">
            <Button
              onClick={() => store.toggle('sidebar')}
              variant="ghost"
              className="ml-2 p-1 hover:bg-stone-200 items-end justify-end font-medium text-lg"
            >
              {!store.user?.show.sidebar ? (
                <PinRightIcon className="shrink-0 m-2" />
              ) : (
                <PinLeftIcon className="shrink-0 m-2" />
              )}
            </Button>
            {store.persona && (
              <Button
                onClick={() => store.nav(`/hello/${store.current_persona}`)}
                variant="ghost"
                className="ml-2 p-1 hover:bg-stone-200 w-full items-center justify-start font-medium text-lg"
              >
                <PlusCircledIcon className="shrink-0 m-2" />
                {store.user.show?.sidebar ? 'New' : ''}
              </Button>
            )}
            <Button
              variant="ghost"
              className="ml-2  p-1 hover:bg-stone-200 w-full items-center justify-start font-medium text-lg"
            >
              <LayersIcon className="shrink-0 m-2" />{' '}
              {store.user.show?.sidebar ? 'History' : ''}
            </Button>
            {store.user.show?.sidebar && store.persona_rooms().length ? (
              <div className="flex flex-col items-start w-full space-y-3">
                {store
                  .persona_rooms()
                  .slice(0, 10)
                  .map((r) => (
                    <Button
                      onClick={() => store.go(r)}
                      variant="ghost"
                      className="hover:bg-stone-200 w-full"
                    >
                      <div className="flex ml-2 text-left justify-start items-start w-full">
                        <span className="w-full min-w-0 shrink truncate">
                          {store.room_title(r.id)}
                        </span>
                      </div>
                    </Button>
                  ))}
              </div>
            ) : null}
            <Button
              onClick={() => store.nav('/explore')}
              variant="ghost"
              className="ml-2 p-1 hover:bg-stone-200 w-full items-center justify-start font-medium text-lg"
            >
              <RocketIcon className="shrink-0 m-2" />{' '}
              {store.user.show?.sidebar ? 'Discover' : ''}
            </Button>
          </div>
        </motion.div>
        <motion.div
          layout
          className="overflow-hidden bg-neutral-50 rounded-tr-lg rounded-tl-lg shadow w-full m-2 mb-0"
        >
          {this.renderMain()}
        </motion.div>
      </div>
    )
  }
}

export class Explore extends SideBarPage {
  constructor(props) {
    super(props, { type: 'user' })
    this.state.search = ''
  }

  renderMain() {
    if (store.state._loading) {
      return <Loading />
    }
    return (
      <div className="overflow-scroll h-full p-2">
        <div className="relative w-[95%] m-5">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button
              type="submit"
              className="p-1 focus:outline-none focus:shadow-outline"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <Input
            placeholder="Search Personas"
            value={this.state.search}
            onInput={(v) => {
              //@ts-ignore
              this.setState({ search: v.target.value })
            }}
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendInvite(get_elem_value('invite_email'))
                store.toggle('send_invite', false)
              }
            }}
            className="text-lg p-7 pl-12 bg-white w-full"
            id="character_search"
          />
        </div>
        <div className="flex flex-row flex-wrap">
          {store.public_characters
            .filter((c) => {
              return c.name
                ?.toLowerCase()
                .includes(this.state.search?.toLowerCase())
            })
            .map((u) => (
              <PersonaCard key={u.id} persona={u} />
            ))}
        </div>
      </div>
    )
  }
}

export class PersonaCard extends VComponent {
  render() {
    const { noborder, persona, className } = this.props
    let plan = store.plan_always
    if (!persona) return null
    return (
      <div
        className={
          className ??
          cn(
            'flex flex-col hover:scale-105 cursor-pointer h-[340px] w-[300px] overflow-hidden p-1 m-2 space-y-0 bg-white rounded-lg',
            { shadow: !noborder },
          )
        }
        onClick={() =>
          this.props.onClick
            ? this.props.onClick()
            : persona.ai && store.nav(`/hello/${persona.id}`)
        }
      >
        <div className="flex flex-row justify-end w-full">
          {store.user.location.includes('explore') &&
          persona.ai &&
          !persona.builtin &&
          !store.user.anonymous ? (
            <>
              {store.is_vello_admin && (
                <Tip side={'left'} content={<div>Hide</div>}>
                  <ConfirmButton
                    icon={<EyeClosedIcon />}
                    key="remove_ai"
                    confirmIcon="same"
                    className=" md:ml-0 mr-0 text-slate-300"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      store.upsertAIUser({ id: persona.id, shadow: true })
                    }}
                  />
                </Tip>
              )}
              <Tip side={'left'} content={<div>Add to Space</div>}>
                <ConfirmButton
                  icon={<PlusIcon />}
                  key="add_user"
                  confirmIcon="same"
                  className=" md:ml-0 mr-0 text-slate-300"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    Commands.run('add_to_teams', e, { target: persona })
                    // setOpen(true)
                  }}
                />
              </Tip>
            </>
          ) : (
            <div className="h-4"></div>
          )}
        </div>
        <div className="flex flex-col content-center text-center justify-center items-center">
          <motion.div
            key={`${plan?.cancelled}-${plan?.status}-${plan?.name}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img
              src={
                persona.avatar_url?.length > 0
                  ? persona.avatar_url
                  : persona.id == store.user.id && plan?.name
                    ? plan?.cancelled
                      ? `/cancelled.png`
                      : plan?.status == 'trialing'
                        ? `/trial.png`
                        : plan?.has_flex
                          ? `/flex.png`
                          : `/${plan?.name}.png`
                    : logoCircled
              }
              className={
                persona.id == store.user.id && plan?.name
                  ? 'select-none hand_logo shrink-0 w-[150px]'
                  : 'rounded-full shadow-lg select-none hand_logo shrink-0 h-[150px] w-[150px]'
              }
            />
          </motion.div>
          <Separator className="m-4" />
          <div className="text-lg text-gray-600 pt-0 font-medium">
            {store.user_name(persona.id)}
          </div>
          <div className="text-xs text-gray-400 my-2">
            {persona.ai ? 'created' : 'joined'} {ago(persona?.create_time)} ago
            · active {ago(persona.last_focused)}{' '}
            {ago(persona.last_focused) == 'now' ? '' : 'ago'}
          </div>
          <div className="text-sm text-gray-400">
            {(persona?.intro ?? persona?.description)?.trunc(100) ?? ''}
          </div>
        </div>
      </div>
    )
  }
}

class Persona extends VComponent {
  render() {
    if (store.room_messages().length == 0) {
      return (
        <>
          <div className="flex-col justify-center w-full h-full">
            <div className="pt-20 flex justify-center">
              <div className="flex flex-col max-w-2xl content-center text-center justify-center items-center">
                <img
                  src={store.persona.avatar_url ?? logoCircled}
                  className="rounded-full ring-2 ring-gray-300 dark:ring-gray-500 shadow-lg select-none hand_logo shrink-0 w-[150px] md:w-[250px]"
                />
                <div
                  key="name"
                  className="text-3xl text-gray-600 py-5 font-medium"
                >
                  {store.persona?.name}
                </div>
                <div className="text-md text-gray-400 pt-5">
                  {store.persona?.intro ?? store.persona?.description}
                </div>
              </div>
            </div>
            <motion.div
              key="message_input"
              layout
              className="flex w-full h-full"
            >
              <MessageArea
                relative
                placeholder="Ask anything..."
                hide_settings
              />
            </motion.div>
          </div>
        </>
      )
    } else {
      return <Chat hide_settings />
    }
  }
}

export class PersonaView extends SideBarPage {
  constructor(props) {
    super(props)
  }

  renderMain() {
    return <Persona />
  }

  renderFooter() {
    return (
      <div className="flex space-x-3 items-center p-1 pr-2 fixed top-0 right-2">
        <Tip align={'end'} content="Dark Mode">
          <DarkModeToggle />
        </Tip>
      </div>
    )
  }
}

async function handleFileUpload(file) {
  const reader = new FileReader()
  reader.onload = handleFileRead.bind(this)
  reader.readAsText(file)
}

function filterNullKeys(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      filterNullKeys(obj[key])
    }
  }
}
async function handleFileRead(event) {
  const contents = event.target.result
  const parsedData = JSON.parse(contents)
  // Do something with the parsed JSON data

  store._import = true
  let uid = store.user.id
  store.unshowAll()
  // store.setToast({ level:'success', options:{id: 'import', duration:Infinity}, title: 'Starting OpenAI Import', content: 'Please leave this tab open until import is complete.  If there is an issue it is safe to re-run import, your chats will not be duplicated.' })
  for (const rid in parsedData) {
    console.log(`rid`, rid)
    // store.off()
    const r = parsedData[rid]
    let msgs = r.mapping
    delete r.mapping
    filterNullKeys(r)
    r.update_time *= 1000
    r.create_time = r.create_time ? r.create_time * 1000 : r.update_time
    store.addRoom(false, r, 'openai/gpt-4')
    values(msgs)
      .filter((m) => m.message)
      .map((m) => {
        m.role = m.message.author?.role
        m.content = m.message.content?.parts?.at(0) || ''
        m.imported = true
        m.update_time *= 1000
        m.create_time = m.create_time ? m.create_time * 1000 : m.update_time
        m = JSON.parse(JSON.stringify(m))
        delete m.message
        filterNullKeys(m)
        store.addMessageSilent(
          _.cloneDeep(m),
          r.id,
          m.role == 'user' ? null : 'ai',
        )
      })

    store.setToast({
      quiet: true,
      options: { id: 'import', duration: Infinity },
      title: 'Running OpenAI Import',
      content:
        `${parseInt(rid) + 1}/${parsedData.length} conversations imported. ` +
        'Please leave this tab open until import is complete.  If there is an issue it is safe to re-run import, your chats will not be duplicated.',
    })

    await new Promise((r) => setTimeout(r, 400))
    if (store.user.id != uid) {
      break
    }
    // store.on()
  }

  store.setToast({
    level: 'success',
    options: { id: 'import', duration: Infinity },
    title: 'OpenAI Import Complete',
    content:
      'You may continue any of your OpenAI conversations with any Vello model or persona.',
  })
  store._import = false
}

class Invites extends ObserverComponent {
  constructor(props) {
    super(props, (store) => [store.state.invites])
  }

  render() {
    if (!store.invites) return null
    return (
      <Dialog open={store.invites.length > 0}>
        <DialogContent>
          <DialogTitle>Invites</DialogTitle>
          <DialogDescription>
            Shared Spaces can be used to collaborate with others. You can join a
            space by accepting an invite.
          </DialogDescription>
          <div className="flex flex-col space-y-2">
            {(store.invites || []).map((invite) => (
              <div
                key={`invite-${invite.id}`}
                className="flex flex-col space-y-0 pt-8"
              >
                <div className="flex justify-between items-center">
                  <div className="flex-col space-y-1">
                    <div className="text-md font-medium">
                      Join space `
                      <span className="font-bold">{invite.team_name}</span>`?
                    </div>
                    <div className="text-sm text-gray-500">
                      <span className="font-medium">{invite.from}</span> invited
                      you to join their workspace.
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      key={`accept-${invite.id}`}
                      onClick={async () => {
                        store.acceptInvite(invite)
                        await post(
                          '/accept',
                          { id: invite.team_id, name: invite.team_name },
                          'Accepting invite',
                        )
                        await Sync.refreshProfile()
                        await Sync.setTeam({
                          team_id: invite.team_id,
                          team_name: invite.team_name,
                        })
                        //this.setState({ _dummy: Math.random() })
                      }}
                    >
                      Join
                    </Button>
                    <Button
                      key={`decline-${invite.id}`}
                      onClick={async () => {
                        store.declineInvite(invite)
                        // await post('/decline', invite, 'Declining invite')
                        // await Sync.refreshProfile()
                        this.setState({ _dummy: Math.random() })
                      }}
                      variant="destructive"
                    >
                      Decline
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

function DarkModeToggle(props) {
  return (
    <div {...props}>
      <button
        onClick={() => {
          store.toggleProp('dark_mode')
          document.firstElementChild.setAttribute(
            'data-theme',
            store.user?.dark_mode ? 'dark' : 'light',
          )
          document
            .querySelector('#theme-toggle')
            ?.setAttribute(
              'aria-label',
              store.user?.dark_mode ? 'dark' : 'light',
            )
        }}
        className="theme-toggle opacity-40 w-8 mt-2"
        id="theme-toggle"
        title="Toggles light & dark"
        aria-label={store.user?.dark_mode ? 'dark' : 'light'}
        aria-live="polite"
      >
        <svg
          className="sun-and-moon"
          aria-hidden="true"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <mask className="moon" id="moon-mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <circle cx="24" cy="10" r="6" fill="black" />
          </mask>
          <circle
            className="sun"
            cx="12"
            cy="12"
            r="6"
            mask="url(#moon-mask)"
            fill="currentColor"
          />
          <g className="sun-beams" stroke="currentColor">
            <line x1="12" y1="1" x2="12" y2="3" />
            <line x1="12" y1="21" x2="12" y2="23" />
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
            <line x1="1" y1="12" x2="3" y2="12" />
            <line x1="21" y1="12" x2="23" y2="12" />
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
          </g>
        </svg>
      </button>
    </div>
  )
}

class RoomItem extends ObserverComponent {
  constructor(props) {
    super(props, (store) => [
      props.room,
      props.room.id === store.room?.id,
      store.user?.seen && store.user.seen[props.room.id],
      // () => values(store.state.users).filter((u) => store.user_current_room_(u) === props.room.id).length,
    ])
  }

  render() {
    var r = this.props.room
    return (
      <CommandItem
        key={`room-select-${r.id}`}
        className={cn(
          'whitespace-nowrap',
          'overflow-hidden',
          'p-3',
          'group',
          r.id == store.room?.id && 'bg-slate-150',
        )}
        onSelect={(value) => {
          //note: don't use cmdk value.. because it lowercases
          store.go(r)
          // setOpen(false);
        }}
        value={store.room_title(r.id) + ' | ' + r.id}
      >
        <RoomItemLabel room={r} />
      </CommandItem>
    )
  }
}

class Rooms extends ObserverComponent {
  search = createRef<any>()
  state = {
    search: '',
  }

  constructor(props) {
    super(
      props,
      (store) => {
        var r = [
          store.team.id,
          store.state.rooms,
          store.bookmarks.length,
          store.user.show?.archived,
        ]
        return r
      },
      (action) =>
        action.name != 'setNoteContents' && action.name != 'setCurrentMessage',
      'rooms',
    )
  }

  componentDidMount() {
    super.componentDidMount()
    Keys.search = this.search
  }

  render() {
    return (
      <CommandC
        className="pt-1 bg-slate-50 bg-opacity-90"
        shouldFilter={false}
        filter={() => 1}
        key="rooms"
      >
        <CommandInput
          onValueChange={(v) => this.setState({ search: v })}
          value={this.state.search}
          key="rooms_input"
          ref={this.search}
          placeholder={`Search Chats (${KEY_MOD}/)`}
        />
        <CommandList key="rooms_list" className="max-h-none">
          <CommandEmpty>
            <div className="text-slate-400">Nothing found.</div>
          </CommandEmpty>
          {store.roomsQuery(this.state.search).map(([k, rms]) => {
            const now = new Date()
            let divider
            let last_divider
            return (
              <CommandGroup key={k} heading={k}>
                {rms.slice(0, 500).map((r) => {
                  // Parse the ISO date from the room's update time
                  const updatedAt = new Date(r.update_time)

                  last_divider = divider
                  // Determine which divider to use based on when the room was updated
                  if (isToday(updatedAt)) {
                    divider = 'Today'
                  } else if (isYesterday(updatedAt)) {
                    divider = 'Yesterday'
                  } else if (isAfter(updatedAt, subWeeks(now, 1))) {
                    divider = 'Last Week'
                  } else if (isAfter(updatedAt, subMonths(now, 1))) {
                    divider = 'Last Month'
                  } else if (isAfter(updatedAt, subYears(now, 1))) {
                    divider = 'Last Year'
                  } else {
                    divider = 'Older'
                  }

                  return (
                    <div key={r.id}>
                      {k == 'All Chats' && divider !== last_divider && (
                        <div
                          className={cn(
                            'flex text-slate-400 font-medium text-xs px-2 mt-2',
                            { 'mt-6': last_divider },
                          )}
                        >
                          <div>{divider}</div>
                        </div>
                      )}
                      <RoomItem key={r.id} room={r} />
                    </div>
                  )
                })}
                <Separator />
              </CommandGroup>
            )
          })}
        </CommandList>
      </CommandC>
    )
  }
}

class CommandButton extends PComponent {
  render() {
    var command = Commands.all[this.props.id]
    return (
      <Tip
        content={
          <div>
            {command.name} {render_key_sequence(command.sequence)}
          </div>
        }
      >
        <Button
          className="text-slate-300"
          onClick={() => Commands.run(this.props.id)}
          variant="ghost"
        >
          {command.icon}
        </Button>
      </Tip>
    )
  }
}

function RoomItemLabel({ room, simple = false }) {
  var users = store.usersInRoom(room.id)
  var notifs = store
    .user_notifs()
    .filter((n) => n.key == room.id)
    .filter((n) => n.create_time > store.user.seen[room.id])
  return (
    <Sentry.ErrorBoundary fallback={<></>}>
      <div className="w-full flex items-center">
        {notifs.length ? (
          <Badge
            className="shrink-0 rounded-full m-0 mr-2 p-1 h-4"
            variant="destructive"
          >
            {notifs.length}
          </Badge>
        ) : null}
        {!notifs.length && !simple && (
          <>
            <span className="shrink-0 md:group-hover:hidden">
              {room.pinned ? (
                <DrawingPinFilledIcon className="shrink-0 text-slate-400 mr-2" />
              ) : store.getIsStreaming(room.id) ? (
                <PuffLoader
                  className="shrink-0 text-slate-400 mr-2"
                  size={15}
                />
              ) : room.room_id ? (
                <BookmarkFilledIcon className="shrink-0 text-slate-400 mr-2" />
              ) : (
                <ChatBubbleIcon className="shrink-0 text-slate-300 mr-2" />
              )}
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation()
                store.togglePinned(room.id)
              }}
              className="shrink-0 hidden md:group-hover:block"
            >
              {room.room_id &&
                (room.bookmarked ? (
                  <BookmarkIcon className="shrink-0 text-slate-300 mr-2" />
                ) : (
                  <BookmarkFilledIcon className="shrink-0 text-slate-400 mr-2" />
                ))}
              {!room.room_id &&
                (room.pinned ? (
                  <DrawingPinIcon className="shrink-0 text-slate-300 mr-2" />
                ) : (
                  <DrawingPinFilledIcon className="shrink-0 text-slate-400 mr-2" />
                ))}
            </span>
          </>
        )}
        <span className="min-w-0 shrink truncate">
          {room.room_id ? room.content?.trunc?.(50) : store.room_title(room.id)}
        </span>
        {!simple && users && users.length > 0 ? (
          <span className="text-sm text-gray-400 pl-2 shrink-0">
            <span className="flex text-sm text-gray-400 space-x-1">
              {users
                .filter((u) => !u.anonymous)
                .map((u) => (
                  <Ava className="shrink-0" key={u.id} pill user={u} />
                ))}
            </span>
          </span>
        ) : null}
        <div className="grow" />
        {room.archived ? (
          <ResetIcon
            onClick={(e) => {
              e.stopPropagation()
              store.unDeleteRoom(room.id)
            }}
            className="invisible md:group-hover:visible cursor-pointer text-slate-400 shrink-0"
          />
        ) : (
          <Cross2Icon
            onClick={(e) => {
              e.stopPropagation()
              store.deleteRoom(room.id)
            }}
            className="invisible md:group-hover:visible cursor-pointer text-slate-400 shrink-0"
          />
        )}
      </div>
    </Sentry.ErrorBoundary>
  )
}

class RoomHeader extends PComponent {
  // componentDidMount() {
  //   store.watch(
  //     (store) => [store.user.layout],
  //     () => this.forceUpdate(),
  //     'room_header_layout',
  //   )
  // }

  // componentWillUnmount(): void {
  //   store.unwatch('room_header_layout')
  // }

  render() {
    var nfs = store.user_notifs(true).filter((n) => !n.ephemeral)
    return (
      <div className="fixed top-0 z-20 bg-slate-50 bg-opacity-95 shadow backdrop-blur-lg w-full flex p-0 pr-2 align-center items-center justify-left">
        {!store.is_mobile && (
          <div>
            {' '}
            <Tip
              content={
                <div>
                  Toggle Sidebar <kbd>Ctrl</kbd> <kbd>[</kbd>
                </div>
              }
            >
              <Button
                className="text-slate-400"
                onClick={() => store.collapseLeft(store.user.layout?.[0] !== 0)}
                variant="ghost"
              >
                {store.user.layout?.[0] === 0 ? (
                  <PinRightIcon />
                ) : (
                  <PinLeftIcon />
                )}
              </Button>
            </Tip>
          </div>
        )}
        <Tip
          content={
            <div>
              New Chat <kbd>Ctrl</kbd> <kbd>M</kbd>
            </div>
          }
        >
          <div className="scale-75">
            <ConfirmButton
              icon={<PlusIcon />}
              confirmIcon="same"
              key="new_chat"
              className="md:ml-2 shadow-inner shadow-lg ml-1 p-2 mr-4 text-slate-400 ring-1 ring-slate-300 hover:bg-slate-100 hover:text-slate-400"
              onClick={() => store.addRoom(true)}
            />
          </div>
        </Tip>
        {!store.room && <div className="grow" />}
        {!store.in_publish && !store.user.show?.user_edit && store.room ? (
          <div className="desktop-header-drag mx-2 w-full truncate text-center items-center justify-center text-slate-400 flex">
            <div
              onClick={() => Commands.run('rename_chat')}
              className="pr-1 truncate"
            >
              {store.room_title(store.room.id)}
            </div>
            {!store.user.show?.user_edit &&
              store.room_headers(store.room.id).map((u) => [
                <Ava
                  className="no-drag shrink-0 ml-3"
                  // selected={store.room_models().includes(u.id)}
                  key={u.id}
                  pill
                  user={u}
                />,
              ])}

            <div className="no-drag">
              <Tip
                content={
                  <div className="text-left max-w-xl">
                    <div className="font-semibold">
                      <kbd>Ctrl</kbd> <kbd>E</kbd> Edit Chat Members
                    </div>
                    <div className="pt-2 font-normal">
                      Models you add here will respond in order to every message
                      in this chat by default.
                    </div>
                    <div>
                      You can @mention specific models in your messages to
                      override this for the next message.
                    </div>
                  </div>
                }
              >
                <ConfirmButton
                  icon={<ChevronDownIcon className="text-slate-800" />}
                  key="add_user"
                  confirmIcon="same"
                  className=" md:ml-2 mr-0 text-slate-300 border-0 hover:bg-slate-100 hover:text-slate-400"
                  onClick={(e) => {
                    e.preventDefault()
                    store.toggle('user_edit')
                  }}
                />
              </Tip>
            </div>
          </div>
        ) : (
          <div className="grow" />
        )}
        {store.user.show?.user_edit && store.room && (
          <div className="grow w-full place-items-center items-center flex justify-center">
            <div className="pr-1 text-slate-400 truncate">
              {store.room_title(store.room.id)}
            </div>
            <div className="w-auto min-w-[300px]">
              <UserSelect
                autoselect
                escCallback={(e) => store.toggle('user_edit', false)}
                onUnselect={(uid) => {
                  if (store.users[uid]?.ai) store.removeRoomModels([uid])
                  else store.removeRoomMembers([uid])
                }}
                onSelect={(uid) => {
                  if (store.users[uid].ai) store.addRoomModels([uid])
                  else store.addRoomMembers([uid])
                }}
                selected={store.room_headers().map((u) => u.id)}
                selectables={store.others.filter(
                  (u) => u.id != 'ai' && !u.anonymous,
                )}
              />
            </div>
          </div>
        )}
        {!store.user.anonymous && (
          <>
            {!store.is_mobile && (
              <Tip
                content={
                  <div>
                    Settings <kbd>{OPTION_MOD}</kbd>
                    <kbd>,</kbd>
                  </div>
                }
              >
                <ConfirmButton
                  icon={<GearIcon />}
                  confirmIcon="same"
                  className="md:ml-2 ml-1 mr-0 text-slate-300"
                  onClick={(e) => {
                    e.preventDefault()
                    store.toggle('space_settings')
                  }}
                />
              </Tip>
            )}
            {store.user_notifs().length > 0 &&
              !(store.is_mobile && nfs.length == 0) && (
                <Tip content={<div>Notifications</div>}>
                  <Popover
                    open={store.user?.show?.notifs}
                    onOpenChange={(o) => store.toggle('notifs', o)}
                  >
                    <PopoverTrigger asChild>
                      <Button
                        onClick={() => {
                          store.toggle('notifs')
                          later(() => store.see('notifs'), 500)
                        }}
                        className="text-slate-300"
                        variant="ghost"
                      >
                        {
                          /*nfs.length > 0*/ false ? (
                            <BellFilledIcon />
                          ) : (
                            <BellIcon />
                          )
                        }
                        {nfs.length > 0 && (
                          <Badge
                            className="shrink-0 rounded-full m-0 mr-0 opacity-70 -ml-1 -mt-2 p-1 h-4"
                            variant="destructive"
                          >
                            {nfs.length}
                          </Badge>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="overflow-y-scroll max-h-[630px] w-[380px] max-w-full p-0 mx-4">
                      {store.user_notifs().filter((n) => !n.ephemeral)
                        .length === 0 ? (
                        <div className="px-4 py-4 flex items-center justify-between select-none cursor-pointer hover:bg-slate-50">
                          <div className="flex items-center">
                            <div className="ml-2 text-xs text-slate-300">
                              No notifications
                            </div>
                          </div>
                        </div>
                      ) : (
                        store
                          .user_notifs()
                          .filter((n) => !n.ephemeral)
                          .slice(0, 20)
                          .map((n) => {
                            if (!store.messages[n.target]) return null
                            return (
                              <Sentry.ErrorBoundary fallback={null} key={n.id}>
                                <div
                                  onClick={() => {
                                    store.toggle('notifs', false)
                                    store.setCurrentRoomMessage(n.target)
                                  }}
                                  key={n.id}
                                  className={cn(
                                    'px-4 py-2 flex items-center justify-between select-none cursor-pointer hover:bg-slate-50',
                                    {
                                      'bg-yellow-100':
                                        !n.seen &&
                                        n.create_time >
                                          store.user.seen['notifs'],
                                    },
                                  )}
                                >
                                  <div className="flex items-center">
                                    <Ava
                                      className="shrink-0"
                                      pill
                                      user={
                                        store.users[
                                          store.messages[n.target]?.owner_id
                                        ]
                                      }
                                    />
                                    <div className="ml-2">
                                      <div className="flex space-x-1">
                                        <div className="text-xs text-slate-300">
                                          {
                                            store.teams[
                                              store.in_teams(
                                                store.messages[n.target],
                                              )[0]
                                            ]?.name
                                          }
                                        </div>
                                        <div className="text-xs text-slate-300">
                                          · {ago(n.create_time)} ago
                                        </div>
                                      </div>
                                      <div className="text-xs text-slate-400 truncate">
                                        {store.messages[n.target].content}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Sentry.ErrorBoundary>
                            )
                          })
                      )}
                    </PopoverContent>
                  </Popover>
                </Tip>
              )}
          </>
        )}

        <CommandButton id={'share'} />
        <Tip
          content={
            <div>
              Commands <kbd>⌘ K</kbd>
            </div>
          }
        >
          <Button
            className="text-slate-300"
            onClick={() => store.toggle('bar')}
            variant="ghost"
          >
            <HamburgerMenuIcon />
          </Button>
        </Tip>
        <NotesToggle />
      </div>
    )
  }
}

function scrollToBottom() {
  if (store.user_current_message) {
    later(() => {
      document
        .getElementById(store.user_current_message)
        ?.scrollIntoView({ behavior: 'instant' })
    })
  } else {
    document.getElementById('chat_thread')?.scroll(0, 9999999)
    // later(() => {
    //   document.getElementById('end')?.scrollIntoView({ behavior: 'instant' })
    // })
  }
}

class ScrollDownButton extends VComponent {
  componentDidMount() {
    document
      .getElementById('chat_thread')
      ?.addEventListener('scroll', () => this.forceUpdate())
  }

  render() {
    return (
      scroll_amount('chat_thread') > 1000 && (
        <div className="scrollDownButton z-50">
          <ConfirmButton
            onClick={(e) => {
              scrollToBottom()
            }}
            confirmIcon="same"
            icon={
              <div className="bg-red">
                <BsArrowDownCircleFill className="opacity-40 w-8 h-8" />
              </div>
            }
          />
        </div>
      )
    )
  }
}

function PricingCheckout() {
  return (
    <>
      <PricingHeader />
      {!store.plan_always ? (
        <div className="flex w-full stretch">
          <div className="w-full">
            <StripeCheckout key="checkout" />
            <PricingFooter />
          </div>
        </div>
      ) : (
        <>
          <Plan />
          <PricingFooter />
        </>
      )}
    </>
  )
}

function PricingTable() {
  // Paste the stripe-pricing-table snippet in your React component
  //@ts-ignore
  return (
    <>
      <PricingHeader />
      {true ? (
        //@ts-ignore
        <>
          <stripe-pricing-table
            pricing-table-id={import.meta.env.VITE_STRIPE_PRICING_TABLE_ID}
            client-reference-id={window.Rewardful?.referral ?? store.user.id}
            customer-email={store.user.email}
            publishable-key={import.meta.env.VITE_STRIPE_PUBLIC_KEY}
          ></stripe-pricing-table>
          <PricingFooter />
        </>
      ) : (
        <>
          <Plan />
        </>
      )}
    </>
  )
}

// <stripe-pricing-table pricing-table-id="prctbl_1O5JGlHXHeMd1ouKVfFtWmy3"
//    client-reference-id={store.user.id}
//    customer-email={store.user.email}
//    publishable-key="pk_test_51IAXABHXHeMd1ouKp5DCCiGMqCYzbtD7HujyKjbsn4WKUgZLRQOav9EGDnBiy7Fj7lypQLxv2seyVbOcts0tekEi00MgKfRX5Z">
// </stripe-pricing-table> : <Plan />}

// <div className="ml-2 text-md text-slate-400">
//    <Button
//     onClick={async () => {
//       var d = await post('/stripe/end-trial');
//     }}
//   >Cancel</Button>
// </div></> : (
function Plan() {
  const [loading, setLoading] = useState(false)
  var plan = store.plan_always
  const msg = store.user_message()
  return (
    <>
      <PersonaCard
        className="w-full"
        key={store.user.id}
        onClick={() => {}}
        persona={store.user}
      />
      <div className="whitespace-nowrap mt-6 m-2 bg-slate-100 p-4 rounded-lg border-l-4 border-slate-200">
        <div className="flex flex-wrap items-center space-y-3">
          <div className="ml-2 font-semibold text-md text-slate-400 shrink-0">
            <img
              src={
                plan.has_flex
                  ? `/flex.png`
                  : plan.status == 'trialing'
                    ? `/trial.png`
                    : `/${plan.name}.png`
              }
              className="h-8 mr-2 shrink-0"
            />
          </div>
          <div className="ml-2 font-semibold text-md text-slate-400">
            {toCapitalCase(plan.name)} {plan.has_flex ? ' Flex' : ''} Plan
          </div>
          {plan.status == 'trialing' ? (
            <div className="ml-2 text-md font-medium text-rose-500">
              · Limited Free Trial
            </div>
          ) : (
            <div
              className={cn('ml-2 text-md font-medium text-green-500', {
                'text-rose-500': !['active', 'trialing'].includes(plan.status),
              })}
            >
              {plan?.cancelled || store.plan_expired
                ? ''
                : '· ' + toCapitalCase(plan.status.replace('_', ' '))}
            </div>
          )}
          {!['canceled', 'active', 'trialing'].includes(plan.status) && (
            <div
              className={cn('text-md', {
                'text-rose-400': true,
              })}
            >
              · Payment Issue ·
              <a
                className="mx-2 text-rose-400 font-medium cursor-pointer underline"
                onClick={async () => {
                  window.open(
                    (await (await post('/stripe_portal_url')).json()).url,
                    '_blank',
                  )
                }}
              >
                Fix Payment
              </a>
            </div>
          )}
          {store.plan_expired && (
            <div className={cn('text-md text-rose-400 font-medium')}>
              {'· Expired · '}
              {rel(plan.ending * 1000)}
            </div>
          )}
          {plan?.cancelled && (
            <div
              className={cn('ml-1 text-md', {
                'text-slate-400': !plan.cancelled,
                'text-rose-400': plan.cancelled,
              })}
            >
              · {plan.cancelled ? 'Cancelled' : 'Next Billing Cycle '}{' '}
              {plan.cancelled ? '' : rel(plan.ending * 1000)}
            </div>
          )}
          <div className="grow" />
          {store.plan?.has_flex && (
            <div className="flex items-center mr-2">
              <Switch
                checked={store.user.use_full_context}
                onCheckedChange={(e) => {
                  store.toggleProp('use_full_context')
                }}
              />
              <Label className="mx-2">Use Full Context</Label>
              <Button
                variant="link"
                disabled={loading}
                onClick={async () => {
                  store.unshowAll()
                  store.toggle('flex_usage', true)
                }}
              >
                View Flex Usage
              </Button>
            </div>
          )}
          {store.plan?.status != 'cancelled' &&
            store.plan?.status != 'trialing' &&
            store.plan?.name == 'plus' &&
            msg?.type == 'warning' && (
              <div className="flex items-center mr-2">
                <Button
                  className="bg-sky-800"
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true)
                    var d = await post('/stripe/upgrade-to-pro')
                    later(() => setLoading(false), 1000)
                    // setLoading(false);
                    lg('funnel', { step: 'upgrade_to_pro' })
                  }}
                >
                  {loading ? (
                    <BeatLoader
                      size={4}
                      speedMultiplier={0.5}
                      className="text-white mr-2 shrink-0 opacity-40"
                    />
                  ) : null}
                  Upgrade to Pro
                  <div className="text-sm text-slate-200">
                    &nbsp;+ $12/month
                  </div>
                </Button>
              </div>
            )}

          {store.plan?.name == 'pro' &&
            !store.plan?.has_flex &&
            store.plan?.status !== 'trialing' &&
            msg?.type == 'warning' && (
              <div className="flex items-center mr-2">
                <Button
                  className="bg-sky-800"
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true)
                    var d = await post('/stripe/add-flex')
                    later(() => setLoading(false), 1000)
                    // setLoading(false);
                    lg('funnel', { step: 'add_flex' })
                  }}
                >
                  {loading ? (
                    <BeatLoader
                      size={4}
                      speedMultiplier={0.5}
                      className="text-white mr-2 shrink-0 opacity-40"
                    />
                  ) : null}
                  Enable Flex
                </Button>
              </div>
            )}
          {plan?.cancelled ? (
            <div className="text-md text-slate-400">
              <Button
                className="bg-sky-800"
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  var d = await post('/stripe/reactivate')
                  later(() => setLoading(false), 1000)
                  // setLoading(false);
                  lg('funnel', { step: 'reactivate_plan' })
                }}
              >
                {loading ? (
                  <BeatLoader
                    size={4}
                    speedMultiplier={0.5}
                    className="text-white mr-2 shrink-0 opacity-40"
                  />
                ) : null}
                Renew Plan
              </Button>
              <Button
                variant="link"
                className="ml-2"
                onClick={async () => {
                  window.open(
                    (await (await post('/stripe_portal_url')).json()).url,
                    '_blank',
                  )
                }}
              >
                View Plan
              </Button>
            </div>
          ) : plan.status == 'trialing' ? (
            <div className="ml-2 text-md text-slate-400">
              <Button
                className="bg-sky-800"
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  var d = await post('/stripe/end-trial')
                  if (d.status != 200) {
                    setLoading(false)
                    store.setToast({
                      level: 'error',
                      title: 'Issue processing your payment.',
                      content:
                        'Click `View Plan` or email us at hello@vello.ai to resolve.',
                    })
                    return
                  }
                  later(() => setLoading(false), 1000)
                  lg('funnel', { step: 'activate_plan' })
                }}
              >
                {loading ? (
                  <BeatLoader
                    size={4}
                    speedMultiplier={0.5}
                    className="text-white mr-2 shrink-0 opacity-40"
                  />
                ) : null}
                Start Paid Plan
              </Button>
              <Button
                variant="link"
                className="ml-2"
                onClick={async () => {
                  window.open(
                    (await (await post('/stripe_portal_url')).json()).url,
                    '_blank',
                  )
                }}
              >
                View Plan
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              onClick={async () => {
                var url = (await (await post('/stripe_portal_url')).json()).url
                window.open(url, '_blank')
              }}
            >
              View / Upgrade Plan
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

function PricingHeader({ show_discount = false }) {
  const msg = store.user_message()
  return (
    <div className="grid grid-cols-3 gap-8 bg-white p-2 mt-2">
      <div className="col-span-3">
        {msg?.type == 'warning' && (
          <div className="mb-2 bg-orange-100 p-4 rounded-lg border-l-4 border-orange-200">
            <div className="flex items-center">
              <LapTimerIcon className="w-4 h-4 text-orange-400 mr-2" />
              <h2 className="text-lg font-medium text-orange-400">
                {msg.title}
              </h2>
            </div>

            <p className="text-sm text-gray-500 mt-2">
              <ReactMarkdown components={MarkdownComponents}>
                {msg?.content}
              </ReactMarkdown>
            </p>
          </div>
        )}
        {!store.user.show.pricing_table && show_discount && !store.plan && (
          <div className="bg-slate-50 p-4 rounded-lg border-l-4 border-slate-200">
            <div className="flex items-center">
              <HeartFilledIcon className="w-4 h-4 text-slate-400 mr-2" />
              <h2 className="text-lg font-medium text-slate-400">
                Early Adopter Discount
              </h2>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Start working with the world's best AI suite today with{' '}
              <span className="text-slate-600 font-semibold">
                40% off the first year of Vello Plus
              </span>{' '}
              if purchased{' '}
              <span className="text-slate-600 font-semibold">
                in the next hour
              </span>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

function PricingFooter() {
  return (
    <div className="grid grid-cols-3 gap-8 bg-white p-4 pt-2">
      <div className="col-span-3">
        {FL('show-future-features') && store.user.show.pricing_table ? (
          <div className="bg-slate-50 p-4 rounded-lg border-l-4 border-slate-200">
            <div className="flex items-center mt-2">
              <CalendarIcon className="w-4 h-4 text-slate-400 mr-2" />
              <h2 className="text-lg font-medium text-slate-400">
                Future Features
              </h2>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Your Vello plan will continue to bring you the best AI models and
              tools, in the most elegant package.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Along with all existing features, premium plans will enjoy
              everything coming up on our roadmap: a native mobile client, data
              integrations and ingestion with your favorite tools, desktop
              filesystem integration, vision & video models, more complex agenic
              behavior for intricate workflows, persona publishing & embeds, and
              more.
            </p>
          </div>
        ) : null}
        {!store.user.show.pricing_table && store.user.use_full_context && (
          <div className="mt-1 mb-2 flex items-center">
            <StopIcon className="shrink-0 w-4 h-4 text-gray-500 mr-2" />
            <p className="text-sm text-gray-400">
              Full context model calls for frontier models can be expensive.
              Please make sure you understand the{' '}
              <a
                href="https://docs.vello.ai/models?#flex-pricing"
                target="_blank"
              >
                costs
              </a>
              .
            </p>
          </div>
        )}
        <div className="mt-1 flex items-center">
          <StackIcon className="shrink-0 w-4 h-4 text-gray-500 mr-2" />
          <p className="text-sm text-gray-400">
            <a
              className="cursor-pointer text-slate-400 font-medium"
              target="_blank"
              href="https://docs.vello.ai/plans#faq"
            >
              ⋆ See FAQ
            </a>{' '}
            ·&nbsp;
            {!store.user.show.pricing_table ? (
              <a
                onClick={() => {
                  store.unshowAll()
                  store.toggle('pricing_table')
                }}
                className="cursor-pointer text-slate-400 font-medium"
              >
                {' '}
                See All Plans ·&nbsp;
              </a>
            ) : null}
            For per-seat pricing, white label, self-hosting options, or
            questions about our plans please
            <a
              className="cool-link text-gray-500 ml-1"
              href="mailto:hello@vello.ai"
            >
              contact us
            </a>
            .
          </p>
        </div>
        {false && (
          <div className="my-2">
            <p className="text-sm text-gray-500">
              Vello reserves the right to cancel subscriptions and throttle
              abusive misuse of our products and systems.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

// function CalendarIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
//       <line x1="16" x2="16" y1="2" y2="6" />
//       <line x1="8" x2="8" y1="2" y2="6" />
//       <line x1="3" x2="21" y1="10" y2="10" />
//     </svg>
//   )
// }

function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  )
}

function GiftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 12 20 22 4 22 4 12" />
      <rect width="20" height="5" x="2" y="7" />
      <line x1="12" x2="12" y1="22" y2="7" />
      <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z" />
      <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z" />
    </svg>
  )
}

function HeartHandshakeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
      <path d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66" />
      <path d="m18 15-2-2" />
      <path d="m15 18-2-2" />
    </svg>
  )
}

function MailIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  )
}

function StickyNoteIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.5 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V8.5L15.5 3Z" />
      <path d="M15 3v6h6" />
    </svg>
  )
}

class Chat extends VComponent {
  end = createRef<any>()
  form = createRef<any>()

  componentDidMount() {
    store.watch(
      (store) => [
        store.room?.id,
        store.user_current_message,
        store.user.dense_mode,
        store.plan_always,
      ],
      (a, meta) => {
        if (
          a.name == 'toggle' ||
          a.name == 'setNoteContents' ||
          a.name.includes('RoomModels') ||
          Sync.session.share_id
        )
          return

        scrollToBottom()
      },
      'chat_scroll',
    )
    store.trigger(async (action, meta) => {
      if (action.name == 'addMessage') later(() => scrollToBottom())
    }, 'chat_scroll')
    if (!store.user.anonymous)
      //@ts-ignore
      Canny('initChangelog', {
        appID: '658304b99e1c61b746279807',
        position: 'top',
        align: 'left',
        theme: store.user.dark_mode ? 'dark' : 'light', // options: light [default], dark, auto
      })
    if (!store.in_share) scrollToBottom()
  }

  componentWillUnmount() {
    store.unwatch('chat_scroll')
    store.untrigger('chat_scroll')
  }

  componentDidUpdate() {
    if (/*store.is_mobile &&*/ scroll_amount('chat_thread') < 120) {
      scrollToBottom()
    }
    //@ts-ignore
    // Canny('initChangelog', {
    //   appID: '658304b99e1c61b746279807',
    //   position: 'top',
    //   align: 'left',
    //   theme: store.user.dark_mode ? 'dark' : 'light', // options: light [default], dark, auto
    // });
  }

  render() {
    if (!store.user) return null
    if (!store.room) {
      return (
        <div className="bg-white p-10 justify-center w-full h-full text-slate-400">
          Press{' '}
          <kbd className="cursor-pointer" onClick={() => store.addRoom(true)}>
            Ctrl M
          </kbd>{' '}
          to start a new chat.
        </div>
      )
    }
    var messages = store.room_messages()
    var j = messages.length - 1
    var height = 0
    // we want to prerender 1500px of messages the end of the chat
    while (j > 0 && height < 1500) {
      height += (store.message_content_text(messages[j]).length / 80) * 50
      j--
    }
    let messageContent = (
      <div>
        <Fancybox>
          <div>
            {messages.length ? (
              messages.length > 10 ? (
                messages.map((m, i) =>
                  i < j ? (
                    <RenderIfVisible
                      visibleOffset={2000}
                      defaultHeight={
                        (store.message_content_text(m).length / 80) * 50
                      }
                      stayRendered={true}
                      key={m.id}
                    >
                      <Message
                        key={m.id}
                        m={m}
                        current={m.id == store.user_current_message}
                        dense_mode={store.user.dense_mode}
                        plan={store.user.secure?.plan}
                      />
                    </RenderIfVisible>
                  ) : (
                    <Message
                      key={m.id}
                      m={m}
                      current={m.id == store.user_current_message}
                      dense_mode={store.user.dense_mode}
                      plan={store.user.secure?.plan}
                    />
                  ),
                )
              ) : (
                messages.map((m) => (
                  <Message
                    key={m.id}
                    m={m}
                    current={m.id == store.user_current_message}
                    dense_mode={store.user.dense_mode}
                    plan={store.user.secure?.plan}
                  />
                ))
              )
            ) : (
              <div className="overflow-hidden select-none grid place-items-center text-gray-300">
                <div className="w-full md:px-8 mt-10">
                  <ModelSettings model={store.model} save={true} />
                </div>
                <div className="p-2 md:p-10 h-full" />

                <div className="shrink-0 place-items-center">
                  <motion.img
                    initial={{ opacity: 0, y: '0%' }} // Start below the view
                    animate={{ opacity: 1, y: 0 }} // End at its natural position
                    transition={{ duration: 0.8, ease: 'easeOut' }} // Control the speed & easing
                    id="logo"
                    src={logoUrl}
                    className="select-none hand_logo shrink-0 md:w-[100px] w-[60px]"
                  />
                  <div className="w-full text-center my-8">
                    <span
                      onClick={() => store.toggle('bar', true)}
                      className="flex shrink justify-center hover:shadow-lg cursor-pointer border border-slate-100 mx-1 py-1 p-1 px-2 rounded-2xl shadow"
                    >
                      {KEY_MOD} K
                    </span>
                    {!store.user.anonymous && (
                      <div className="text-center my-8">
                        <span
                          data-canny-changelog
                          // onClick={() => store.toggle('help', true)}
                          className="flex shrink justify-center hover:shadow-lg cursor-pointer border border-slate-100 mx-1 py-2 p-1 px-2 rounded-2xl shadow"
                        >
                          <EnvelopeClosedIcon />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {store.room_typers().map((u) => (
              <Message
                key={u.id}
                typing
                m={{
                  owner_id: u.id,
                  content: 'is typing...',
                  create_time: Date.now(),
                }}
                plan={store.user.secure?.plan}
              />
            ))}

            <div className="pb-28" />
            <ScrollDownButton />
            <div ref={this.end} />
            <div id="end" />
          </div>
        </Fancybox>
      </div>
    )

    let messageWrapped = false ? (
      <div>{messageContent} </div>
    ) : (
      <div id="chat_thread" className="overflow-y-auto grow scroll-auto">
        {messageContent}
      </div>
    )
    if (store.user.deep_mode) {
      return (
        <PanelGroup
          direction={
            store.user.deep_mode_horizontal ? 'horizontal' : 'vertical'
          }
        >
          <Panel defaultSize={80} collapsible={true}>
            <div
              id="chat_thread_parent"
              className="bg-white overflow-hidden h-full w-full flex flex-col grow relative"
            >
              {messageWrapped}
            </div>
          </Panel>
          <PanelResizeHandle
            className={
              store.user.deep_mode_horizontal
                ? 'ResizeHandle'
                : 'ResizeHandleVertical'
            }
          />
          <Panel defaultSize={20} collapsible={true}>
            <MessageEditor placeholder={store.message_placeholder} />
          </Panel>
        </PanelGroup>
      )
    } else {
      var user_message = store.user_message()
      return (
        <div
          id="chat_thread_parent"
          className="bg-white overflow-hidden h-full w-full flex flex-col grow relative"
        >
          {messageWrapped}
          {!store.in_share && (
            <MessageArea
              top={
                !store.check().ok ? (
                  <motion.div
                    key="warning_msg"
                    initial={{ opacity: 0, y: '100%' }} // Start below the view
                    animate={{ opacity: 0.8, y: 0 }} // End at its natural position
                    exit={{ y: '100%' }} // Optional: animate out
                    transition={{ delay: 0.6, duration: 0.5, ease: 'easeOut' }} // Control the speed & easing
                    className={cn(
                      'z-1 bg-orange-100 shadow text-orange-400 font-medium p-2 rounded-t-lg border-l-4 border-orange-200 m-0 w-full',
                      { 'mr-8': store.is_mobile },
                    )}
                  >
                    <a
                      onClick={() => {
                        store.unshowAll()
                        store.toggle(user_message.toggle)
                      }}
                      className="cursor-pointer"
                    >
                      <div className="select-none flex items-center">
                        <LapTimerIcon className="shrink-0 w-4 h-4 text-orange-400 mr-2" />
                        <ReactMarkdown components={MarkdownComponents}>
                          {user_message?.content}
                        </ReactMarkdown>
                        <DoubleArrowUpIcon className="shrink-0 ml-2" />
                      </div>
                    </a>
                  </motion.div>
                ) : (
                  store.room_messages().length == 0 && (
                    <motion.div
                      key={
                        'intro_msg_' +
                        store.room_model() +
                        store.room_models().length
                      }
                      initial={{ opacity: 0, y: '100%' }} // Start below the view
                      animate={{ opacity: 0.7, y: 0 }} // End at its natural position
                      exit={{ y: '100%' }} // Optional: animate out
                      transition={{
                        delay: 0.15,
                        duration: 0.6,
                        ease: 'easeOut',
                      }} // Control the speed & easing
                      className={cn(
                        'z-1 shadow select-none bg-yellow-50 text-stone-700 font-normal p-2 rounded-t-lg border-t-0 border-l-4 border-yellow-300 m-0 w-full',
                        { 'mr-8': store.is_mobile },
                      )}
                    >
                      {store.room_model() && store.room_models().length == 1 ? (
                        <div className="max-w-4xl pt-1 pr-2 text-left align-center">
                          <b>{store.users[store.room_model()]?.name}</b>:{' '}
                          {store.users[store.room_model()]?.description ||
                            store.users[store.room_model()]?.intro ||
                            store.users[store.room_model()]?.instructions}
                        </div>
                      ) : store.room_models().length == 0 ? (
                        <div>
                          No AI selected — this is a retro a chat room, for
                          humans. Invite others to this space via email in the
                          settings.
                        </div>
                      ) : (
                        <div>
                          Chatting with {store.room_models().length} AI models.
                        </div>
                      )}
                    </motion.div>
                  )
                )
              }
              hide_settings={this.props.hide_settings}
              callback={scrollToBottom}
            />
          )}
        </div>
      )
    }
  }
}

export function MessageInput({
  textAreaCallback,
  sendMessage,
  placeholder,
  left,
  top,
  right,
}: any) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [mentionsOpen, setMentionsOpen] = useState(false)
  const [commandValue, setCommandValue] = useState('')

  const handleBlur = useCallback((e: Event) => {
    const dropdown = dropdownRef.current
    if (dropdown) {
      dropdown.classList.add('hidden')
      setMentionsOpen(false)
      setCommandValue('')
    }
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const textarea = textAreaRef.current
      const input = inputRef.current
      const dropdown = dropdownRef.current
      if (textarea && input && dropdown) {
        const currentWord = getCurrentWord(textarea)
        if (currentWord.startsWith('@') && mentionsOpen) {
          if (
            e.key === 'ArrowUp' ||
            e.keyCode === 38 ||
            e.key === 'ArrowDown' ||
            e.keyCode === 40 ||
            e.key === 'Enter' ||
            e.keyCode === 13
          ) {
            e.preventDefault()
            input.dispatchEvent(new KeyboardEvent('keydown', e))
            // } else if (e.key === 'Tab' || e.keyCode === 9) {
            //   e.preventDefault()
            // if I have the currently selected model this would work
            // onCommandSelect(commandValue)
            // var newEvent = new KeyboardEvent('keydown', { key: 'Enter' })
            // console.log('newEvent:', newEvent)
            // input.dispatchEvent(newEvent)
          } else if (e.key === 'Escape' || e.keyCode === 27) {
            handleBlur(e)
          }
        } else if (
          //@ts-ignore
          /* (store.user?.verbose_mode && e.altKey) || */ !store.user
            ?.verbose_mode &&
          !e.shiftKey &&
          e.code == 'Enter' &&
          !mentionsOpen
        ) {
          sendMessage(e)
        }
      }
    },
    [mentionsOpen],
  )

  const onTextValueChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const text = e.target.value
      const textarea = textAreaRef.current
      const dropdown = dropdownRef.current
      const buffer = 10

      store.setCurrentMessage(text)

      if (textarea && dropdown) {
        const caret = getCaretCoordinates(textarea, textarea.selectionEnd)
        const currentWord = getCurrentWord(textarea)
        if (currentWord.startsWith('@')) {
          setCommandValue(currentWord)
          dropdown.classList.remove('hidden')
          setMentionsOpen(true)
          later(() => {
            dropdown.style.left =
              Math.min(
                caret.left + buffer,
                textarea?.getBoundingClientRect().width -
                  dropdown.getBoundingClientRect().width -
                  buffer,
              ) + 'px'
            dropdown.style.top = caret.top + caret.height - buffer + 'px'
          })
        } else {
          // REMINDER: apparently, we need it when deleting
          if (commandValue !== '') {
            setCommandValue('')
            dropdown.classList.add('hidden')
            setMentionsOpen(false)
          }
        }
      }
    },
    [commandValue],
  )

  const onCommandSelect = useCallback((value: string) => {
    const textarea = textAreaRef.current
    const dropdown = dropdownRef.current
    if (textarea && dropdown) {
      replaceWord(textarea, `${value}`)
      setCommandValue('')
      setMentionsOpen(false)
      dropdown.classList.add('hidden')
    }
  }, [])

  const handleMouseDown = useCallback((e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleSectionChange = useCallback(
    (e: Event) => {
      const textarea = textAreaRef.current
      const dropdown = dropdownRef.current
      if (textarea && dropdown) {
        const currentWord = getCurrentWord(textarea)
        if (!currentWord.startsWith('@') && commandValue !== '') {
          setCommandValue('')
          setMentionsOpen(false)
          dropdown.classList.add('hidden')
        }
      }
    },
    [commandValue],
  )

  useEffect(() => {
    const textarea = textAreaRef.current
    const dropdown = dropdownRef.current
    textarea?.addEventListener('keydown', handleKeyDown)
    textarea?.addEventListener('blur', handleBlur)
    document?.addEventListener('selectionchange', handleSectionChange)
    dropdown?.addEventListener('mousedown', handleMouseDown)
    return () => {
      textarea?.removeEventListener('keydown', handleKeyDown)
      textarea?.removeEventListener('blur', handleBlur)
      document?.removeEventListener('selectionchange', handleSectionChange)
      dropdown?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleBlur, handleKeyDown, handleMouseDown, handleSectionChange])

  //@ts-ignore
  const handleRef = useCallback((ref) => {
    textAreaCallback(ref)
    textAreaRef.current = ref
  })

  return (
    // <div className="w-full flex flex-col ml-2 mr-1">
    <div className="w-full mr-2">
      <Command
        ref={dropdownRef}
        className={cn(
          'z-30 relative max-w-min rounded-md whitespace-wrap bg-popover hidden h-auto overflow-y-auto hide-scrollbar border border-popover text-popover-foreground shadow-md outline-none animate-in',
          // mentionsOpen ? 'border border-popover' : ''
        )}
      >
        <div className="hidden">
          <CommandInput ref={inputRef} value={commandValue} />
        </div>
        {mentionsOpen && store.active_users.length > 0 ? (
          <CommandGroup
            className={`h-full overflow-y-auto min-w-[300px] max-h-[500px]`}
          >
            {store.active_users
              .filter((u) => u.id != store.user.id)
              .map((user) => {
                const name = nameToMention(store.user_name(user.id))
                return (
                  <CommandItem
                    key={name}
                    value={name}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={() => onCommandSelect(name + ' ')}
                    className={'cursor-pointer'}
                  >
                    <div className="py-2 flex flex-col">
                      <div className="flex items-center">
                        <Ava className="shrink-0 mr-2" pill user={user} />
                        <div>{store.user_name(user.id)}</div>
                      </div>
                      <div className="text-slate-300">
                        {(
                          user.description ||
                          user.instructions ||
                          user.email
                        )?.trunc(200)}
                      </div>
                    </div>
                  </CommandItem>
                )
              })}
          </CommandGroup>
        ) : null}
      </Command>
      <div className="flex flex-col w-full h-full my-2 m-0 mr-0 max-w-4xl mb-4 px-2">
        <div className="flex items-end stretch mx-auto max-w-4xl w-full">
          <div className="flex mb-3">{left}</div>
          <div className="flex flex-col max-w-4xl w-full ">
            <div className="mx-1">{top}</div>
            <Textarea
              //@ts-ignore
              ref={handleRef}
              value={store.getCurrentMessage()}
              autoComplete="off"
              autoCorrect="off"
              className="z-10 placeholder:text-[#bbb] select-none pointer-events-auto shadow-lg bg-white text-md max-h-96 my-5 m-0 mr-0 max-w-4xl p-4 pr-10"
              onChange={onTextValueChange}
              rows={1}
              placeholder={
                placeholder ? placeholder : store.message_placeholder
              }
            />
          </div>
          <div
            className={cn('z-20 flex', {
              'mb-2': !store.getCurrentMessage(),
              'mb-3': !!store.getCurrentMessage(),
            })}
          >
            {right}
          </div>
        </div>
      </div>
    </div>
  )
}

{
  /* <UserSelect
                autoselect
                escCallback={(e) => store.toggle('user_edit', false)}
                onUnselect={(uid) => {
                  console.log(`uid`, uid)
                  if (store.users[uid]?.ai) store.removeRoomModels([uid])
                  else store.removeRoomMembers([uid])
                }}
                onSelect={(uid) => {
                  if (store.users[uid]) store.addRoomModels([uid])
                  else store.addRoomMembers([uid])
                }}
                selected={store
                  .in_room()
                  .map((u) => u.id)
                  .filter((id) => id != store.user.id)}
                selectables={store.others.filter(
                  (u) => u.id != 'ai' && !u.anonymous
                )}
              /> */
}

function SendMessage({ pad = true, callback = null, ...props }) {
  return (
    <Tip
      content={
        store.user?.verbose_mode || store.user?.deep_mode ? (
          <div className="flex flex-col space-y-2">
            <div>
              Send Message <kbd>{OPTION_MOD} Enter</kbd>
            </div>
            <div>
              New Line <kbd>Enter</kbd>
            </div>
            <div>
              Focus Input <kbd>{KEY_MOD} I</kbd>
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-2">
            <div>
              Send Message <kbd>Enter</kbd>
            </div>
            <div>
              New Line <kbd>⇧ Enter</kbd>
            </div>
            <div>
              Focus Input <kbd>{KEY_MOD} I</kbd>
            </div>
          </div>
        )
      }
    >
      <ConfirmButton
        icon={<PaperPlaneIcon />}
        className={
          pad ? '-ml-10' : 'pt-0' + ` text-slate-400 ${props.className}`
        }
        onClick={(e) => {
          e.preventDefault()
          callback
            ? callback()
            : () => {
                store.sendMessage()
                scrollToBottom()
              }
        }}
      />
    </Tip>
  )
}
class MessageArea extends VComponent {
  input = createRef<any>()

  componentDidMount() {
    store.watch(
      (store) => [
        store.room?.id,
        store.user_current_message,
        store.user?.dense_mode,
        store.user?.deep_mode,
      ],
      (a, meta) => {
        later(() => {
          if (!meta.external && !isMobile && !store.user?.show?.user_edit) {
            if (
              [
                'switchRoom',
                'duplicateRoom',
                'addRoom',
                'setProfile',
                'setFocused',
                'toggleProp', // writing mode
                '@initialize',
              ].includes(a.name)
            ) {
              Keys.input.current?.focus()
            }
            if (['switchRoom', 'nav', 'rewriteLastMessage'].includes(a.name))
              this.input.current?.select()
          }
        }, 500)
      },
      'focus',
    )
    Keys.input = this.input
  }

  renderBeforeInput() {
    return (
      <>
        {this.props.hide_settings ? (
          <ConfirmButton
            icon={<MixerHorizontalIcon />}
            className="md:ml-2 ml-1 mr-0 text-slate-400"
            confirmIcon="same"
            onClick={(e) => {
              e.preventDefault()
              store.toggle('sidebar_shown')
            }}
          />
        ) : (
          // <Tip content={<div>Chat Settings</div>}>
          store.persona && (
            <ConfirmButton
              icon={<MixerHorizontalIcon />}
              className="md:ml-2 ml-1 mr-0 text-slate-400"
              confirmIcon="same"
              onClick={(e) => {
                e.preventDefault()
                store.toggle('_show_settings')
              }}
            />
          )
          // </Tip>
        )}
        {store
          .active_models(store.room?.id)
          .filter((m) => m.assistant || is_vision_model(m.id)).length ? (
          <ConfirmButton
            onClick={(e) => {
              e.preventDefault()
              store.toggle('_file_drawer')
            }}
            icon={
              <div className="flex">
                <FileTextIcon className="shrink-0 ml-0 text-slate-400" />
                {store.room_next_files(store.room).length ? (
                  <Badge className="shrink-0 rounded-full m-0 -mt-2 -ml-2 p-1 h-4">
                    {store.room_next_files(store.room).length}
                  </Badge>
                ) : null}
              </div>
            }
          />
        ) : null}
      </>
    )
  }

  renderAfterInput() {
    return (
      <>
        {store.room.streaming ? (
          <Tip
            content={
              <div>
                Stop Response | <kbd>Ctrl S</kbd>
              </div>
            }
          >
            <Button
              onClick={(e) => {
                e.preventDefault()
                store.stopStreaming()
              }}
              variant="ghost"
              className="-ml-14"
            >
              <StopIcon className="text-rose-400" />
            </Button>
          </Tip>
        ) : store.getCurrentMessage() ? (
          <SendMessage callback={this.sendMessage.bind(this)} />
        ) : store.is_ai(store.last_message) ? (
          <RegenerateMessage />
        ) : (
          <ToggleWritingMode />
        )}
        {!this.props.hide_settings && store.is_mobile && (
          <>
            <ConfirmButton
              onClick={(e) => {
                e.preventDefault()
                store.toggle('_room_drawer')
              }}
              icon={<PiChatsLight className="ml-0 text-slate-400" />}
            />
            <ConfirmButton
              icon={store.user.anonymous ? <PersonIcon /> : <StackIcon />}
              className="md:ml-2 ml-1 mr-0 text-slate-400"
              confirmIcon="same"
              onClick={(e) => {
                e.preventDefault()
                store.toggle('_show_settings')
              }}
            />
          </>
        )}
      </>
    )
  }

  sendMessage(e?) {
    e?.preventDefault()
    if (store.room.streaming) store.stopStreaming()
    const content = this.input.current.value
    if (content == '') return
    this.input.current.value = ''
    store.setCurrentMessage('')
    if (_global.os == 'ios') {
      this.input.current.blur()
    }
    this.props.callback && this.props.callback()
    later(() => {
      store.addMessage({ content })
    }, 0)
  }

  render() {
    return (
      <>
        <form
          className={cn(
            'pt-6 md:pr-3 pt-16 bottom-0 bg-gradient-to-t from-neutral-100 w-full',
            { absolute: !this.props.relative },
          )}
          // ref={this.form}
          onSubmit={this.sendMessage.bind(this)}
        >
          <div className="flex items-center stretch mx-auto max-w-4xl">
            <MessageInput
              left={this.renderBeforeInput()}
              right={this.renderAfterInput()}
              top={this.props.top}
              placeholder={this.props.placeholder}
              //@ts-ignore
              textAreaCallback={(ref) => (this.input.current = ref)}
              sendMessage={this.sendMessage.bind(this)}
            />
          </div>
        </form>
      </>
    )
  }
}

function ToggleWritingMode({ pad = true, ...props }) {
  return (
    <Tip
      content={
        <div>
          Toggle Writing Mode <kbd>{OPTION_MOD} W</kbd>
        </div>
      }
    >
      <Button
        onClick={(e) => {
          e.preventDefault()
          store.toggleProp('deep_mode')
        }}
        variant="ghost"
        className={pad ? '-ml-14' : 'pt-0' + ` ${props.className}`}
      >
        {store.user.deep_mode ? (
          <ExitFullScreenIcon className="text-slate-400" />
        ) : (
          <EnterFullScreenIcon className="text-slate-400" />
        )}
      </Button>
    </Tip>
  )
}
function RegenerateMessage({ pad = true }) {
  return (
    <Tip
      content={
        <div>
          Toggle Writing Mode <kbd>{OPTION_MOD} W</kbd>
        </div>
      }
    >
      <Button
        onClick={(e) => {
          e.preventDefault()
          store.regenResponse()
        }}
        variant="ghost"
        className={pad ? '-ml-14' : 'pt-0'}
      >
        <UpdateIcon className="text-slate-400" />
      </Button>
    </Tip>
  )
}

function NotesToggle({ left = false }) {
  return (
    <div className={left ? 'z-20 absolute top-0 left-0' : 'px-1'}>
      <Tip
        content={
          <div>
            Toggle Notes <kbd>Ctrl ]</kbd>
          </div>
        }
      >
        <Button
          className={
            store.is_mobile && store.note?.length > 0
              ? 'bg-yellow-200 text-slate-700'
              : 'text-slate-300'
          }
          onClick={() => {
            store.collapseRight(store.user.layout?.[2] !== 0)
          }}
          variant="ghost"
        >
          {store.is_mobile &&
            (store.user?.show?.notes_open ? (
              <ChatBubbleIcon />
            ) : (
              <FileTextIcon />
            ))}
          {!store.is_mobile &&
            (store.user.layout?.[2] === 0 ? <PinLeftIcon /> : <PinRightIcon />)}
        </Button>
      </Tip>
    </div>
  )
}

function Tip({ children, content, ...props }) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={350}>
        <TooltipTrigger asChild>
          <div>{children}</div>
        </TooltipTrigger>
        <TooltipContent {...props}>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export function Ava({
  user,
  pill = false,
  is_ai = null,
  width = 8,
  height = null,
  pulsate = false,
  selected = false,
  is_team = false,
  endicon = null,
  ...props
}) {
  var height = height ?? width
  if (pill) {
    width = 9
    height = 4
  }
  if (is_ai === null) {
    is_ai = store.is_ai(user)
  }
  if (!user) return null
  var is_you = user?.id == store.user?.id
  selected = is_ai && user.assistant
  let fallback = (
    <AvatarFallback
      style={{
        backgroundColor: is_ai
          ? 'bg-slate-600'
          : is_team
            ? getTeamColor(user.id)
            : user.anonymous
              ? getAnonColor(user.id)
              : getAuthedColor(user.id),
      }}
      className={cn({
        'bg-slate-500': is_ai && user.builtin,
        'bg-slate-800': is_ai && !user.builtin,
        'tracking-tighter': is_ai,
        'bg-teal-500': pulsate,
      })}
    >
      {user.avatar_url && !user.builtin ? (
        <img className="w-9" src={user.avatar_url} />
      ) : is_team ? (
        <StackIcon />
      ) : is_you ? (
        'YOU'
      ) : props.full_name ? (
        user.name
      ) : (
        (user.anonymous ? 'Anon' : user.name || user.email)
          ?.split(' ')
          .map((n) => n[0])
          .join('')
          ?.replace(' ', '')
          .slice(0, user.name?.startsWith('GPT') ? 4 : 3)
          .toUpperCase()
      )}
      {endicon}
    </AvatarFallback>
  )
  return (
    <span {...props}>
      <Tip content={store.user_name(user.id)}>
        <Avatar
          key={user.id}
          onClick={() => {
            !is_team && store.editModel(user.id)
          }}
          className={cn(
            `cursor-pointer shrink-0 select-none w-${width} h-${height} shadow text-xs text-white font-medium w-9`,
            {
              'w-12': endicon,
              shadow: !pulsate,
              // 'ring-2': selected,
              // 'ring-slate-400': selected,
              // 'ring-offset-2': selected,
            },
          )}
        >
          {!pulsate ? (
            fallback
          ) : (
            <motion.div
              className="relative w-full flex shrink-0"
              animate={{
                opacity: [0.7, 1, 0.7],
                scale: [1, 1.3, 1],
                rotate: [0, 9, -9, 0],
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.7, // duration in seconds. Adjust as needed
                repeat: Infinity, // repeat animation indefinitely
              }}
            >
              {fallback}
            </motion.div>
          )}
        </Avatar>
      </Tip>
    </span>
  )
}

const Message = React.memo(function Message({
  m,
  current,
  dense_mode,
  typing,
  plan,
}: any) {
  if (!m) return null
  if (store.message_content_text(m).startsWith('::done::')) return null
  let is_ai = store.is_ai(m)
  let has_ai = store.in_room(m.room_id).some((m) => store.is_ai(m))
  let is_you = store.user.id === m.owner_id

  let content = store.message_content(m)
  let content_text = store.message_content_text(m)

  //move these transforms to the store instead of view here:
  if (m.owner_id == 'doc' || getSubdomain(location.host) == 'heated') {
    content_text = (content_text + '$$$')
      .replace(
        /::(?!endadd|add)(move|remove)(.*)::/g,
        '<details><summary>✏️  $1</summary>$1$2</details>',
      )
      .replace(/::(?!endadd|add)(tool_call|photo)(.*)/g, '📷 sending..')

      .replace(
        /::(endadd|add)(.*?)::((?:\n|.)*?)(?:::endadd::|\$\$\$)/gm,
        '<details><summary>✏️  writing.</summary>\n\n$1$2\n$3</details>',
      )
      .replace('::done::', '✅ done.')
    content_text = content_text.replace('$$$', '')
    content = [{ type: 'text', text: { value: content_text } }]
  }
  return (
    <Sentry.ErrorBoundary fallback={null}>
      <span>
        <motion.div
          animate={{
            opacity: current ? [1, 0.6, 1] : [],
            backgroundColor: current
              ? ['bg-gray-200', '#fefce8', 'bg-gray-200']
              : [],
          }}
          transition={{
            duration: 0.9, // duration in seconds. Adjust as needed
          }}
          id={m.id}
          className={cn('group p-3 mb-0', {
            'md:p-6': has_ai,
            'md:p-4': !has_ai,
            'md:p-1': store.user.dense_mode,
            'md:pt-2': store.user.dense_mode,
            // 'shadow-inner': !is_ai && is_you && has_ai,
            'bg-white': is_ai,
            'bg-gray-200': !is_ai,
            'bg-gray-50': !is_you && !is_ai,
          })}
        >
          <div className="flex flex-row space-x-3 place-items-start content-center mx-auto max-w-4xl md:px-5">
            <Ava
              pulsate={store.getIsStreaming(m.id)}
              is_ai={is_ai}
              pill={store.user.dense_mode}
              className={cn({ 'mt-1.5': store.user.dense_mode })}
              user={store.message_user(m)}
            />

            {m.files?.length ? (
              <div
                className="flex flex-row"
                onClick={(e) => {
                  e.preventDefault()
                  if (m.files.length == 1) {
                    store.setCurrentFile(store.room.id, m.files[0])
                  } else {
                    Commands.run('search_team_files')
                  }
                }}
              >
                <ConfirmButton
                  icon={<FileIcon className="ml-0 text-slate-300" />}
                />
                <Badge className="shrink-0 rounded-full m-0 -ml-2 p-1 h-4">
                  {m.files.length}
                </Badge>
              </div>
            ) : null}
            <div className="flex w-full">
              <div className="flex-col w-full">
                {m.steps?.map((step) =>
                  step.type == 'tool_calls' ? (
                    step.step_details.tool_calls.map((call) => (
                      <div className="mb-4 rounded p-2 ">
                        <div className="flex shrink-0 space-x-2 flex-row items-center">
                          {store.user_by_name(call.function?.name) ? (
                            <>
                              <BeatLoader
                                size={4}
                                speedMultiplier={0.5}
                                className="shrink-0 opacity-40"
                              />
                              <div className="text-sm font-medium">Asking </div>
                              <Ava
                                className="shrink-0 mr-2"
                                pill
                                user={store.user_by_name(call.function.name)}
                              />
                            </>
                          ) : (
                            <>
                              <BeatLoader
                                size={4}
                                speedMultiplier={0.5}
                                className="shrink-0 opacity-40"
                              />
                              <div className="text-sm font-medium shrink-0">
                                {call.type == 'code_interpreter'
                                  ? `Running Code "${call.code_interpreter.input.trunc(
                                      80,
                                    )}"`
                                  : call.type == 'retrieval'
                                    ? 'Reading Files'
                                    : toCapitalCase(
                                        call.function.name.replace('_', ' '),
                                      )}
                              </div>
                            </>
                          )}
                          {call.function && (
                            <div className="text-sm text-gray-500 ml-2">
                              "
                              {values(
                                parse(call.function?.arguments || '{}'),
                              ).at(0)}
                              "
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <div className="flex flex-row">
                            <div className="text-gray-500 ml-2">
                              {call.function?.type}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="rounded py-1 ">
                        <div className="flex space-x-2 flex-row items-center">
                          <BeatLoader
                            size={4}
                            speedMultiplier={0.5}
                            className="shrink-0 opacity-40"
                          />
                          <div className="text-sm font-medium">
                            Writing Response
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                )}
                {content.map((sm) => {
                  return (
                    <article
                      key={sm.id}
                      className={cn(
                        'message prose prose-slate break-words',
                        !is_ai && 'whitespace-pre-wrap',
                        {
                          'text-rose-300': m.error == true,
                          'text-slate-400': m.warning == true,
                          'select-none': m.hidden && !store.check().ok,
                          'blur-[1px]': m.hidden && !store.check().ok,
                          'blurred-div': m.hidden && !store.check().ok,
                        },
                      )}
                    >
                      {typing ||
                      (!m.steps?.length && m.streaming && !content_text) ? (
                        <BeatLoader
                          size={4}
                          speedMultiplier={0.5}
                          className="shrink-0 opacity-40"
                        />
                      ) : true ? (
                        <Sentry.ErrorBoundary
                          fallback={<div>{content_text}</div>}
                        >
                          {!is_ai && content_text.length > 2000 ? (
                            <div>{content_text}</div>
                          ) : sm.type == 'text' ? (
                            <ReactMarkdown
                              children={
                                !is_ai
                                  ? // DOMPurify.sanitize(
                                    store.transform(
                                      sm.text,
                                      `${import.meta.env.VITE_API_URI}/file`,
                                    )
                                  : // )
                                    store.transform(
                                      sm.text,
                                      `${import.meta.env.VITE_API_URI}/file`,
                                    )
                              }
                              //@ts-ignore
                              rehypePlugins={
                                is_ai ? [rehypeRaw, rehypeKatex] : [rehypeKatex]
                              }
                              remarkPlugins={[
                                remarkGfm,
                                [remarkMath, { singleDollarTextMath: false }],
                                [
                                  remarkMentions,
                                  {
                                    /*usernameLink: (u) => `/user/${u}`*/
                                  },
                                ],
                              ]}
                              components={MarkdownComponents}
                            />
                          ) : (
                            <a
                              data-fancybox="gallery"
                              href={
                                sm.image_url?.url ??
                                sm.image_url ??
                                get_file_url(sm.image_file.file_id)
                              }
                            >
                              <img
                                className="shadow-lg mb-4 p-1 rounded"
                                src={
                                  sm.image_url?.url ??
                                  sm.image_url ??
                                  get_file_url(sm.image_file.file_id)
                                }
                              />
                            </a>
                          )}
                        </Sentry.ErrorBoundary>
                      ) : !typing ? (
                        content_text
                      ) : (
                        <BeatLoader
                          size={4}
                          speedMultiplier={0.5}
                          className="shrink-0 opacity-40"
                        />
                      )}
                      {/* {(m.error && (store.last_message == m)) && (<a onClick={() => store.regenResponse()} className="text-rose-400 cursor-pointer !font-semibold">Try again</a>)} */}
                    </article>
                  )
                })}
              </div>
              <div className="grow" />
              <div className="flex-grow-0 flex-shrink-0 flex flex-col items-end">
                {!is_ai && (
                  <div className="min-w-auto text-xs text-slate-400">
                    {ago(m.create_time)}
                  </div>
                )}
                {!store.persona && is_ai && !m.hidden && (
                  <div className="min-w-auto">
                    <Tip content="Copy">
                      <CopyToClipboard text={content_text}>
                        <ConfirmButton
                          className={cn(
                            store.getIsStreaming(m.id) && 'invisible',
                          )}
                        />
                      </CopyToClipboard>
                    </Tip>
                  </div>
                )}
                {!store.persona && is_ai && !m.hidden && (
                  <div className="min-w-auto">
                    <Tip content="Bookmark">
                      <ConfirmButton
                        icon={
                          m.bookmarked ? (
                            <BookmarkFilledIcon />
                          ) : (
                            <BookmarkIcon />
                          )
                        }
                        onClick={() => store.toggleBookmarked(m.id)}
                      />
                    </Tip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </span>
    </Sentry.ErrorBoundary>
  )
})

const MarkdownComponents: object = {
  code({ node, inline, className, children, ...props }) {
    const hasLang = /language-(\w+)/.exec(className || '')
    const hasMeta = node?.data?.meta

    const applyHighlights: object = (applyHighlights: number) => {
      return {}
    }

    if (hasLang?.at(1) == 'command') {
      return (
        <Collapsible className="p-2">
          <CollapsibleTrigger asChild>
            <Button variant="ghost" size="sm">
              <Pencil1Icon />
              <ChevronDownIcon className="ml-2" />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>{children}</CollapsibleContent>
        </Collapsible>
      )
    }

    if (inline)
      return (
        <code {...props} className={className}>
          {children}
        </code>
      )

    return (
      //@ts-ignore
      <div className="group relative">
        <CopyToClipboard text={children}>
          <ConfirmButton className="m-1 overflow-none absolute right-0 hover:bg-neutral-800 " />
        </CopyToClipboard>
        <Code
          code={children[0]}
          language={hasLang ? hasLang[1] : 'javascript'}
        />
      </div>
    )
  },
  img({ src, ...props }) {
    return (
      <a data-fancybox="gallery" href={src}>
        <img src={src} {...props} />
      </a>
    )
  },
  a({ node, href, title, className, children, ...props }) {
    if (children[0]?.startsWith?.('link:')) {
      return (
        <a target="_blank" href={href}>
          {href}
        </a>
        // <ReactTinyLink
        //   cardSize="small"
        //   showGraphic={true}
        //   maxLine={2}
        //   minLine={1}
        //   // proxyUrl="https://vello.ai:8080/"
        //   url={href}
        // />
      )
    } else if (
      children[0]?.startsWith?.('@') &&
      store.mentioned_users(children[0]).at(0)
    ) {
      return (
        <Ava
          className="opacity-70 inline-block shrink-0"
          pill
          user={store.mentioned_users(children[0]).at(0)}
        />
      )
    } else {
      return (
        <a target="_blank" href={href}>
          {children}
        </a>
      )
    }
  },
}

const colors = [
  '#70CFF8',
  '#F98181',
  '#FBBC88',
  '#90f7c8',
  '#958DF1',
  '#e9e042',
  '#99F17B',
]

const anon_colors = [
  '#497391',
  '#DBC2CF',
  '#97a18D',
  // '#40bFa8',
  // '#aBcC88',
]

const team_colors = [
  '#497391',
  '#97a18D',
  '#40bFa8',
  '#aBcC88',
  '#c98181',
  '#9ac8D8',
  '#1aa054',
]
const getRandomElement = (list) => list[Math.floor(Math.random() * list.length)]
const getRandomColor = () => getRandomElement(colors)
const getAuthedColor = (id) =>
  colors[
    Math.abs(
      values(store.human_users)
        .filter((u) => !u.anonymous)
        .sort(latest)
        .map((u) => u.id || u)
        .indexOf(id),
    ) % colors.length
  ]

const getAnonColor = (id) =>
  anon_colors[
    Math.abs(
      values(store.human_users)
        .filter((u) => u.anonymous)
        .sort(latest)
        .map((u) => u.id || u)
        .indexOf(id),
    ) % colors.length
  ]

const getTeamColor = (id) => {
  return team_colors[
    Math.abs(
      values(store.teams)
        .map((u) => u.id || u)
        .indexOf(id),
    ) % team_colors.length
  ]
}

class FileViewer extends PComponent {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    store.watch(
      (store) => [store.current_file],
      () => {
        this.forceUpdate()
      },
      'file_viewer',
    )
  }

  componentWillUnmount() {
    store.unwatch('file_viewer')
  }

  handleTitleClick() {
    this.setState({ isEditing: true }, () => {
      setTimeout(() => {
        this.inputRef.current.focus()
      }, 0)
    })
  }

  render() {
    const file_id = store.current_file?.id
    if (!store.room && !file_id) {
      return (
        <div className="text-slate-400 mx-5 my-10">Select a file to view</div>
      )
    }
    return (
      <>
        {store.room && store.current_file?.type === 'file' && (
          <Button
            onClick={() => {
              if (store.room.note) {
                store.setCurrentFile(store.room.id, store.room.note)
              } else {
                store.setNoteContents('', store.room.id)
              }
            }}
            variant="ghost"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowLeftIcon />
            <span style={{ marginLeft: '8px' }}>Return to note</span>
          </Button>
        )}
        {store.current_file?.type === 'file' && (
          <div className="desktop-header-drag mx-2 w-full truncate text-center items-center justify-center text-gray-400 flex my-2">
            {this.state.isEditing ? (
              <input
                className="text-center truncate text-gray-400 w-full focus:outline-none focus:bg-transparent"
                type="text"
                ref={this.inputRef}
                value={getFileName(file_id ?? null)}
                onChange={(e) =>
                  store.setFileName(store.current_file.id, e.target.value)
                }
                onBlur={() => this.setState({ isEditing: false })}
              />
            ) : (
              <div
                onClick={this.handleTitleClick.bind(this)}
                className="pr-1 truncate"
              >
                {getFileName(file_id ?? null)}
              </div>
            )}
          </div>
        )}
        {store.current_file?.type === 'file' ? (
          <FilePreview />
        ) : (
          <div className="mt-2">
            <EditorContainer setEditor={this.props.setEditor} />
          </div>
        )}
      </>
    )
  }
}

class FilePreview extends PComponent {
  componentDidMount() {
    const headerBar = document.getElementById('header-bar')
    if (headerBar) {
      headerBar.style.display = 'none'
    }
    store.watch(
      (store) => [store.current_file],
      () => {
        this.forceUpdate()
      },
      'file_preview',
    )
  }

  componentWillUnmount() {
    store.unwatch('file_preview')
  }

  render() {
    const docs = [
      {
        uri: `${CDN_URL}/files/` + store.current_file.sid,
      },
    ]
    return (
      // probably possible to make your own document header that is less buggy and looks better
      // config -> header -> overrideComponent -> MainState and MainStateActions + reducer to perform actions
      <DocViewer
        documents={docs}
        initialActiveDocument={docs[0]}
        pluginRenderers={DocViewerRenderers}
        language="en"
        theme={{
          disableThemeScrollbar: true,
        }}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          csvDelimiter: ',',
          pdfZoom: {
            defaultZoom: 1.1,
            zoomJump: 0.3,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    )
  }
}

class EditorContainer extends VComponent {
  constructor(props) {
    super(props)
  }

  render() {
    //@ts-ignore
    return (
      <span>
        <Editor
          key={'editor'}
          //@ts-ignore
          className="max-w-4xl"
          {...this.props}
        />
        {false /*is_mobile */ ? <NotesToggle left /> : null}
      </span>
    )
  }
}

const Editor = ({ setEditor }) => {
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      // TextStyle.configure({ types: [ListItem.name] }),
      Placeholder.configure({
        placeholder:
          'Markdown notes for this chat… \n\n`Note Editor` model can see and edit this note with you.',
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      CodeBlockPrism.configure({
        defaultLanguage: 'jsx',
      }),
      // Collaboration.configure({
      //   fragment: Sync.provider.document.getXmlFragment(store.room?.id),
      // }),
      // CollaborationCursor.configure({
      //   provider: Sync.provider,
      //   user: {
      //     name:
      //       store.user?.email?.split('@')[0] ||
      //       'Anonymous ' + (Object.values(store.users).indexOf(store.user) + 1),
      //     color: getRandomColor(),
      //   },
      // }),
      Markdown.configure({
        html: true, // Allow HTML input/output
        tightLists: false, // No <p> inside <li> in markdown output
        tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
        bulletListMarker: '-', // <li> prefix in markdown output
        linkify: true, // Create links from "https://..." text
        breaks: true, // New lines (\n) in markdown input are converted to <br>
        // transformPastedText: true, // Allow to paste markdown text in the editor
        // transformCopiedText: true, // Copied text is transformed to markdown
      }),
    ],
    editorProps: {
      attributes: {
        class:
          'max-w-4xl prose prose-slate dark:prose-invert p-5 focus:outline-none',
      },
    },
  })

  //write to doc
  useEffect(() => {
    setEditor(editor)
    store.watch(
      (s) => [s.note, editor],
      (action) => {
        if (!editor) return
        store._set_editor = true
        if (!store._user_edit) editor?.commands.setContent(store.note)
        later(() => (store._set_editor = false))
      },
      'editor_room',
    )

    editor?.on('update', ({ editor }) => {
      store._user_edit = true
      // probably should go back to just rendering the editor off markdown but it was slow
      // many cases here, basically dont set doc if user is mutating, or exrternal render is happening
      if (
        !store._set_editor &&
        !store.state._ai_edit &&
        !store._in_action_render &&
        !store._in_external_render
      ) {
        store.setNoteContents(
          editor.storage.markdown.getMarkdown(),
          store.room?.id,
          store.current_file?.id,
        )
      }
    })
  }, [editor])

  return (
    <div className="h-full">
      <EditorContent className="h-full" editor={editor} />
    </div>
  )
}

const MessageEditor = ({ placeholder }) => {
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      // TextStyle.configure({ types: [ListItem.name] }),
      Placeholder.configure({
        placeholder:
          placeholder + `. \`${OPTION_MOD} + Enter\` to send message.`,
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      CodeBlockPrism.configure({
        defaultLanguage: 'jsx',
      }),
      Markdown.configure({
        html: true, // Allow HTML input/output
        tightLists: false, // No <p> inside <li> in markdown output
        tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
        bulletListMarker: '-', // <li> prefix in markdown output
        linkify: true, // Create links from "https://..." text
        breaks: true, // New lines (\n) in markdown input are converted to <br>
        // transformPastedText: true, // Allow to paste markdown text in the editor
        // transformCopiedText: true, // Copied text is transformed to markdown
      }),
    ],
    editorProps: {
      attributes: {
        class:
          'max-w-4xl prose prose-slate dark:prose-invert p-5 focus:outline-none',
      },
    },
  })

  //write to doc
  useEffect(() => {
    // setEditor(editor)
    Keys.editor = editor
    editor?.commands.setContent(store.getCurrentMessage())
    editor?.commands.focus()
    store.watch(
      (s) => s.getCurrentMessage(),
      (action) => {
        if (!editor) return
        store._set_editor = true
        if (!store._user_edit)
          editor?.commands.setContent(store.getCurrentMessage())
        later(() => (store._set_editor = false))
      },
      'editor_room_msg',
    )

    editor?.on('update', ({ editor }) => {
      store._user_edit = true
      // probably should go back to just rendering the editor off markdown but it was slow
      // many cases here, basically dont set doc if user is mutating, or exrternal render is happening
      if (
        !store._set_editor &&
        !store.state._ai_edit &&
        !store._in_action_render &&
        !store._in_external_render
      ) {
        store.setCurrentMessage(editor.storage.markdown.getMarkdown())
      }
      store._user_edit = false
    })
  }, [editor])

  return (
    <div className="overflow-auto h-full">
      <SendMessage
        pad={false}
        className="z-50 absolute right-0 bottom-0 mr-2 mb-1"
      />
      <ToggleWritingMode pad={false} className="z-50 absolute right-0 mt-1" />
      <EditorContent className="h-full" editor={editor} />
    </div>
  )
}

//this is slow
//https://github.com/pacocoursey/cmdk/pull/123
//export function CommandMenu() {
//  const [open, setOpen] = useState(false)

//  useEffect(() => {
//    const down = (e: KeyboardEvent) => {
//      if (e.key === '`' && e.ctrlKey) {
//        setOpen(true)
//      }
//    }
//    const up = (e: KeyboardEvent) => {
//      if (e.key == "Control") setOpen(false);
//    };
//    document.addEventListener('keydown', down)
//    document.addEventListener("keyup", up);
//    return () => {
//      document.removeEventListener('keydown', down)
//      document.removeEventListener("keyup", up);
//    }
//  }, [])

//  return (
//    <CommandDialog
//      open={open}
//      onOpenChange={setOpen}
//      // filter={(value, search) => {
//      //   if (store.room_messages(value)[0]?.content.includes(search)) return 1;
//      //   return 0;
//      // }}
//    >
//      <CommandInput placeholder="search..." />
//      <CommandList>
//        <CommandEmpty>No results found.</CommandEmpty>
//        <CommandGroup heading="Spaces">
//          {store.rooms_mru.slice(1).map((r) => (
//            <CommandItem
//              key={`room-${r.id}`}
//              onSelect={(value) => {
//                //note: don't use cmdk value.. because it lowercases
//                store.setCurrentRoom(r.id)
//                setOpen(false)
//              }}
//              value={store.room_title(r.title) + ' | ' + r.id}
//            >
//              <RoomItemLabel room={r} />
//            </CommandItem>
//          ))}
//        </CommandGroup>
//      </CommandList>
//    </CommandDialog>
//  )
//}

class AuthView extends VComponent {
  render() {
    return (
      <Auth
        key="auth_view"
        additionalData={{
          team_id: 'personal-' + nid(),
          team_name: 'Your Space',
          last_anon_id: store.user.anonymous ? store.user?.id : null,
        }}
        providers={['google']}
        supabaseClient={supabase}
        redirectTo="https://vello.ai/app"
        view={this.props.view ?? 'sign_up'}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: '#fff',
                brandButtonText: '#111',
                brandAccent: '#ddd',
              },
            },
          },
        }}
        {...this.props}
      />
    )
  }
}

class Login extends VComponent {
  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    store.watch(
      (store) => store.user?.id,
      () => this.forceUpdate(),
    )
  }

  render() {
    if (!store.user.id || store.user.anonymous) {
      return <AuthView {...this.props} />
    } else {
      return null
    }
  }
}
// import { useTheme } from 'next-themes'

function render_key_sequence(sequence) {
  return sequence?.split('+').map((key) => {
    return <kbd key={key}>{key_to_symbol(key)}</kbd>
  })
}

//memo but with external stuff too
function mem(component, fn = () => null) {
  // fancy memo that also checks if the fn() has changed
  // last is keyed by the previous props to be instance specific
  //this doesn't work..
  let last = new Map()
  return React.memo(component, (prev, next) => {
    if (!shallowEqual(next, prev)) return false
    let cur = fn()
    let last_tmp = last.get(prev) ?? 'none'
    last.set(cur, cur)
    last.delete(prev)
    if (!shallowEqual(cur, last_tmp)) return false
    return true
  })
}

export const CMDK = mem(
  function CMDK({ nofocus }) {
    const [inp, setInput] = React.useState(null)
    const input = useRef(null)
    var cmds = Commands.current
    var single
    //gross stuff, should just separate these cases below, list vs single
    var parent = values(cmds) ? Commands.all[values(cmds)[0].parent] : null
    if (values(cmds).length == 1 && Commands.selected?.type != 'list') {
      single = true
      cmds = values(cmds)[0]
    }
    // context:
    // if cmds is  array, then render the list of commands
    // if its a single node, then render the context above, and then take the inp as
    // a node that looks like:
    //   {
    //   on: () => !Sync.session?.share_id,
    //   context: () => `Chat - ${store.room_title(store.room.id)}`,
    //   load: () => store.room.title,
    //   save: (name) => store.renameRoom(name),
    // }

    useEffect(() => {
      if (!nofocus) input.current?.focus()
    })

    useEffect(() => {
      if (nofocus) input.current?.blur()
    }, [])

    store.watch(
      (store) => store.user?.show?.bar,
      () => {
        if (inp) setInput(null)
      },
      'command_bar',
    )

    return (
      <div className="linear rounded-[20px]">
        <div className="bg-white max-w-[700px] rounded-[20px]">
          <Command
            className="rounded-[20px]"
            onKeyDown={(e) => {
              if (e.code == 'Enter') {
                if (single) {
                  //TODO: this is the biggest wart.
                  //move towards tree of commands instead of calling "save" just have child commands
                  //handle the save
                  cmds.save(inp)
                  store.set('_sel_cmd', null)
                  store.toggle('bar', false)
                  store.toggle('desktop_bar', false)
                } else if (inp?.length > 9) {
                  store.addRoom(true)
                  store.addMessage({ content: inp })
                  store.toggle('bar', false)
                  store.toggle('desktop_bar', false)
                }
              }
              if (e.code == 'Escape') {
                /*
                if (single || parent) {
                  setInput(null)
                  //doesn't support multilevel
                  store.set('_sel_cmd', null)
                  e.stopPropagation()
                  e.preventDefault()
                } else {
                */
                store.unshowAll()
                // }
              }
            }}
          >
            {((single && cmds.context) || parent?.context) && (
              <div cmdk-linear-badge="">
                <span className="font-medium">
                  {parent?.context?.() ?? cmds.context?.()}
                </span>
              </div>
            )}
            <Command.Input
              autoFocus
              ref={input}
              defaultValue={single ? cmds.name : null}
              value={inp ?? (!cmds.load ? inp : cmds.load()) ?? ''}
              onValueChange={setInput}
              placeholder={
                single
                  ? cmds.name
                  : (parent?.placeholder ?? parent)
                    ? 'Select an option...'
                    : 'Type a command, search, or start a new chat...'
              }
            />
            {!single && (
              <Command.List>
                <Command.Empty>
                  <ChatBubbleIcon /> Talk to {store.ai?.name}.
                </Command.Empty>
                {entries(cmds)
                  //@ts-ignore
                  .filter(([_, { name }]) => name)
                  //@ts-ignore
                  .map(([id, { icon, name, sequence }]) => {
                    return (
                      <Command.Item
                        onSelect={async (_) => {
                          setInput(null)
                          await Commands.run(id)
                          // store.toggle('bar', false);
                        }}
                        key={id}
                        value={name + ' | ' + id}
                      >
                        {icon}
                        {name}
                        <div cmdk-linear-shortcuts="">
                          {render_key_sequence(sequence)}
                        </div>
                      </Command.Item>
                    )
                  })}
                {!store.state._sel_cmd &&
                  store.rooms_mru
                    .filter((r) => {
                      return (
                        inp?.length > 1 &&
                        r.title?.toLowerCase().includes(inp?.toLowerCase())
                      )
                    })
                    .map((r) => (
                      <Command.Item
                        key={`room-${r.id}`}
                        onSelect={(value) => {
                          //note: don't use cmdk value.. because it lowercases
                          store.go(r)
                          if (_global.desktop) {
                            store.toggle('bar', false)
                            store.toggle('desktop_bar', false)
                          }
                        }}
                        value={store.room_title(r.id) + ' | ' + r.id}
                      >
                        <RoomItemLabel room={r} />
                      </Command.Item>
                    ))}
              </Command.List>
            )}
          </Command>
        </div>
      </div>
    )
  },
  () => [
    Object.keys(store.rooms).length,
    store.state._sel_cmd,
    store.user?.show?.bar,
  ],
)

function BellFilledIcon({ props = {} }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.6013 1.25461C8.6013 1.75834 8.26284 2.18302 7.80093 2.31364C10.1459 2.46845 12.0001 4.41957 12.0001 6.80374V10.2538C12.0001 11.06 12.033 11.7112 12.7237 12.0565C12.9311 12.1602 13.04 12.3929 12.9867 12.6186C12.9334 12.8443 12.7319 13.0038 12.5 13.0038H8.1615C8.3691 13.187 8.50003 13.4551 8.50003 13.7537C8.50003 14.306 8.05232 14.7537 7.50003 14.7537C6.94775 14.7537 6.50003 14.306 6.50003 13.7537C6.50003 13.4551 6.63097 13.187 6.83857 13.0038H2.50005C2.26816 13.0038 2.0667 12.8443 2.01342 12.6186C1.96015 12.3929 2.06903 12.1602 2.27644 12.0565C2.96714 11.7112 3.00005 11.06 3.00005 10.2538V6.80374C3.00005 4.41912 4.85487 2.46772 7.20049 2.31356C6.73873 2.18283 6.40042 1.75823 6.40042 1.25461C6.40042 0.646858 6.8931 0.154175 7.50086 0.154175C8.10862 0.154175 8.6013 0.646858 8.6013 1.25461Z"
        fill="#FF4848"
      />
    </svg>
  )
}

// class ErrorBoundary extends VComponent {
//   constructor(props) {
//     super(props)
//     this.state = { hasError: false }
//   }
//
//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true }
//   }
//
//   componentDidCatch(error, info) {
//     console.error(error, info.componentStack)
//   }
//
//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return this.props.fallback
//     }
//
//     return this.props.children
//   }
// }

function SubItem({
  children,
  shortcut,
}: {
  children: ReactNode
  shortcut: string
}) {
  return (
    <Command.Item>
      {children}
      <div cmdk-raycast-submenu-shortcuts="">
        {shortcut.split(' ').map((key) => {
          return <kbd key={key}>{key}</kbd>
        })}
      </div>
    </Command.Item>
  )
}

function TerminalIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="4 17 10 11 4 5"></polyline>
      <line x1="12" y1="19" x2="20" y2="19"></line>
    </svg>
  )
}

function RaycastLightIcon() {
  return (
    <svg
      width="1024"
      height="1024"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M934.302 511.971L890.259 556.017L723.156 388.902V300.754L934.302 511.971ZM511.897 89.5373L467.854 133.583L634.957 300.698H723.099L511.897 89.5373ZM417.334 184.275L373.235 228.377L445.776 300.923H533.918L417.334 184.275ZM723.099 490.061V578.209L795.641 650.755L839.74 606.652L723.099 490.061ZM697.868 653.965L723.099 628.732H395.313V300.754L370.081 325.987L322.772 278.675L278.56 322.833L325.869 370.146L300.638 395.379V446.071L228.097 373.525L183.997 417.627L300.638 534.275V634.871L133.59 467.925L89.4912 512.027L511.897 934.461L555.996 890.359L388.892 723.244H489.875L606.516 839.892L650.615 795.79L578.074 723.244H628.762L653.994 698.011L701.303 745.323L745.402 701.221L697.868 653.965Z"
        fill="#FF6363"
      />
    </svg>
  )
}

function RaycastDarkIcon() {
  return (
    <svg
      width="1024"
      height="1024"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.144 634.799V722.856L90 511.712L134.244 467.804L301.144 634.799ZM389.201 722.856H301.144L512.288 934L556.34 889.996L389.201 722.856ZM889.996 555.956L934 511.904L512.096 90L468.092 134.052L634.799 300.952H534.026L417.657 184.679L373.605 228.683L446.065 301.144H395.631V628.561H723.048V577.934L795.509 650.395L839.561 606.391L723.048 489.878V389.105L889.996 555.956ZM323.17 278.926L279.166 322.978L326.385 370.198L370.39 326.145L323.17 278.926ZM697.855 653.61L653.994 697.615L701.214 744.834L745.218 700.782L697.855 653.61ZM228.731 373.413L184.679 417.465L301.144 533.93V445.826L228.731 373.413ZM578.174 722.856H490.07L606.535 839.321L650.587 795.269L578.174 722.856Z"
        fill="#FF6363"
      />
    </svg>
  )
}

function WindowIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 4.75V3.75C14.25 2.64543 13.3546 1.75 12.25 1.75H3.75C2.64543 1.75 1.75 2.64543 1.75 3.75V4.75M14.25 4.75V12.25C14.25 13.3546 13.3546 14.25 12.25 14.25H3.75C2.64543 14.25 1.75 13.3546 1.75 12.25V4.75M14.25 4.75H1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function FinderIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 4.75V6.25M11 4.75V6.25M8.75 1.75H3.75C2.64543 1.75 1.75 2.64543 1.75 3.75V12.25C1.75 13.3546 2.64543 14.25 3.75 14.25H8.75M8.75 1.75H12.25C13.3546 1.75 14.25 2.64543 14.25 3.75V12.25C14.25 13.3546 13.3546 14.25 12.25 14.25H8.75M8.75 1.75L7.08831 7.1505C6.9202 7.69686 7.32873 8.25 7.90037 8.25C8.36961 8.25 8.75 8.63039 8.75 9.09963V14.25M5 10.3203C5 10.3203 5.95605 11.25 8 11.25C10.0439 11.25 11 10.3203 11 10.3203"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function StarIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43376 2.17103C7.60585 1.60966 8.39415 1.60966 8.56624 2.17103L9.61978 5.60769C9.69652 5.85802 9.92611 6.02873 10.186 6.02873H13.6562C14.2231 6.02873 14.4665 6.75397 14.016 7.10088L11.1582 9.3015C10.9608 9.45349 10.8784 9.71341 10.9518 9.95262L12.0311 13.4735C12.2015 14.0292 11.5636 14.4777 11.1051 14.1246L8.35978 12.0106C8.14737 11.847 7.85263 11.847 7.64022 12.0106L4.89491 14.1246C4.43638 14.4777 3.79852 14.0292 3.96889 13.4735L5.04824 9.95262C5.12157 9.71341 5.03915 9.45349 4.84178 9.3015L1.98404 7.10088C1.53355 6.75397 1.77692 6.02873 2.34382 6.02873H5.81398C6.07389 6.02873 6.30348 5.85802 6.38022 5.60769L7.43376 2.17103Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// function ClipboardIcon() {
//   return (
//     <div cmdk-raycast-clipboard-icon="">
//       <svg
//         width="32"
//         height="32"
//         viewBox="0 0 16 16"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M6.07512 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V12.25C2.75 13.3546 3.64543 14.25 4.75 14.25H11.25C12.3546 14.25 13.25 13.3546 13.25 12.25V4.75C13.25 3.64543 12.3546 2.75 11.25 2.75H9.92488M9.88579 3.02472L9.5934 4.04809C9.39014 4.75952 8.73989 5.25 8 5.25V5.25C7.26011 5.25 6.60986 4.75952 6.4066 4.04809L6.11421 3.02472C5.93169 2.38591 6.41135 1.75 7.07573 1.75H8.92427C9.58865 1.75 10.0683 2.3859 9.88579 3.02472Z"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </div>
//   )
// }

function ConfirmButton({
  className = '',
  onClick = null,
  icon = null,
  confirmIcon = null,
}) {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (clicked) {
      later(() => setClicked(false), 350)
    }
  }, [clicked])

  icon = icon ?? <ClipboardIcon />
  confirmIcon = confirmIcon == 'same' ? icon : null
  confirmIcon = confirmIcon ?? <CheckIcon className="text-lime-500" />
  return (
    <div
      onClick={(e) => {
        e.preventDefault()
        if (onClick) onClick(e)
        setClicked(true)
      }}
    >
      <motion.button
        className={
          'cursor-pointer text-center text-slate-300 grid place-items-center w-8 h-8 rounded-full shrink-0 hover:bg-slate-50 ' +
          className
        }
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {clicked ? confirmIcon : icon}
      </motion.button>
    </div>
  )
}

// export ConfirmButton;

function HammerIcon() {
  return (
    <div cmdk-raycast-hammer-icon="">
      <svg
        width="32"
        height="32"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.73762 6.19288L2.0488 11.2217C1.6504 11.649 1.6504 12.3418 2.0488 12.769L3.13083 13.9295C3.52923 14.3568 4.17515 14.3568 4.57355 13.9295L9.26238 8.90071M6.73762 6.19288L7.0983 5.80605C7.4967 5.37877 7.4967 4.686 7.0983 4.25872L6.01627 3.09822L6.37694 2.71139C7.57213 1.42954 9.50991 1.42954 10.7051 2.71139L13.9512 6.19288C14.3496 6.62017 14.3496 7.31293 13.9512 7.74021L12.8692 8.90071C12.4708 9.328 11.8248 9.328 11.4265 8.90071L11.0658 8.51388C10.6674 8.0866 10.0215 8.0866 9.62306 8.51388L9.26238 8.90071M6.73762 6.19288L9.26238 8.90071"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export function TeamSwitcher({ className, force_open }: any) {
  const [open, setOpen] = React.useState(false)
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false)
  const [showInviteDialog, setShowInviteDialog] = React.useState(false)
  // const [selectedTeam, setSelectedTeam] = React.useState<any>(null)
  // const [teams, setTeams] = useState([])
  const [privacy, setPrivacy] = useState('closed')

  // useEffect(() => {
  //   if (!Sync.session?.profile) return
  //   setTeams(
  //     Sync.session?.profile?.teams
  //       ?.filter((t) => t)
  //       .map((team) => ({
  //         id: team,
  //         ...(_.first(
  //           Sync.session.profile.teams_info?.filter((i) => i.id == team)
  //         ) || {}),
  //       })) || []
  //   )
  //   setSelectedTeam(Sync.session.team_id)
  // }, [store.user?.email, Sync.session, Sync.session?.profile?.teams?.length])

  var teams = values(store.teams)
  var shares = teams.filter((t) => t.id.indexOf('share') != -1)
  var personal = teams.filter((t) => t.id.indexOf('personal') != -1)
  var non_personal = teams.filter(
    (t) => t.id.indexOf('personal') == -1 && t.id.indexOf('share') == -1,
  )
  // var g_teams = _.groupBy(teams, t => t.id.indexOf('personal') == -1);
  let groups = [
    {
      label: 'Personal Space',
      teams: personal.map((team) => ({
        name: team.name || `${store.user_name()}'s Space`,
        id: team.id,
      })),
    },
    {
      label: 'Shared Spaces',
      teams: non_personal.map((team) => ({
        name: team.name || 'Your Space',
        id: team.id,
      })),
    },
    // {
    //   label: 'Public Spaces',
    //   teams: shares.map((team) => ({ name: team.name || 'Public Share', id: team.id })),
    // },
  ]

  groups = groups.filter((g) => g.teams.length > 0)

  if (!teams || !store.team.id) {
    return null
  }

  let sTeam = _.first(
    _.flatten(groups.map((g) => g.teams.filter((t) => t.id == store.team.id))),
  )

  return (
    <Dialog
      key="team_switcha"
      open={showNewTeamDialog}
      onOpenChange={setShowNewTeamDialog}
    >
      <Collapsible open={!!(force_open ?? store.user?.show?.team_viewer)}>
        <CollapsibleTrigger className="w-full">
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn(
              'rounded-none bg-white w-full border-0 justify-between',
              className,
            )}
            onClick={() => store.toggle('team_viewer')}
          >
            {sTeam ? (
              <Ava className="shrink-0 mr-2" width={7} user={sTeam} is_team />
            ) : null}
            <div className="truncate">{sTeam?.name}</div>
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <Separator />
          <Command className="bg-white">
            <CommandList className="max-h-72 h-full">
              <CommandInput placeholder="Search spaces..." />
              <CommandEmpty>No team found.</CommandEmpty>
              {groups.map((group) => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.teams.map((team) => (
                    <CommandItem
                      key={team.id}
                      value={team.name + ' | ' + team.id}
                      onSelect={async () => {
                        var t = {
                          team_id: team.id,
                          team_name: team.name,
                        }
                        store.setCurrentTeam(t.team_id)
                        await Sync.setTeam(t)
                        setOpen(false)
                      }}
                      className="text-sm truncate"
                    >
                      <Ava className="shrink-0 mr-2" pill user={team} is_team />
                      <div className="truncate">{team.name}</div>
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          store.team.id === team.id
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup className="truncate text-slate-500">
                <DialogTrigger asChild>
                  <CommandItem
                    className="truncate"
                    onSelect={() => {
                      setOpen(false)
                      setShowNewTeamDialog(true)
                    }}
                  >
                    <PlusCircledIcon className="shrink-0 mr-2 h-5 w-5" />
                    New Space
                  </CommandItem>
                </DialogTrigger>
                <CommandSeparator />
                <CommandItem
                  className="truncate"
                  onSelect={() => store.toggle('send_invite')}
                >
                  <BiUserPlus className="shrink-0 mr-1 h-5 w-5" />
                  Invite to&nbsp;<b>{store.team.name || 'Your Space'}</b>
                </CommandItem>
                <CommandItem
                  className="truncate"
                  onSelect={() => store.toggle('space_settings')}
                >
                  <GearIcon className="shrink-0 mr-2 h-5 w-5" />
                  Space Settings
                </CommandItem>
                <CommandItem
                  className="truncate"
                  onSelect={() => store.nav('/docs')}
                >
                  <FileIcon className="shrink-0 mr-2 h-5 w-5" />
                  Space Files
                </CommandItem>
                <CommandSeparator />
                <CommandItem
                  className="truncate"
                  onSelect={() =>
                    store.plan
                      ? store.toggle('pricing_drawer')
                      : store.toggle('pricing_table')
                  }
                >
                  <PersonIcon className="shrink-0 mr-2 h-5 w-5" />
                  Plan Settings ({toCapitalCase(store.plan?.name ?? 'free')})
                </CommandItem>
                <Separator />
                <Collapsible>
                  <CollapsibleTrigger className="w-full">
                    <CommandItem>
                      <Ava className="shrink-0 mr-2" pill user={store.user} />
                      {store.user?.email}
                      <CaretDownIcon className="shrink-0 h-4 w-4" />
                    </CommandItem>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    {/*<CommandItem onSelect={() => store.toggle('user_settings')}>
                      <GearIcon className="m-1" />
                      User Settings
                    </CommandItem>*/}
                    <CommandItem onSelect={() => clearSession()}>
                      <ExitIcon className="m-1" />
                      Sign Out
                    </CommandItem>
                  </CollapsibleContent>
                </Collapsible>
              </CommandGroup>
            </CommandList>
          </Command>

          <DialogContent>
            <DialogHeader>
              <DialogTitle>New Space</DialogTitle>
              <DialogDescription>
                Create a new workspace to stay organized or collaborate with
                others.
              </DialogDescription>
            </DialogHeader>
            <div>
              <div className="space-y-4 py-2 pb-4">
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input id="ws_name" placeholder="My Team" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="plan">Privacy</Label>
                  <Select
                    defaultValue="closed"
                    name="privacy"
                    onValueChange={(value) => setPrivacy(value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Choose Privacy" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="closed">
                        <span className="font-medium">Closed</span> -{' '}
                        <span className="text-muted-foreground">
                          Only invited members can join.
                        </span>
                      </SelectItem>
                      <SelectItem value="open">
                        <span className="font-medium">Open</span> -{' '}
                        <span className="text-muted-foreground">
                          Anyone with the link can join.
                        </span>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setShowNewTeamDialog(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  var team: any = {
                    team_name: get_elem_value('ws_name'),
                    team_open: privacy == 'open',
                    is_new: true,
                  }
                  team.team_id = store.addTeam({
                    name: team.team_name,
                    open: team.team_open,
                  })
                  Sync.setTeam(team)
                  setShowNewTeamDialog(false)
                }}
                type="submit"
              >
                Create
              </Button>
            </DialogFooter>
          </DialogContent>
        </CollapsibleContent>
      </Collapsible>
    </Dialog>
  )
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'RTF']

function Upload() {
  const handleChange = async (file) => {
    const { data, error } = await supabase.storage
      .from('files')
      .upload(`./${this.team.id}/${file.name}`, file)
    if (data) {
      console.log(data)
    } else {
      console.log(error)
    }
  }

  return (
    <FileUploader
      handleChange={(file) => handleChange(file)}
      name="video"
      types={fileTypes}
    />
  )
}

import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  setFetchMethod,
  // auto as followSystemColorScheme,
  exportGeneratedCSS as collectCSS,
  // isEnabled as isDarkReaderEnabled
} from 'darkreader'

// DarkReader.setFetchMethod(fetch: (url) => Promise<Response>))
setFetchMethod(window.fetch)

async function do_toast(t) {
  var response =
    typeof Notification == 'undefined'
      ? 'denied'
      : await (Notification && Notification.requestPermission
          ? Notification.requestPermission()
          : Promise.resolve('denied'))
  var tst: any = toast
  if (!t.quiet && !document.hasFocus() && response === 'granted') {
    tst = (title, options) => {
      const notification = new Notification(title, {
        body: options?.description,
        icon: logoCircled,
      })
      notification.onclick = function () {
        window.parent.parent.focus()
        options.action?.onClick()
      }
    }
  }
  if (t.type == 'error') {
    lg('error', { type: 'server_missed_patch' }, store)
    toast.warning('Sync Issue. Reload Page & Try Again.', {
      id: 'connection',
      duration: Infinity,
      action: {
        label: '↻ Reload',
        onClick: () => {
          window.location.reload()
        },
      },
    })
  } else if (t.type == 'notif') {
    var notif = store.state?.notifs[t.id_]
    if (!notif) return
    var msg = store.messages[notif.target]
    if (!msg) return
    if (!Sync.audio) {
      Sync.audio = new Audio(notifSound)
      Sync.audio.volume = 0.2
    }
    Sync.audio.play()
    tst(store.user_name(msg?.owner_id), {
      description: store
        .message_content_text(msg)
        .replace(/<[^>]*>/g, '')
        .trunc(95),
      action: {
        label: 'Open',
        onClick: () => store.setCurrentRoomMessage(msg.id),
      },
    })
    store.setDelivered(notif.id)
  } else if (t.url) {
    tst(t.title, {
      description: t.content,
      action: {
        label: 'Open',
        onClick: () => window.open(t.url, '_blank'),
      },
    })
  } else if (t.type == 'check') {
    t.options.action.onClick = () => store.toggle(t.toggle)
    tst(t.title, {
      description: t.content,
      type: t.level ?? 'message',
      ...(t.options || {}),
    })
  } else if (t.action) {
    tst(t.content, {
      action: {
        label: t.action.label,
        onClick: t.action.onClick,
      },
    })
  } else if (t.title) {
    tst(t.title, {
      description: t.content,
      type: t.level ?? 'message',
      ...(t.options || {}),
      onClick: () => store.toggle('space_login'),
    })
  } else {
    tst(t.content, t.options)
  }
}

store.watch(
  (store) => store.user?.toast,
  async (action, meta) => {
    //init, but why is it setUser not sure
    if (action.name == 'setUser') return
    var t = store.user?.toast
    if (t?.delivered !== false) return
    if (t.ephemeral && action.name == '@initialize') return
    await do_toast(t)
    //should compare last toast instead?
    store.setToast({ delivered: true })
  },
  'toast',
)

store.watch(
  (store) => store.user?.dark_mode,
  () => {
    document.firstElementChild.setAttribute(
      'data-theme',
      store.user?.dark_mode ? 'dark' : 'light',
    )
    if (store.user?.dark_mode) {
      enableDarkMode(
        {
          brightness: 90,
          contrast: 85,
          sepia: 0,
        },
        //@ts-ignore
        {
          ignoreInlineStyle: ['body', 'circle', '#moon-mask', '.sun-and-moon'],
          ignoreImageAnalysis: ['circle', '#moon-mask', '.sun-and-moon'],
          // invert: ['.hand_logo'],
          //@ts-ignore
          noinvert: ['body', '#moon-mask', '.sun-and-moon'],
        },
      )
    } else {
      disableDarkMode()
    }
  },
  'dark_mode',
)

function Link({ to, go, children }) {
  return (
    <Button
      variant="outline"
      onClick={() => (go ? store.go(go) : store.nav(to))}
    >
      {children}
    </Button>
  )
}

store.watch(
  (store) => !!store.user?.show?.desktop_bar,
  () => {
    if (!store.user?.show?.desktop_bar) {
      ;(window as any).electron?.ipcRenderer.sendMessage('message', {
        action: 'unsize',
      })
    }
  },
  'resize_desktop',
)

store.watch(
  (store) => !!store.user?.id,
  () => {
    if (store.user?.create_time) {
      //@ts-ignore
      Canny('identify', {
        appID: '658304b99e1c61b746279807',
        user: {
          // Replace these values with the current user's data
          email: store.user.email,
          name: store.user_name(),
          id: store.user.id,

          // These fields are optional, but recommended:
          // avatarURL: user.avatarURL,
          created: new Date(store.user.create_time).toISOString(),
        },
      })
    }
  },
  'user_init',
)

store.watch(
  (store) => (store.user ? store.user.reload_requested : 'nouser'),
  (action, meta, cur, prev) => {
    if (prev == 'nouser' || action.name == '@initialize') return
    if (action.name == 'requestReload') window.location.reload()
  },
  'requestReload',
  false,
)

store.watch(
  (store) => store.unlogged_plan_changes,
  (a, meta) => {
    if (store.unlogged_plan_changes.length == 0) return
    var plan = store.user.secure.plan || {}
    var lplan = store.user.secure.logged_plan || {}
    //Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
    lg(
      'plan_change',
      { changes: store.unlogged_plan_changes, new: plan, old: lplan },
      store,
    )
    if (plan.status !== lplan.status) {
      if (plan.status == 'trialing' && lplan.status != 'active') {
        console.log(`:: trial conversion`)
        lg('funnel', { step: 'trial_start', new: plan, old: lplan })
        lg('conversion', { event: 'trial_start', new: plan, old: lplan })
        ReactGA.gtag('event', 'conversion', {
          action: 'conversion',
          category: 'Conversion',
          send_to: 'AW-11465219128/mP_0CNud6IUZELi4hdsq',
          value: 15,
          currency: 'USD',
        })
      }
      if (store.plan && plan.status == 'active') {
        console.log(`:: purchase conversion`)
        lg(
          'funnel',
          {
            step: 'subscription_payment',
            new: plan,
            old: lplan,
            value: store.plan?.amount,
          },
          store,
        )
        lg(
          'conversion',
          { event: 'subscription_payment', new: plan, old: lplan },
          store,
        )
        ReactGA.gtag('event', 'conversion', {
          action: 'conversion',
          category: 'Conversion',
          send_to: 'AW-11465219128/xMzFCMOZ7ZkZELi4hdsq',
          value: (store.plan?.amount || 17) * 2.5,
          currency: 'USD',
        })
      }
    }
    if (plan.name !== lplan.name) {
      //log increase in value
      lg('plan_type_changed', { new: plan.name, old: lplan.name }, store)
    }

    store.setPlanLogged()
    // if (store.plan && !meta.prev?.users?.[store.user?.id]?.secure?.plan) {
    //   lg('funnel', { step: 'new_subscriber' }, this)
    //   ReactGA.gtag('event', 'conversion', {
    //     action: 'conversion',
    //     category: 'Conversion',
    //     // label: "RCQ-XXXXXXXXXXXXXXXX",
    //     send_to: 'AW-11465219128/mP_0CNud6IUZELi4hdsq',
    //   })
    // }
  },
  'sub_log',
)

function scroll_amount(id) {
  const divElement = document.getElementById(id)
  if (!divElement) return 0

  const scrollTop = divElement.scrollTop
  const scrollHeight = divElement.scrollHeight - divElement.clientHeight
  const scrollDistanceFromBottom = scrollHeight - scrollTop

  return scrollDistanceFromBottom
}

export function UserEdit({ selected, setSelected, classes, ai = false }) {
  const [open, setOpen] = useState(false)
  return open ? (
    <>
      {selected.map((uid) => [
        <Ava
          className="no-drag shrink-0 ml-3"
          // selected={store.room_models().includes(uid)}
          key={uid}
          pill
          user={store.users[uid]}
        />,
      ])}
      <div className="no-drag">
        <Tip content={<div>Change Team Members</div>}>
          <ConfirmButton
            icon={<PlusIcon />}
            key="add_user"
            confirmIcon="same"
            className=" md:ml-0 mr-0 text-slate-300"
            onClick={(e) => {
              e.preventDefault()
              // setOpen(true)
            }}
          />
        </Tip>
      </div>
    </>
  ) : (
    <div className="grow w-full place-items-center items-center flex justify-center">
      <div className="w-auto min-w-[300px]">
        <UserSelect
          classes={classes}
          escCallback={(e) => {
            e.preventDefault()
            setOpen(false)
          }}
          onUnselect={(id) => {
            setSelected((selected ?? []).filter((s) => s !== id))
          }}
          onSelect={(id) => {
            setSelected([...(selected || []), id])
          }}
          selected={selected || []}
          selectables={store.ai_users.filter((u) => u.id != 'ai')}
        />
      </div>
    </div>
  )
}

export function UserSelect({
  escCallback,
  autoselect = false,
  onUnselect,
  onSelect,
  selected,
  selectables,
  classes = '',
}) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    if (autoselect) inputRef.current?.focus()
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current
    if (input) {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (input.value === '') {
          if (selected.length > 0) {
            onUnselect(_.last(selected))
          }
        }
      }
      // This is not a default behaviour of the <input /> field
      if (e.key === 'Escape') {
        escCallback(e)
        input.blur()
      }
    }
  }

  selectables = selectables.filter((u) => !(selected || []).includes(u.id))
  return (
    <CommandC
      onKeyDown={handleKeyDown}
      className="min-w-auto shrink overflow-visible bg-transparent"
      shouldFilter={false}
      filter={() => 1}
    >
      <div className="group border-0 px-3 py-2 text-sm ring-offset-background rounded-md">
        <div className="flex gap-1 flex-wrap">
          {selected.map((uid) => {
            const user = store.users[uid]
            return (
              <Ava
                className="shrink-0 mr-2"
                pill
                user={user}
                key={user.id}
                endicon={
                  <button
                    className="rounded-full outline-none"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onUnselect(user.id)
                      }
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={(e) => {
                      onUnselect(user.id)
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <X className="h-3 w-4 text-white hover:text-slate-400" />
                  </button>
                }
              />
            )
          })}
          {/* Avoid having the "Search" Icon */}
          <Command.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={(e) => {
              escCallback(e)
              setOpen(false)
            }}
            onFocus={() => setOpen(true)}
            placeholder="Add.."
            className="ml-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1"
          />
        </div>
      </div>
      <div className="relative">
        {open && selectables.length > 0 ? (
          <div className="absolute w-full z-10 top-0 rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
            <CommandGroup
              className={`h-full overflow-auto max-h-[80vh] ${classes}`}
            >
              {selectables
                .filter((user) =>
                  store
                    .user_name(user.id)
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase()),
                )
                .map((user) => {
                  return (
                    <CommandItem
                      key={user.id}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onSelect={(value) => {
                        setInputValue('')
                        onSelect(user.id)
                      }}
                      className={'cursor-pointer'}
                    >
                      <div className="py-2 flex flex-col">
                        <div className="flex items-center">
                          <Ava className="shrink-0 mr-2" pill user={user} />

                          <div>{store.user_name(user.id)}</div>
                        </div>
                        <div className="text-slate-300">
                          {user.description || user.instructions || user.email}
                        </div>
                      </div>
                    </CommandItem>
                  )
                })}
            </CommandGroup>
          </div>
        ) : null}
      </div>
    </CommandC>
  )
}

export class Docs extends BasePanelPage {
  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()
    store.watch(
      (s) => s.all_files(),
      () => this.forceUpdate(),
      'docs',
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    store.unwatch('docs')
  }

  fileThumbnail(file) {
    if (file.fileType?.includes('image')) {
      return (
        <img
          src={get_file_url(file.id)}
          className="w-full mb-2 h-40 object-contain"
        />
      )
    } else if (file['type'] == 'note') {
      // className="whitespace-pre-wrap overflow-y-auto h-min max-h-full"
      return (
        <div className="w-full h-40 mb-2 flex text-4xl text-slate-300 overflow-hidden">
          <div className="message prose prose-slate break-words select-none">
            <ReactMarkdown
              children={file['content']}
              //@ts-ignore
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              components={MarkdownComponents}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-full h-40 mb-2 flex items-center justify-center text-4xl text-slate-300">
          <FileIcon className="w-10 h-10" />
        </div>
      )
    }
  }

  renderMain() {
    return (
      <div className="bg-slate-50 h-full overflow-auto">
        <div className="flex p-5 pb-0 ">
          <div className="text-2xl mr-2 font-medium">Files & Notes</div>

          <Tip content="New Note">
            <ConfirmButton
              onClick={(e) => {
                e.preventDefault()
                store.addNote()
              }}
              icon={<FilePlusIcon className="ml-0 text-slate-400" />}
            />
          </Tip>
          <Tip content="Add File">
            <ConfirmButton
              onClick={(e) => {
                e.preventDefault()
                store.toggle('_file_drawer', {
                  meta: {},
                  ref: store.ref(store.team),
                })
              }}
              icon={<UploadIcon className="ml-0 text-slate-400" />}
            />
          </Tip>
          {store.archived_files().length > 0 && (
            <Tip content="Delete Archived Files">
              <ConfirmButton
                onClick={(e) => {
                  e.preventDefault()
                  store.confirm(
                    () => store.clearArchivedFiles(),
                    `Are you sure you want to permanently delete ${store.archived_files().length} archived files?`,
                  )
                }}
                icon={<TrashIcon className="ml-0 text-rose-500" />}
              />
            </Tip>
          )}
        </div>
        <div className="flex flex-wrap">
          {values(store.all_files())
            .sort(recent)
            .filter(
              (f) =>
                !f.archived &&
                (f.type !== 'note' || f.content?.trim().length > 0) &&
                store.in_team(f),
            )
            .map((f) => (
              <div
                className={cn(
                  'w-48 whitespace-wrap truncate h-70 m-4 p-2 bg-white rounded',
                  store.current_file === f
                    ? 'shadow-lg shadow-slate-300'
                    : 'shadow',
                )}
                onClick={(e) => {
                  store.nav('/docs/' + f.id)
                }}
              >
                <div className="flex flex-col justify-between items-end">
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <MoreHorizontal className="text-slate-400" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {/* <DropdownMenuLabel>My Account</DropdownMenuLabel>
                      <DropdownMenuSeparator /> */}
                        <DropdownMenuItem
                          onClick={() => {
                            if (f['type'] == 'note') {
                              downloadMarkdown(f['content'], getFileName(f.id))
                            } else {
                              downloadURI(get_file_url(f.id), f.filename)
                            }
                          }}
                        >
                          <DownloadIcon className="mr-2 text-slate-500" />
                          {'Download File'}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={(e) => {
                            //e.preventDefault()
                            store.duplicateFile(f)
                            // e.stopPropagation()
                          }}
                        >
                          <CopyIcon className="mr-2 text-slate-500" />
                          {' Duplicate File'}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => {
                            store.archiveFile(f)
                          }}
                        >
                          <FileMinusIcon className="mr-2 text-slate-500" />{' '}
                          {'Archive File'}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex-grow">{this.fileThumbnail(f)}</div>
                  </div>
                </div>
                <div className="font-medium">{f.filename}</div>
                <div className="flex mb-4 mt-2">
                  <Ava
                    className="shrink-0 mr-2"
                    pill
                    //@ts-ignore
                    user={store.users[store.admin(f)]}
                  />
                  <div className="text-xs text-slate-300">
                    · {ago(f.create_time)} ago
                  </div>
                </div>
                <div className="flex-grow" />
                <div className="flex space-x-2">
                  <Button
                    variant="outline"
                    className="grow"
                    onClick={(e) => {
                      store.addRoomWithFile(f.id)
                      e.stopPropagation()
                    }}
                  >
                    <ChatBubbleIcon />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export class Blog extends VComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    if (this.props.team_id) var res = await get(`/shares/${this.props.team_id}`)
    else var res = await get(`/user_shares/${this.props.user_id}`)
    if (!res) return
    var shares = await res.json()
    shares.map((share) => {
      share.state = JSON.parse(share.screenshot)
    })

    this.setState({ shares })
  }

  render() {
    if (!this.state.shares) return <Loading />
    return (
      <div className="w-full overflow-y-scroll">
        <div className="mx-auto my-12 max-w-[676px] px-6 text-gray-900 md:my-16">
          {this.state.shares.reverse().map((share) => (
            <div className="flex flex-col">
              <a
                href={'/share/' + share.room}
                className="hover:bg-slate-200 text-xl whitespace-nowrap"
              >
                {share.state.rooms[share.room]?.title}
              </a>
              <div className="text-xs pb-10 text-slate-300">
                {ago(share.created_at)}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

// Utility function components
function Query({ query, children }) {
  const result = query()
  return <>{children(result)}</>
}
// Class component
// class Admin extends React.Component {
//   render() {
//     return (
//         <div>
//           <Query query={() => api.userList.useQuery({})}>
//             {query => {
//               if (query.isLoading) return <p>Loading...</p>;
//               if (query.error) return <p>Error: {query.error.message}</p>;
//               return <p>{JSON.stringify(query.data)}</p>;
//             }}
//           </Query>
//         </div>
//     );
//   }
// }

export function Admin() {
  const query = api.userList.useQuery({ builtin: true })
  const mutation = api.user.useMutation()
  return (
    <div>
      <div className="ag-theme-quartz h-screen">
        {/* The AG Grid component */}
        <AgGridReact
          rowData={query.data as any}
          rowHeight={100}
          reactiveCustomComponents={true}
          // autoSizeStrategy= {{ type: 'fitGridWidth', minColWidth: 100 }}

          rowSelection={'multiple'}
          onSelectionChanged={(s) => {
            s.api.resetRowHeights()
            s.api.getSelectedNodes().map((n) => n.setRowHeight(400))
            s.api.onRowHeightChanged()
          }}
          onCellValueChanged={(e) => {
            console.log(`e`, e)
            mutation.mutate({
              where: { id: e.data.id },
              data: { [e.colDef.field]: e.newValue },
            })
          }}
          columnDefs={
            entries(usersSchema.shape).map(([k, v]) => ({
              field: k,
              filter: true,
              editable: true,

              suppressKeyboardEvent: (params) => {
                console.log('cell is editing: ' + params.editing)
                console.log('keyboard event:', params.event)

                // return true (to suppress) if editing and user hit up/down keys
                const key = params.event.key
                const gridShouldDoNothing = params.editing && key === 'Enter'
                return gridShouldDoNothing
              },
              //@ts-ignore
              valueFormatter:
                v._def?.typeName == 'ZodLazy'
                  ? (cell) => JSON.stringify(cell.value)
                  : undefined,
              // valueGetter: v._def?.typeName == 'ZodLazy' ? (cell) => JSON.stringify(cell.value) : undefined,
              cellEditor:
                //@ts-ignore
                v._def?.typeName == 'ZodLazy'
                  ? ({ value, onValueChange }) => {
                      return (
                        <div className="h-full w-full overflow-auto">
                          <ReactJson
                            name={null}
                            onEdit={(e) => {
                              console.log(`onValueChange`, onValueChange)
                              onValueChange(e.updated_src)
                            }}
                            onAdd={(e) => {
                              console.log(`onValueChange`, onValueChange)
                              onValueChange(e.updated_src)
                            }}
                            onDelete={(e) => {
                              console.log(`onValueChange`, onValueChange)
                              onValueChange(e.updated_src)
                            }}
                            enableClipboard={false}
                            quotesOnKeys={false}
                            displayDataTypes={false}
                            src={value}
                          />
                        </div>
                      )
                    }
                  : undefined,
              cellRenderer:
                //@ts-ignore
                v._def?.typeName == 'ZodLazy'
                  ? (cell) => {
                      return (
                        <div className="h-full w-full overflow-auto">
                          <ReactJson
                            name={null}
                            enableClipboard={false}
                            quotesOnKeys={false}
                            displayDataTypes={false}
                            src={cell.value}
                          />
                        </div>
                      )
                    }
                  : undefined,
            })) as any
          }
        />
      </div>
    </div>
  )
}

export class Landing extends ObserverComponent {
  constructor(props) {
    super(props, (store) => [store.user])
  }

  render() {
    if (getSubdomain(location.host) == 'heated')
      return <Redirect to={'/explore'} />

    if (!this.props.force && Sync.session?.id && !Sync.session?.anonymous)
      return <Redirect to={'/app'} />

    window.location.href = 'https://hello.vello.ai'
  }
}
